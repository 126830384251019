import { CiSearch } from 'react-icons/ci'
import { IoMdClose } from 'react-icons/io'
import IconButton from '@mui/material/IconButton'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Typography,
} from '@mui/material'
import { useFilter } from '../../context/FilterContext'
import DropDownFilter from './DropDownFilter'
import { useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

function CustomTablePaginationActions({
  countRows,
  page,
  rowsPerPage,
  onPageChange,
}) {
  const { data } = useFilter()

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        variant="body2"
        style={{ marginRight: 'auto', marginLeft: 0 }}
      >
        Usuários do dia: {data[0]?.total}
      </Typography>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) => `total: ${count}`}
        count={countRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        labelRowsPerPage="Linhas por página:"
      />
    </div>
  )
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const TableFilter = ({ columns, onRowClick }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { filteredData, setSearchText, searchText } = useFilter()
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('asc')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <>
      <TextField
        variant="standard"
        placeholder="Buscar..."
        value={searchText}
        className="w-full md:!w-[30vw]"
        onChange={(e) => setSearchText(e.target.value)}
        margin="normal"
        InputProps={{
          startAdornment: (
            <IconButton
              size="small"
              title="Search"
              color="inherit"
              aria-label="Search"
            >
              <CiSearch />
            </IconButton>
          ),
          endAdornment: (
            <div className="flex items-center gap-1">
              {searchText && (
                <IconButton
                  size="small"
                  title="Clear"
                  color="inherit"
                  aria-label="Clear"
                  onClick={() => setSearchText('')}
                >
                  <IoMdClose />
                </IconButton>
              )}
              <DropDownFilter />
            </div>
          ),
        }}
      />
      <TableContainer component={Paper} sx={{ minHeight: '60vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    width: column.width,
                    backgroundColor: '#333',
                    color: '#f1f1f1',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleRequestSort(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <span style={{ marginLeft: '5px' }}>
                      {order === 'asc' ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  className="cursor-pointer"
                  onClick={() => onRowClick(row)}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ width: column.width, maxWidth: 0 }}
                    >
                      {column.truncate ? (
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {row[column.id]}
                        </Typography>
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomTablePaginationActions
        countRows={filteredData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  )
}

export default TableFilter
