import { Alert, Card, CardContent, CardHeader, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { api } from '../../../utils/api'
import Select from '../../forms/Select'
import SwitchLabel from '../../forms/SwitchLabel'
import Button from '../../forms/Button'
import Input from '../../forms/Input'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import WeaponSelect from '../../WeaponSelect'
import { selectedType } from '../../../types/habitualidade'
import ModalFinalRegistration from './ModalFinalRegistration'
import UploadPhotos from './UploadPhotos'
import ModalCompetitionDay from './ModalCompetitionDay'
import { useLoading } from '../../../context/LoadingContext'
import { useHabitualidadeContext } from '../../../context/HabitualidadeContext'
import { useGetData } from '../../../context/getDataContext'

export const initialState = {
  armasAll: [selectedType],
  armasPessoa: [selectedType],
  modalidades: [selectedType],
  tipos: [selectedType],
}

const Competion = ({ setType }) => {
  const { user } = useAuth()
  const {
    getDataCompeticao,
    data: { 'habitualidade/competicao': data },
  } = useGetData()
  const history = useHistory()
  const { setCompeticao, competicao } = useHabitualidadeContext()
  const { startLoading, stopLoading } = useLoading()
  // const [data, setData] = useState(initialState)
  const [armaPropria, setArmaPropria] = useState(true)
  const [armaSelecionada, setArmaSelecionada] = useState(selectedType)
  const [modalFinalRegistration, setModalFinalRegistration] = useState(false)
  const [competionCreate, setCompetionCreate] = useState(false)
  const [files, setFiles] = useState([])
  const [previews, setPreviews] = useState([])
  const [dataCadastroInvalid, setDataCadastroInvalid] = useState(false)
  // Abre modal se ja tiver alguma competicao no dia atual ou na data selecionada
  const [openModalCompetitionDay, setOpenModalCompetitionDay] = useState(false)
  const [key, setKey] = useState(false)
  const [competionSelected, setCompetitionSelected] = useState({
    status: false,
  })

  const {
    handleSubmit,
    control,
    register,
    reset,
    getValues,
    watch,
    setValue,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm()

  // Seleciona se é arma propria ou não
  const handleSwitchChange = (newValue) => {
    watch('arma')
    setArmaPropria(newValue)
    setValue('seletedArm', null)
    if (!newValue) {
      setValue('numeroControle', '')
      setError('arma', {
        type: 'required',
        message: 'custom message',
      })
      setArmaSelecionada(null)
    } else {
      setValue('numeroControle', data.armasPessoa[0].numeroControle)
      setArmaSelecionada(data.armasPessoa[0])
      clearErrors('arma')
    }
  }

  const onSelectArm = (value) => {
    const arm = data.armasAll.find((a) => a.nome === value.nome)
    if (arm) {
      setArmaSelecionada(arm)
    } else {
      setArmaSelecionada(null)
      setValue('seletedArm', null)
    }
  }

  useEffect(() => {
    if (!getValues('seletedArm')) {
      setArmaSelecionada(null)
      setError('arma', {
        type: 'required',
        message: 'custom message',
      })
    } else {
      clearErrors('arma')
    }
  }, [getValues('seletedArm')])

  // Clicado em salvar abre modal para confirmar os dados e salvar no banco
  const handleClickConfirm = (value) => {
    delete value.seletedArm
    const formatValues = {
      ...value,
      armaSelecionada,
      clubeID: user.clube.clubeID,
      banco: user.clube.banco,
    }
    setCompeticao({
      ...competicao,
      ...formatValues,
    })
    setModalFinalRegistration(true)
    setCompetionCreate(false)
  }

  // Adiciona fotos do treinamento
  const savePhotos = async (id, logAppID) => {
    let formData = new FormData()
    for (let i = 0; i < files?.length; i++) {
      if (files[i].type.includes('image')) {
        formData.append('files[]', files[i])
      }
    }
    formData.append('id', id)
    formData.append('logAppID', logAppID)
    formData.append('banco', user.clube.banco)
    formData.append('usuarioID', user.pessoa.usuarioID)
    formData.append('clubeID', user.clube.clubeID)

    try {
      const response = await fetch(
        `${api}habitualidade/competicao/savePhotos`,
        {
          method: 'POST',
          body: formData,
        }
      )
      const res = await response.json()
      return true
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    trigger()
  }, [key, isValid])

  // Cria treinamento / adiciona fotos
  const create = async (values) => {
    startLoading()
    try {
      const response = await fetch(`${api}habitualidade/competicao/create`, {
        method: 'POST',
        body: JSON.stringify({
          ...values,
          banco: user.clube.banco,
          pessoaID: user.pessoa.pessoaID,
          clubeID: user.clube.clubeID,
          usuarioID: user.pessoa.usuarioID,
          id: competionSelected.item.id,
        }),
      })
      const res = await response.json()
      const id = res.id
      const logAppID = res.logAppID
      let finished = false
      if (id && id > 0 && files?.length > 0) {
        finished = await savePhotos(id, logAppID)
      } else {
        finished = true
      }
      if (finished) {
        setCompetionCreate(true)
        toast.success('Competição criada com sucesso!')
      }
    } catch (error) {
      console.log(error)
    } finally {
      stopLoading()
    }
  }

  // Mensagem caso a data esteja fora do range
  const errorDateMessage =
    competionSelected?.item?.data === competionSelected?.item?.dataFim
      ? `A data deve ser igual a ${competionSelected?.item?.data}`
      : `A data deve ser entre ${competionSelected?.item?.data} e ${competionSelected?.item?.dataFim}`

  // Verifica data esta dentro do range
  function isDateWithinRange(selectedDate) {
    const dataIni = competionSelected.item.data_
    const dataFim = competionSelected.item.dataFim_
    return selectedDate >= dataIni && selectedDate <= dataFim
  }

  // Baseado na resposta da função isDateWithinRange, valida se a data esta dentro do range
  const handleDateChange = (newValue) => {
    const setInvalidState = () => {
      setDataCadastroInvalid(true)
      setValue('dataCadastro', null)
      setError('dataCadastro', {
        type: 'required',
        message: 'custom message',
      })
    }

    if (newValue) {
      if (!isDateWithinRange(newValue)) {
        setInvalidState()
      } else {
        clearErrors('dataCadastro')
        setDataCadastroInvalid(false)
        setValue('dataCadastro', newValue)
      }
    } else {
      setInvalidState()
    }
  }

  useEffect(() => {
    getDataCompeticao()
    setOpenModalCompetitionDay(true)
  }, [])

  useEffect(() => {
    if (data && data.armasPessoa?.length > 0) {
      setArmaSelecionada(data.armasPessoa[0])
    }
  }, [data, data.armasPessoa])

  useEffect(() => {
    trigger()
  }, [openModalCompetitionDay])

  return (
    <>
      {/* Modal que abre quando ja tiver alguma competicao no dia atual */}
      <ModalCompetitionDay
        setType={setType}
        open={openModalCompetitionDay}
        OnClose={() => setOpenModalCompetitionDay(false)}
        setCompetitionSelected={setCompetitionSelected}
        armaSelecionada={armaSelecionada}
        data={data.competicao}
        reset={reset}
      />
      {/* Modal que abre para confirmar a criação da competição */}
      <ModalFinalRegistration
        competionCreate={competionCreate}
        setPreviews={setPreviews}
        previews={previews}
        setFiles={setFiles}
        setCompetionCreate={setCompetionCreate}
        reset={reset}
        onSubmit={create}
        setArmaSelecionada={setArmaSelecionada}
        open={modalFinalRegistration}
        OnClose={() => setModalFinalRegistration(false)}
        setKey={setKey}
        armaPropria={armaPropria}
        armasPessoa={data.armasPessoa}
      />
      <form onSubmit={handleSubmit(handleClickConfirm)} className="pt-2">
        <Card>
          <CardHeader title="Competição" />
          <CardContent>
            <Grid container spacing={2}>
              {/* Campos fixos */}
              {competionSelected.status && (
                <>
                  <Grid item xs={12}>
                    <Card className="p-3 transition-colors rounded-lg">
                      <div>
                        <p className="uppercase font-bold">
                          {competionSelected.item.descricao}
                        </p>
                      </div>
                      <div className="flex justify-between pt-3">
                        <div>
                          <p className="text-sm opacity-80 ">Data</p>
                          <p>
                            {competionSelected.item.data ===
                              competionSelected.item.dataFim ||
                            !competionSelected.item.dataFim
                              ? competionSelected.item.data
                              : `${competionSelected.item.data} a ${competionSelected.item.dataFim}`}
                          </p>
                        </div>
                        <div></div>
                      </div>
                      <div className="flex justify-between pt-3">
                        <div>
                          <p className="text-sm opacity-80 ">Tipo do evento</p>
                          <p>{competionSelected.item.nomeTipoEvento}</p>
                        </div>
                        <div>
                          <p className="text-sm opacity-80 text-right">Local</p>
                          <p className=" uppercase">
                            {competionSelected.item.local}
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    placeholder="Data da competição"
                    control={control}
                    onChange={handleDateChange}
                    errors={errors?.dataCadastro}
                    required
                    type="date"
                    name="dataCadastro"
                    title="Data da competição"
                    minDate={competionSelected.item.data_}
                    maxDate={competionSelected.item.dataFim_}
                  />
                  {competionSelected &&
                    (errors.dataCadastro || dataCadastroInvalid) && (
                      <Grid item xs={12}>
                        <Alert color="error">
                          A data informada é inválida. {errorDateMessage}
                        </Alert>
                      </Grid>
                    )}
                  {/* Campos dinamicos */}
                  <SwitchLabel
                    xs={12}
                    name="armaPropria"
                    title="Arma própria"
                    value={armaPropria}
                    register={register}
                    onChange={handleSwitchChange}
                  />
                  <Grid item xs={12}>
                    {/* Mostra armas */}
                    {armaPropria && armaPropria ? (
                      <div
                        className={`flex gap-4 ${
                          data?.armasPessoa?.length < 3 || !armaPropria
                            ? 'justify-center'
                            : ''
                        } overflow-x-auto ${
                          data?.armasPessoa?.length > 0 ? 'cursor-pointer' : ''
                        }`}
                        style={
                          data?.armasPessoa?.length > 2
                            ? {
                                overflowX: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                whiteSpace: 'nowrap',
                                width: '100%',
                              }
                            : {}
                        }
                      >
                        {data.armasPessoa &&
                          data?.armasPessoa?.length === 0 && (
                            <Alert className="w-full" severity="error">
                              Nenhuma arma cadastrada em seu acervo.{' '}
                              <Link
                                to="/acervo-pessoal"
                                className="cursor-pointer font-bold underline opacity-80 hover:opacity-100 transition-colors"
                              >
                                Cadastrar arma
                              </Link>
                            </Alert>
                          )}
                        {data.armasPessoa.map((arma) => {
                          return (
                            <div
                              key={arma.nome}
                              onClick={() => {
                                setArmaSelecionada(arma)
                                setValue('numeroControle', arma?.numeroControle)
                              }}
                            >
                              <WeaponSelect
                                armaSelecionada={armaSelecionada}
                                name={arma.nome}
                                url={arma.imagem}
                                numeroSerie={arma.numeroSerie}
                              />
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <div>
                        <Select
                          xs={12}
                          title="Arma"
                          name="seletedArm"
                          type="string"
                          options={data?.armasAll ?? []}
                          register={register}
                          setValue={setValue}
                          required
                          onChange={onSelectArm}
                          control={control}
                          errors={errors?.arma}
                          clearErrors={clearErrors}
                          setError={setError}
                        />
                        {/* Imagem arma selecionada */}
                        {armaSelecionada && (
                          <WeaponSelect
                            armaSelecionada={armaSelecionada}
                            name={armaSelecionada?.nome}
                            url={armaSelecionada?.imagem}
                          />
                        )}
                      </div>
                    )}
                  </Grid>
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    md={6}
                    placeholder={
                      armaPropria && armaSelecionada && armaSelecionada.id
                        ? armaSelecionada?.sistemaControle === '1'
                          ? 'Número SIGMA'
                          : 'Número SINARM'
                        : 'Número de SIGMA'
                    }
                    control={control}
                    disabled={armaPropria}
                    errors={errors?.numeroControle}
                    type="number"
                    name="numeroControle"
                    title={
                      armaPropria && armaSelecionada && armaSelecionada.id
                        ? armaSelecionada?.sistemaControle === '1'
                          ? 'Número SIGMA'
                          : 'Número SINARM'
                        : 'Número de SIGMA'
                    }
                  />
                  <Select
                    xs={12}
                    md={6}
                    title="Modalidade"
                    name="modalidade"
                    type="string"
                    options={data?.modalidades ?? []}
                    register={register}
                    setValue={setValue}
                    control={control}
                    required
                    errors={errors?.modalidade}
                    clearErrors={clearErrors}
                    setError={setError}
                  />
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    md={6}
                    placeholder="Divisão"
                    control={control}
                    errors={errors?.divisao}
                    name="divisao"
                    title="Divisão"
                  />
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    md={6}
                    placeholder="Qtd Munição"
                    control={control}
                    errors={errors?.QtdMunicao}
                    required
                    type="number"
                    name="QtdMunicao"
                    title="Qtd Munição"
                  />
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    md={6}
                    placeholder="Livro"
                    control={control}
                    required
                    errors={errors?.livro}
                    name="livro"
                    title="Livro"
                  />
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    md={6}
                    required
                    placeholder="Folha"
                    control={control}
                    errors={errors?.folha}
                    name="folha"
                    title="Folha"
                  />
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    md={6}
                    placeholder="Colocação"
                    control={control}
                    errors={errors?.colocacao}
                    type="number"
                    name="colocacao"
                    title="Colocação"
                  />
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    md={6}
                    placeholder="Pontos/Resultado"
                    control={control}
                    errors={errors?.pontos}
                    type="number"
                    name="pontos"
                    title="Pontos/Resultado"
                  />
                  <Input
                    clearErrors={clearErrors}
                    setError={setError}
                    xs={12}
                    placeholder="Observação"
                    control={control}
                    errors={errors?.observacao}
                    name="observacao"
                    title="Observação"
                  />
                  <Grid item xs={12} className="pt-4">
                    {/* Adicionar fotos e mostrar preview */}
                    <UploadPhotos
                      files={files}
                      setFiles={setFiles}
                      previews={previews}
                      setPreviews={setPreviews}
                    />
                  </Grid>
                  <Grid item xs={12} className="pt-4">
                    <div className="flex justify-between gap-4">
                      <Button
                        color="primary"
                        name="Salvar"
                        disabled={!isValid}
                        type="submit"
                      />
                      <Button
                        color="default"
                        name="Voltar"
                        onClick={() => history.push('/')}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  )
}

export default Competion
