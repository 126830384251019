import { Card, CardContent } from '@mui/material'
import { formatarMoedaBRL } from '../../utils/functions'

const Pagamentos = ({ pagamento, index, numeroPagamentos }) => {
  return (
    <Card>
      <CardContent
        className={`rounded-[4px] border-2 flex gap-4 items-center  ${
          pagamento.operacao === 'p'
            ? 'border-primary'
            : pagamento.operacao === 'c'
            ? 'border-danger'
            : 'border-warning'
        }`}
      >
        <div>
          <p className="text-xs opacity-80">{`${
            index + 1
          }/${numeroPagamentos}`}</p>
        </div>
        <div>
          <div className="flex justify-between items-center w-full gap-2">
            <div className="flex items-center gap-4">
              <div>
                <p className="text-sm opacity-80">Forma de pagamento</p>

                <p className="font-medium">
                  {pagamento.data}
                  <span className="text-xs opacity-70">
                    {` (${pagamento.formaPagamento})`}
                  </span>
                </p>
              </div>
            </div>
            <div className="text-right">
              <p className={`text-sm opacity-80 text-right `}>
                {pagamento.operacao === 'p'
                  ? 'Pagamento'
                  : pagamento.operacao === 'c'
                  ? 'Cancelado'
                  : 'Estorno'}
              </p>

              <p className="font-medium">
                {formatarMoedaBRL(Number(pagamento.valor))}
              </p>
            </div>
          </div>
          {pagamento.obs && (
            <div className="flex items-center gap-4 mt-2">
              <div>
                <p className="text-sm opacity-80">Observação</p>
                <p className="font-medium">{pagamento.obs}</p>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default Pagamentos
