import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
} from '@mui/material'
import Modal from '../../Modal'
import { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { api } from '../../../utils/api'
import ModalPoliticas from './ModalPoliticas'
import toast from 'react-hot-toast'
import { firstName } from '../../../utils/functions'

const ModalPoliticasAccept = ({ isOpen, onClose, acceptedPolicies }) => {
  const { user } = useAuth()
  const [politicaPrivacidade, setPoliticaPrivacidade] = useState(false)
  const [termosUso, setTermosUso] = useState(false)
  const [openModalPoliticas, setOpenModalPoliticas] = useState({
    politicaPrivacidade: false,
    termosUso: false,
  })

  const handleSave = async () => {
    if (!termosUso || !politicaPrivacidade) return

    try {
      const response = await fetch(`${api}home/modalPoliticas`, {
        method: 'POST',
        body: JSON.stringify({
          banco: user.clube.banco,
          pessoaID: user.pessoa.pessoaID,
          usuarioID: user.pessoa.usuarioID,
          clubeID: user.clube.clubeID,
          politicaPrivacidade: politicaPrivacidade ? 1 : 0,
          termosUso: termosUso ? 1 : 0,
        }),
      })
      const res = await response.json()
      acceptedPolicies()
      toast.success(
        `Olá ${firstName(user.pessoa.nome)}, seja bem-vindo ao SisArma!`
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/* Modal da politica especifica selecionada, saber mais */}
      <ModalPoliticas
        isOpen={openModalPoliticas}
        onClose={() => {
          setOpenModalPoliticas({
            politicaPrivacidade: false,
            termosUso: false,
          })
        }}
      />
      <Modal
        zIndex="z-20"
        isOpen={isOpen}
        title="Termos de uso"
        titleBnt1="Confirmar"
        titleBnt2="Sair"
        handleSave={handleSave}
        disabledBnt1={!termosUso || !politicaPrivacidade}
        closeIcon={false}
        onClose={onClose}
      >
        <h1 className="font-medium pb-4">
          Leia as políticas e termos de uso para continuar
        </h1>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={politicaPrivacidade}
                onChange={() => setPoliticaPrivacidade(!politicaPrivacidade)}
              />
            }
            label={
              <Typography sx={{ fontSize: 14 }}>
                Concordo com as{' '}
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenModalPoliticas({
                      politicaPrivacidade: true,
                      termosUso: false,
                    })
                  }}
                >
                  políticas de privacidade
                </Link>
              </Typography>
            }
          />
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={termosUso}
                onChange={() => setTermosUso(!termosUso)}
              />
            }
            label={
              <Typography sx={{ fontSize: 14 }}>
                Concordo com os{' '}
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenModalPoliticas({
                      politicaPrivacidade: false,
                      termosUso: true,
                    })
                  }}
                >
                  termos de uso
                </Link>
              </Typography>
            }
          />
        </FormGroup>
      </Modal>
    </>
  )
}

export default ModalPoliticasAccept
