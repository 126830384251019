import { Card, CardContent } from '@mui/material'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'
import { FaRegClock } from 'react-icons/fa'
import IconBack from '../components/IconBack'
import { useEffect, useState } from 'react'
import { useAuth } from '../context/AuthContext'
import { api } from '../utils/api'
import Etiqueta from '../components/Etiqueta'
import Photos from '../components/historicoDetalhado/Photos'
import Badge from '../components/Badge'

const HistoricoDetalhes = () => {
  const history = useHistory()
  const { id, tipo } = useParams()
  const { user, validateAccess } = useAuth()
  const [data, setData] = useState([])
  const getData = async () => {
    try {
      const response = await fetch(`${api}historico/detalhes/getData`, {
        method: 'POST',
        body: JSON.stringify({
          banco: user.clube.banco,
          clubeID: user.clube.clubeID,
          pessoaID: user.pessoa.pessoaID,
          id,
          tipo,
        }),
      })
      const res = await response.json()
      setData(res)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    validateAccess()
    if (id && tipo) {
      getData()
    }
  }, [id, tipo])

  return (
    data && (
      <section>
        <Card className="relative">
          <div
            className="absolute top-6 right-5 text-xl cursor-pointer"
            onClick={() => history.push('/historico')}
          >
            <IconBack />
          </div>

          <div className="p-5">
            <div className="flex items-center gap-1">
              <FaRegClock />
              <h1 className="text-lg font-semibold">Detalhes do histórico</h1>
            </div>
          </div>
        </Card>
        {/* Dados gerais */}
        <Card className="mt-2">
          <CardContent className="relative">
            <h1 className="text-lg pb-4 font-medium">Dados gerais</h1>
            <Etiqueta
              name={tipo === 't' ? 'Treinamento' : 'Competicão'}
              className={`rotate-[35deg] px-8 top-4 -right-7  ${
                tipo === 't' ? 'bg-warning' : 'bg-primary'
              }`}
            />
            {data &&
              data.camposDinamicos &&
              data.camposDinamicos.length > 1 && (
                <div>
                  <p className="text-sm opacity-80 ">
                    {data.camposDinamicos[1].name}
                  </p>
                  <div className="flex items-center gap-2">
                    <p className="font-medium">
                      {data.camposDinamicos[1].value}
                    </p>
                    <Badge
                      background={
                        data.camposDinamicos[8].value === '1'
                          ? 'bg-primary'
                          : 'bg-neutral-500'
                      }
                      color="text-white"
                      label={
                        data.camposDinamicos[8].value === '1'
                          ? 'Confirmado'
                          : 'Recusado'
                      }
                    />
                  </div>
                </div>
              )}

            <div className="grid grid-cols-2 gap-4 pt-4">
              {data &&
                data?.camposDinamicos?.map((row) => {
                  if (row.name !== 'Arma' && row.value) {
                    return (
                      <div>
                        <p className="text-sm opacity-80 ">{row.name}</p>
                        <p className="font-medium">{row.value}</p>
                      </div>
                    )
                  }
                })}
            </div>
          </CardContent>
        </Card>
        {/* Fotos */}
        <Photos data={data} />
      </section>
    )
  )
}

export default HistoricoDetalhes
