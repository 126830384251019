import { Alert, Avatar, Card, CardContent } from '@mui/material'
import { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const Photos = ({ data }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openLightbox = (index) => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  return data.photos && data.photos.length > 0 ? (
    <Card className="mt-2">
      <CardContent className="relative">
        <h1 className="text-lg pb-4 font-medium">Fotos</h1>
        {data.photos && data.photos.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-4 md:gap-2 gap-3">
            {data.photos.map((photo, index) => (
              <Avatar
                index={index}
                src={photo.path}
                onClick={() => openLightbox(index)}
                className="cursor-pointer"
                sx={{
                  width: 'auto',
                  height: '180px',
                  border: '1px solid #b1b1b1',
                }}
                variant="rounded"
              />
            ))}
            {isOpen && (
              <Lightbox
                mainSrc={data.photos[photoIndex].path}
                nextSrc={
                  data.photos[(photoIndex + 1) % data.photos.length].path
                }
                prevSrc={
                  data.photos[
                    (photoIndex + data.photos.length - 1) % data.photos.length
                  ].path
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + data.photos.length - 1) % data.photos.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % data.photos.length)
                }
              />
            )}
          </div>
        ) : (
          <div>
            <Alert color="warning">Sem fotos</Alert>
          </div>
        )}
      </CardContent>
    </Card>
  ) : (
    <div className="mt-2">
      <Alert color="warning">Sem fotos</Alert>
    </div>
  )
}

export default Photos
