import { Card, CardContent } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const NotData = () => {
  const theme = useTheme()

  return (
    <Card
      sx={{
        backgroundColor: `${theme.palette.card.main}`,
      }}
      className={`cursor-pointer transition-all`}
    >
      <CardContent className="relative">
        <h1 className="text-lg font-medium">Nenhum dado encontrado!</h1>
      </CardContent>
    </Card>
  )
}

export default NotData
