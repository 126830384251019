import React, { useEffect, useState } from 'react'
import { detalhadoType } from '../../types/financas'
import { Card, CardContent } from '@mui/material'
import { formatarMoedaBRL } from '../../utils/functions'
import Pagamentos from './Pagamentos'
import Etiqueta from '../Etiqueta'
import NotData from '../../components/NotData'

const initialState = detalhadoType
const Detalhado = ({ values, quitados }) => {
  const [data, setData] = useState(initialState)

  const somaPagamentos = (pagamentos) => {
    return pagamentos.reduce((acc, item) => {
      if (item.operacao === 'p') {
        return acc + Number(item.valor)
      } else {
        return acc
      }
    }, 0)
  }

  useEffect(() => {
    setData(values)
  }, [values])
  return values.length > 0 ? (
    <>
      <h1 className="py-2 font-semibold text-lg">Detalhado</h1>
      <div className="flex flex-col gap-3">
        {/* Financeiro */}
        {data.map((item) => {
          if (item.situacao === 'pendente' || quitados) {
            return (
              <Card>
                <CardContent className="relative">
                  <Etiqueta
                    name={item.situacao === 'pendente' ? 'aberto' : 'pago'}
                    className={`${
                      item.situacao === 'pendente'
                        ? 'bg-warning px-4'
                        : 'bg-primary px-5'
                    }`}
                  />
                  <div className="flex justify-between items-center pt-3">
                    <div>
                      <p className="text-sm opacity-80 ">Data</p>
                      <p className="font-medium">{item.data}</p>
                    </div>
                    <div>
                      <p className="text-sm opacity-80 text-right">
                        Valor total
                      </p>
                      <p className="font-bold text-danger">
                        {formatarMoedaBRL(Number(item.valor))}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center pt-3">
                    <div>
                      <p className="text-sm opacity-80 ">Origem</p>
                      <p className="font-medium">{item.origem}</p>
                    </div>
                    <div>
                      <p className="text-sm opacity-80 text-right">
                        Valor pago
                      </p>
                      <p className="font-bold text-primary">
                        {formatarMoedaBRL(
                          Number(somaPagamentos(item.pagamentos))
                        )}
                      </p>
                    </div>
                  </div>
                  {/* Pagamentos */}
                  {item.pagamentos.length > 0 && (
                    <div className="space-y-3 pt-3">
                      {item.pagamentos.map((pagamento, index) => {
                        return (
                          <Pagamentos
                            numeroPagamentos={item.pagamentos.length}
                            pagamento={pagamento}
                            index={index}
                          />
                        )
                      })}
                    </div>
                  )}
                </CardContent>
              </Card>
            )
          }
        })}
      </div>
    </>
  ) : (
    <NotData />
  )
}

export default Detalhado
