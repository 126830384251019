export const treinamentoTypes = {
  armasPessoa: [
    {
      nome: '',
      id: '',
      pessoaArmaID: '',
      numeroSerie: '',
      imagem: '',
      numeroControle: '',
      tipo: '',
      sistemaControle: '',
    },
  ],
  armasAll: [
    {
      nome: '',
      imagem: '',
      id: '',
    },
  ],
  verifyTreinamentCurrentDate: '',
}
