import { Card, CardContent } from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { FaRegComments } from 'react-icons/fa6'
import IconBack from '../components/IconBack'
import { useAuth } from '../context/AuthContext'
import alert from '../assets/mensagem/alert.png'

const Mensagem = () => {
  const history = useHistory()
  const { validateAccess } = useAuth()

  useEffect(() => {
    validateAccess()
    const userFormat = {
      ...JSON.parse(localStorage.getItem('@sisarma-socio-notification')),
      mensagem: '3',
    }
    localStorage.setItem(
      '@sisarma-socio-notification',
      JSON.stringify(userFormat)
    )

    document.title = 'SisarmaApp | Mensagem'
  }, [validateAccess])

  return (
    <section>
      <Card className="relative">
        <div
          className="absolute top-6 right-5 text-xl cursor-pointer"
          onClick={() => history.push('/')}
        >
          <IconBack />
        </div>
        <div className="p-5">
          <div className="flex items-center gap-1">
            <FaRegComments />
            <h1 className="text-lg font-semibold">Mensagem</h1>
          </div>
        </div>
      </Card>
      <Card className="mt-2">
        <CardContent>
          <a
            href="https://www.instagram.com/stories/highlights/18045805648740399/"
            target="_blank"
            rel="noreferrer"
            className="flex justify-center cursor-pointer"
          >
            <img src={alert} className="bg-cover" alt="Mensagem alerta" />
          </a>
        </CardContent>
      </Card>
    </section>
  )
}

export default Mensagem
