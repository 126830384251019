import { createContext, useContext, useState } from 'react'
import { competionCreate, treinamentCreate } from '../types/habitualidade'

const initialState = {
  competicao: competionCreate,
  setCompeticao: () => {},
  treinamento: treinamentCreate,
  setTreinamento: () => {},
}
const HabitualidadeContext = createContext(initialState)

const HabitualidadeProvider = ({ children }) => {
  const [competicao, setCompeticao] = useState(competionCreate)
  const [treinamento, setTreinamento] = useState(treinamentCreate)

  const values = {
    competicao,
    setCompeticao,
    treinamento,
    setTreinamento,
  }

  return (
    <HabitualidadeContext.Provider value={values}>
      {children}
    </HabitualidadeContext.Provider>
  )
}

const useHabitualidadeContext = () => {
  return useContext(HabitualidadeContext)
}

export { HabitualidadeContext, HabitualidadeProvider, useHabitualidadeContext }
