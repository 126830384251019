import { isValid } from 'date-fns'
import heic2any from 'heic2any';

function formatarMoedaBRL(valor) {
  return valor.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

const firstName = (name) => {
  return name.split(' ')[0]
}

// Formata data para o formato brasileiro
function formatDate(data) {
  if (isValid(new Date(data))) {
    // Adiciona um dia à data fornecida
    var dataComUmDia = new Date(data)
    dataComUmDia.setDate(dataComUmDia.getDate() + 1)

    // Formata a data com um dia adicionado
    return dataComUmDia.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  } else {
    return 'Data inválida'
  }
}

function mostrarTextoLimitado(texto, limite) {
  if (texto.length > limite) {
    return texto.substring(0, limite) + '...'
  }
  return texto
}


// Converte data do formato dd/mm/yyyy para Mon Dec 18 2023 00:00:00 GMT-0300 (Horário Padrão de Brasília
const convertStringToDate = dateString => {
  const parts = dateString.split('/')
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10) - 1
  const year = parseInt(parts[2], 10)
  return new Date(year, month, day)
}

const cleanJSONString = (jsonString) => {
  return jsonString.replace(/[\u0000-\u001F\u007F-\u009F]/g, '')
}

function formatCellPhone(numero) {
  numero = numero.replace(/\D/g, ''); // Remove caracteres não numéricos
  numero = numero.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'); // Formata como (XX) XXXXX-XXXX
  return numero;
}

function parseDate(dateString) {
  if (!dateString) return null
  const parts = dateString.split(' ')
  const dateParts = parts[0].split('/')
  let hour = 0
  let minute = 0
  const [day, month, year] = dateParts.map((part) => parseInt(part, 10))
  return new Date(year, month - 1, day, hour, minute)
}

function convertHeicToJpg(heicFile) {
  return new Promise((resolve, reject) => {
    heic2any({ blob: heicFile })
      .then((result) => {
        const jpgBlob = new Blob([result], { type: 'image/jpeg' });
        resolve(jpgBlob);
      })
      .catch((error) => {
        console.error("Erro na conversão heic2any:", error);
        reject(error);
      });
  });
}



export { formatarMoedaBRL, firstName, formatDate, mostrarTextoLimitado, convertStringToDate, cleanJSONString, formatCellPhone, parseDate, convertHeicToJpg }