import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import { useAuth } from '../context/AuthContext'

const DefaultLayou = ({ children, setCurrentTheme, currentTheme }) => {
  const { user } = useAuth()
  if (!user) return null

  return (
    <main className="min-h-screen flex flex-col">
      <NavBar setCurrentTheme={setCurrentTheme} currentTheme={currentTheme} />
      <div className="w-full md:w-[60%] px-2 md:px-0 mx-auto flex-1 pt-2">
        {children}
      </div>
      <Footer />
    </main>
  )
}

export default DefaultLayou
