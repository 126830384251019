import { Grid, FormControl, Autocomplete, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

const Select = ({
  xs,
  md,
  title,
  options,
  name,
  type,
  clearErrors,
  limitTags,
  setError,
  value,
  required,
  control,
  // trigger,
  disabled,
  multiple,
  setValue,
  errors,
  onChange,
  className,
  createNew,
  onSelect,
}) => {
  return (
    <Grid item xs={xs} md={md} sx={{ my: 1 }} className={className}>
      <div className="relative">
        <FormControl fullWidth>
          <Controller
            name={name}
            control={control}
            defaultValue={value}
            rules={{ required }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple={multiple}
                limitTags={limitTags}
                size="small"
                options={options}
                getOptionLabel={(option) =>
                  option?.cnpj
                    ? `${option.cnpj} - ${option.nome}`
                    : option?.nome
                }
                value={
                  multiple && field.value && field.value.length > 0
                    ? field.value.map((item) =>
                        options.find((option) => option.id === item.id)
                      )
                    : field.value ?? { nome: '' }
                }
                disabled={disabled}
                onChange={(e, newValue) => {
                  if (onSelect) {
                    onSelect(newValue)
                  }

                  onChange && onChange(newValue)
                  setValue(name, newValue)
                  if (newValue) {
                    clearErrors(name)
                  } else {
                    setError(name, {
                      type: 'required',
                      message: 'custom message',
                    })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={title}
                    placeholder={title}
                    error={errors ? true : false}
                  />
                )}
                noOptionsText="Sem opções"
              />
            )}
          />
        </FormControl>
      </div>
    </Grid>
  )
}

export default Select
