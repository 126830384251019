import { Card, CardContent, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PiPlusCircleLight } from 'react-icons/pi'
import { useAuth } from '../context/AuthContext'
import { armType } from '../types/habitualidade'
import WeaponSelect from '../components/WeaponSelect'
import ModalArm from '../components/acervoPessoal/ModalArm'
import { useHistory } from 'react-router-dom'
import { FaPersonRifle } from 'react-icons/fa6'
import IconBack from '../components/IconBack'
import { useGetData } from '../context/getDataContext'

const AcervoPessoal = () => {
  const { validateAccess } = useAuth()
  const {
    getDataAcervoPessoal,
    data: { acervoPessoal: data },
  } = useGetData()

  const history = useHistory()
  const [openModalNew, setOpenModalNew] = useState(false)
  const [armaSelecionadaAcervo, setArmaSelecionadaAcervo] = useState(armType)

  useEffect(() => {
    validateAccess()
    getDataAcervoPessoal()
    document.title = 'SisarmaApp | Acervo Pessoal'
  }, [validateAccess])

  return (
    <>
      {openModalNew && (
        <ModalArm
          open={openModalNew}
          setOpenModalNew={setOpenModalNew}
          OnClose={() => {
            setOpenModalNew(false)
            setArmaSelecionadaAcervo('')
          }}
          data={data}
          getData={getDataAcervoPessoal}
          armaSelecionadaAcervo={armaSelecionadaAcervo}
        />
      )}
      <section>
        <Card className=" mt-5 relative">
          <div
            className="absolute top-6 right-5 text-xl cursor-pointer"
            onClick={() => history.push('/')}
          >
            <IconBack />
          </div>
          <div className="p-5">
            <div className="flex items-center gap-1">
              <FaPersonRifle />
              <h1 className="text-lg font-semibold">Acervo Pessoal</h1>
            </div>
          </div>

          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} className="pt-4">
                <button
                  className="w-full p-3 border border-black/20 flex gap-1 items-center justify-center cursor-pointer rounded-2xl bg-primary text-white opacity-90 hover:opacity-100 transition-colors"
                  onClick={() => setOpenModalNew(true)}
                >
                  <PiPlusCircleLight className="text-3xl" />
                  <span className="text-lg">Adicionar uma arma</span>
                </button>
              </Grid>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 mx-auto gap-3 pt-6 px-2">
                {data &&
                  data?.armasPessoa.map((arma) => {
                    return (
                      <div className="relative">
                        <WeaponSelect
                          className={`${
                            arma?.status == 0 && 'opacity-50'
                          } hover:opacity-100`}
                          inative={arma?.status == 0}
                          onClick={() => {
                            setOpenModalNew(true)
                            setArmaSelecionadaAcervo(arma)
                          }}
                          name={arma.nome}
                          url={arma.imagem}
                          numeroSerie={arma.numeroSerie}
                        />
                      </div>
                    )
                  })}
              </div>
            </Grid>
          </CardContent>
        </Card>
      </section>
    </>
  )
}

export default AcervoPessoal
