import { useState, useEffect } from 'react'
import { Card } from '@mui/material'
import { FaRegClock } from 'react-icons/fa'
import IconBack from '../components/IconBack'
import { useHistory } from 'react-router-dom'
import React from 'react'
import { useAuth } from '../context/AuthContext'
import List from '../components/Historico/List'
import FilterHeader from '../components/Historico/FilterHeader'
import Pagination from '../components/Historico/Pagination'
import NotData from '../components/NotData'
import { useForm } from 'react-hook-form'
import Resumo from '../components/Historico/Resumo'
import { parseDate } from '../utils/functions'
import { isValid } from 'date-fns'
import { useGetData } from '../context/getDataContext'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export default function Historico() {
  const history = useHistory()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const query = params.get('date')

  const { validateAccess } = useAuth()
  const {
    getDataHistorico,
    data: { historico: data },
  } = useGetData()
  const [dataPage, setDataPage] = useState([])
  const [tabData, setTabData] = useState([])
  const [calibres, setCalibres] = useState([])

  const form = useForm()

  const handleClick = (id, tipo) => {
    const formTipo = tipo === 'Treinamento' ? 't' : 'c'
    history.push(`/historico/${formTipo}/${id}`)
  }

  // Filtra por calibre
  const filterCaliber = async (data) => {
    const calibre = form.getValues().calibre
    if (!form.getValues().calibre) {
      return data
    } else {
      const filteredData = data.filter((d) => d.calibreID === calibre.id)
      return filteredData
    }
  }

  // Filtra por data
  const filterDate = async (data) => {
    const dataIni = new Date(form.getValues().dataIni)
    dataIni.setHours(0, 0, 0, 0)
    dataIni.setDate(dataIni.getDate() + 1)

    const dataFim = new Date(form.getValues().dataFim)
    dataFim.setHours(0, 0, 0, 0)
    dataFim.setDate(dataFim.getDate() + 1)

    if (!isValid(dataIni) && !isValid(dataFim)) {
      return data
    }

    let dadosFiltrados = data

    if (isValid(dataIni) && isValid(dataFim)) {
      dadosFiltrados = dadosFiltrados.filter((dado) => {
        const dataDado = parseDate(dado.data)
        return dataDado >= dataIni && dataDado <= dataFim
      })
    }

    if (!isValid(dataIni) && isValid(dataFim)) {
      dadosFiltrados = dadosFiltrados.filter((dado) => {
        const dataDado = parseDate(dado.data)
        return dataDado <= dataFim
      })
    }

    if (isValid(dataIni) && !isValid(dataFim)) {
      dadosFiltrados = dadosFiltrados.filter((dado) => {
        const dataDado = parseDate(dado.data)
        return dataDado >= dataIni
      })
    }

    return dadosFiltrados
  }

  // Filtra pelo tab selecionado
  const tabFilter = async (data) => {
    const tab = form.getValues().tab
    if (tab === 'All' || tab === 'Resumo') {
      return data
    } else {
      const filteredData = data.filter((d) => d.tipo === tab)
      return filteredData
    }
  }

  // Chama todos os filtros
  const handleChangeFilter = async (data) => {
    let dataFiltered = data
    dataFiltered = await filterDate(data)
    dataFiltered = await tabFilter(dataFiltered)
    dataFiltered = await filterCaliber(dataFiltered)
    setTabData(dataFiltered)
  }

  const getData = async () => {
    const res = await getDataHistorico()
    // Função de filtro
    await handleChangeFilter(res)

    // Cria um array com os calibres do usuário
    const calibresMap = {}
    const arrayObjetos = res.reduce((acc, curr) => {
      if (!calibresMap[curr.calibreID]) {
        calibresMap[curr.calibreID] = curr.calibre
        acc.push({ id: curr.calibreID, nome: curr.calibre })
      }
      return acc
    }, [])
    setCalibres(arrayObjetos)
  }

  useEffect(() => {
    const userFormat = {
      ...JSON.parse(localStorage.getItem('@sisarma-socio-notification')),
      historico: '1',
    }
    localStorage.setItem(
      '@sisarma-socio-notification',
      JSON.stringify(userFormat)
    )

    validateAccess()

    getData()
    const dataAtual = new Date()
    const umAnoAtras = new Date(
      dataAtual.getFullYear() - 1,
      dataAtual.getMonth(),
      dataAtual.getDate() + 1
    )

    if (query) {
      const queryDate = new Date(query)
      const dataIni = new Date(queryDate)
      const dataFim = new Date(queryDate)

      form.reset({
        dataIni: dataIni.toISOString().slice(0, 10),
        dataFim: dataFim.toISOString().slice(0, 10),
      })

      form.setValue('tab', 'Treinamento')
    } else {
      form.reset({
        dataIni: umAnoAtras.toISOString().slice(0, 10),
        dataFim: dataAtual.toISOString().slice(0, 10),
      })
      form.setValue('tab', 'Resumo')
    }

    document.title = 'SisarmaApp | Histórico'
  }, [validateAccess, form, query])

  return (
    <section>
      <Card className={`relative`}>
        <div
          className="absolute top-6 right-5 text-xl cursor-pointer"
          onClick={() => history.push('/')}
        >
          <IconBack />
        </div>

        <div className="p-5">
          <div className="flex items-center gap-1">
            <FaRegClock />
            <h1 className="text-lg font-semibold">
              Histórico de Habitualidades
            </h1>
          </div>
        </div>
      </Card>

      <FilterHeader
        handleChangeFilter={handleChangeFilter}
        data={data}
        calibres={calibres}
        form={form}
      />
      {tabData.length === 0 ? (
        <NotData />
      ) : (
        <>
          {form.getValues().tab === 'Resumo' ? (
            <Resumo
              tabData={tabData}
              form={form}
              data={data}
              handleChangeFilter={handleChangeFilter}
            />
          ) : (
            <>
              <List dataPage={dataPage} handleClick={handleClick} />
              <Pagination
                data={data}
                setDataPage={setDataPage}
                dataPage={dataPage}
                tabData={tabData}
              />
            </>
          )}
        </>
      )}
    </section>
  )
}
