import { createContext, useContext, useEffect, useState } from 'react'
import { api } from '../utils/api'
import { AuthContext } from './AuthContext'

const selectTypes = [
  {
    id: 0,
    name: '',
  },
]

const armsAllTypes = [
  {
    id: 0,
    nome: '',
    imagem: '',
  },
]

const initialValues = {
  commonData: {
    professionalLog: selectTypes,
    armsAll: armsAllTypes,
  },
}

const CommonDataContext = createContext(initialValues)

const CommonDataProvider = ({ children }) => {
  const [commonData, setCommonData] = useState(initialValues.commonData)
  const { user } = useContext(AuthContext)

  const getData = async () => {
    const res = await fetch(`${api}commonData/getData`, {
      method: 'POST',
      body: JSON.stringify({
        banco: user.clube.banco,
        clubeID: user.clube.clubeID,
        pessoaID: user.pessoa.pessoaID,
        usuarioID: user.pessoa.usuarioID,
      }),
    })

    const data = await res.json()
    setCommonData(data)
  }

  useEffect(() => {
    if (user) getData()
  }, [user])

  const values = {
    commonData,
    getData,
  }
  return (
    <CommonDataContext.Provider value={values}>
      {children}
    </CommonDataContext.Provider>
  )
}

const useCommonData = () => {
  const context = useContext(CommonDataContext)
  if (context === undefined) {
    throw new Error('useCommonData must be used within a CommonDataProvider')
  }
  return context
}

export { CommonDataContext, CommonDataProvider, useCommonData }
