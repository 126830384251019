import React, { useState } from 'react'
import Modal from '../../Modal'
import { Alert, Card, Button } from '@mui/material'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import { useHabitualidadeContext } from '../../../context/HabitualidadeContext'

const ModalCompetitionDay = ({
  open,
  OnClose,
  data,
  reset,
  armaSelecionada,
  setCompetitionSelected,
  setType,
}) => {
  const [showMore, setShowMore] = useState(5)
  const { setCompeticao, competicao } = useHabitualidadeContext()

  const theme = useTheme()

  // Seleciona o evento clicado e seta algumas informações
  const handleClick = (item) => {
    const formattedDate = item.data.split('/').reverse().join('-')
    reset({
      ...reset,
      data: formattedDate,
      descricao: item.descricao.toUpperCase(),
      numeroControle: armaSelecionada.numeroControle,
      tipoEvento: {
        id: item.idTipoEvento,
        nome: item.nomeTipoEvento,
      },
      local: item.local.toUpperCase(),
      dataCadastro:
        item.dentroPrazo === '1' ? format(new Date(), 'yyyy-MM-dd') : null,
    })
    setCompeticao({
      ...competicao,
      data_: item.data,
      dataFim_: item.dataFim,
    })
    setCompetitionSelected({
      status: true,
      item,
    })
    OnClose()
  }

  // mostra mais eventos
  const handleShowMore = () => {
    setShowMore((prev) => prev + 5)
  }

  return (
    <Modal
      isOpen={open}
      onClose={() => setType(null)}
      onCloseIcon={() => setType(null)}
      handleSave={() => {}}
      title="Competição"
      size="sm"
      btn1={false}
    >
      <div className="flex flex-col gap-2 mt-4">
        {data &&
          data.slice(0, showMore).map((item, index) => (
            <Card
              key={index}
              sx={{ backgroundColor: theme.palette.card.main }}
              className="p-3 cursor-pointer border-2 border-transparent transition-all hover:border-primary rounded-lg "
              onClick={() => handleClick(item)}
            >
              {' '}
              <div>
                <p className="font-bold uppercase">{item.descricao}</p>
              </div>
              <div className="flex justify-between pt-3">
                <div>
                  <p className="text-sm opacity-80 ">Data</p>
                  <p>
                    {item.data === item.dataFim || !item.dataFim
                      ? item.data
                      : `${item.data} a ${item.dataFim}`}
                  </p>
                </div>
                <div></div>
              </div>
              <div className="flex justify-between pt-3">
                <div>
                  <p className="text-sm opacity-80 ">Tipo do evento</p>
                  <p>{item.nomeTipoEvento}</p>
                </div>
                <div>
                  <p className="text-sm opacity-80 text-right">Local</p>
                  <p className="uppercase">{item.local}</p>
                </div>
              </div>
            </Card>
          ))}
        {!data && data?.length === 0 && (
          <Alert severity="warning" className="mt-4">
            Nenhuma competição encontrada
          </Alert>
        )}
        {data && data?.length > showMore && (
          <Button
            variant="outlined"
            color="inherit"
            className={`!rounded-xl !border`}
            onClick={handleShowMore}
          >
            Ver Mais
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default ModalCompetitionDay
