import { Avatar } from '@mui/material'
import Modal from './Modal'

const ModalViewFile = ({ isOpen, onClose, file }) => {
  if (!file) return null

  const isPreviewImage = file.type === 'image/preview'
  const isBancoImage = file.type === 'image/banco'
  const isPreviewPDF = file.type === 'pdf/preview'
  const isBancoPDF = file.type === 'pdf/banco'

  const srcImagem = isPreviewImage ? file.preview : file?.preview?.imagem
  const srcPDF = isPreviewPDF ? file.preview : file?.preview?.imagem

  return (
    <Modal
      title={isPreviewImage || isBancoImage ? 'Imagem' : 'PDF'}
      isOpen={isOpen}
      onClose={onClose}
      onCloseIcon={onClose}
      btn1={false}
      zIndex="z-50"
    >
      {isPreviewImage || isBancoImage ? (
        <Avatar
          variant="rounded"
          alt={isPreviewImage ? 'Imagem Preview' : 'Imagem Banco'}
          src={srcImagem}
          sx={{
            width: 'auto',
            height: 'auto',
            border: '1px solid #b1b1b1',
          }}
        />
      ) : null}
      {isPreviewPDF || isBancoPDF ? (
        <iframe title="PDF Viewer" src={srcPDF} width="100%" height="500px" />
      ) : null}
    </Modal>
  )
}

export default ModalViewFile
