import { FaRegCreditCard } from 'react-icons/fa6'
import { Card, CardContent } from '@mui/material'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useHistory } from 'react-router-dom'
import IconBack from '../components/IconBack'
import { useAuth } from '../context/AuthContext'
import SwitchLabel from '../components/forms/SwitchLabel'
import Resumo from '../components/financeiro/Resumo'
import Detalhado from '../components/financeiro/Detalhado'
import { useGetData } from '../context/getDataContext'

const Financeiro = () => {
  const { validateAccess } = useAuth()
  const {
    getDataFinanceiro,
    data: { financeiro: data },
  } = useGetData()
  const [type, setType] = useState('resumo')
  const [quitados, setQuitados] = useState(false)

  const history = useHistory()

  const handleType = (value) => {
    setType(value)
  }

  const handleSwitchChange = (newValue) => {
    setQuitados(newValue)
  }

  useEffect(() => {
    validateAccess()
    getDataFinanceiro()
    document.title = 'SisarmaApp | Financeiro'
  }, [validateAccess])

  useEffect(() => {
    if (
      data &&
      data.detalhado.length === 1 &&
      data.detalhado[0].situacao === 'pago'
    ) {
      setQuitados(true)
    }
  }, [data])

  return (
    data && (
      <section>
        <Card className=" mt-5 relative">
          <div
            className="absolute top-6 right-5 text-xl cursor-pointer"
            onClick={() => history.push('/')}
          >
            <IconBack />
          </div>

          <div className="p-5">
            <div className="flex items-center gap-1">
              <FaRegCreditCard />
              <h1 className="text-lg font-semibold">Financeiro</h1>
            </div>
          </div>
          <CardContent>
            <div className="flex justify-between">
              <div className="flex justify-center items-center gap-1">
                <div
                  onClick={() => handleType('resumo')}
                  className={twMerge(
                    ` bg-primary opacity-90 hover:opacity-100 transition-colors p-2  px-4 text-white flex flex-col items-center justify-center gap-1 w-full cursor-pointer rounded-lg ${
                      type === 'resumo' ? 'bg-primary-focus' : ''
                    }`
                  )}
                >
                  <p className="text-white">Resumo</p>
                </div>
                <div
                  onClick={() => handleType('detalhado')}
                  className={twMerge(
                    `bg-primary opacity-90 hover:opacity-100  transition-colors p-2 px-4 text-white flex flex-col items-center justify-center gap-1 w-full cursor-pointer rounded-lg ${
                      type === 'detalhado' ? 'bg-primary-focus' : ''
                    }`
                  )}
                >
                  <p className="text-white">Detalhado</p>
                </div>
                {type === 'detalhado' && (
                  <div className="pl-4">
                    <SwitchLabel
                      xs={12}
                      title="Quitados"
                      value={quitados}
                      register={() => {}}
                      onChange={handleSwitchChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="pt-2">
          {type === 'resumo' && <Resumo values={data.resumo} />}
          {type && type === 'detalhado' && (
            <Detalhado values={data.detalhado} quitados={quitados} />
          )}
        </div>
      </section>
    )
  )
}

export default Financeiro
