import { Avatar, Button, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { IoCloseOutline } from 'react-icons/io5'
import { MdOutlineCloudUpload } from 'react-icons/md'
import { mostrarTextoLimitado } from '../../../utils/functions'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const UploadPhotos = ({ files, setFiles, previews, setPreviews }) => {
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files)
    setFiles([...files, ...newFiles])

    const newPreviews = newFiles.map((file) => URL.createObjectURL(file))
    setPreviews([...previews, ...newPreviews])
  }

  const handleRemoveFile = (index) => {
    URL.revokeObjectURL(previews[index])

    const updatedFiles = files.filter((_, i) => i !== index)
    const updatedPreviews = previews.filter((_, i) => i !== index)

    setFiles(updatedFiles)
    setPreviews(updatedPreviews)
  }

  return (
    <div>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<MdOutlineCloudUpload className="text-xl" />}
        className="w-full"
      >
        <div className="flex items-center gap-1">
          <p className="capitalize ">Inserir</p>
          <p className="lowercase"> imagem</p>
        </div>
        <VisuallyHiddenInput
          type="file"
          accept="*"
          name="files"
          multiple
          onChange={handleFileChange}
        />
      </Button>
      <div className="pt-4 flex flex-col gap-4">
        {previews &&
          previews.map((preview, index) => (
            <div
              key={index}
              className="flex items-center justify-between border-2 border-black/30 border-dashed rounded-lg  p-2"
            >
              <div className="flex items-center gap-2">
                <Avatar
                  variant="rounded"
                  alt={files[index]?.name}
                  src={preview}
                  sx={{
                    width: 56,
                    height: 56,
                    border: '1px solid #b1b1b1',
                  }}
                />
                <p className="text-sm opacity-90 sm:hidden">
                  {mostrarTextoLimitado(files[index]?.name, 20)}
                </p>
                <p className="text-sm opacity-90 hidden sm:block">
                  {files[index]?.name}
                </p>
              </div>
              <IconButton onClick={() => handleRemoveFile(index)}>
                <IoCloseOutline />
              </IconButton>
            </div>
          ))}
      </div>
    </div>
  )
}

export default UploadPhotos
