import React from 'react'
import { Card, CardContent } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Etiqueta from '../Etiqueta'
import Badge from '../Badge'

const List = ({ dataPage, handleClick }) => {
  const theme = useTheme()

  return (
    <div className="space-y-2">
      {dataPage.map((item) => {
        return (
          <Card
            sx={{
              backgroundColor: `${theme.palette.card.main}`,
              ':hover': { backgroundColor: `${theme.palette.action.hover}` },
            }}
            className={`cursor-pointer transition-all`}
            onClick={() => handleClick(item.id, item.tipo)}
          >
            <CardContent className="relative">
              {/* Etiqueta do card */}
              <Etiqueta
                name={item.tipo}
                className={`rotate-[35deg] px-8 top-4 -right-7 ${
                  item.tipo === 'Treinamento' ? 'bg-warning' : 'bg-primary'
                }`}
              />
              {/* Origem do treinamento / atendimento ou aplicativo */}
              <p className="text-xs absolute bottom-1 right-4 pb-1">
                {item.origem.mensagem}
              </p>

              {item.tipo === 'Competição' && (
                <h1 className="text-lg font-medium uppercase">
                  {item.descricao}
                </h1>
              )}
              <div className="flex justify-between items-center pt-3">
                <div>
                  <p className="text-sm opacity-80 ">Data</p>
                  <div className="flex items-center gap-2">
                    <p className="font-medium">{item.data}</p>

                    <Badge
                      background={
                        item.confirmado === '1'
                          ? 'bg-primary'
                          : 'bg-neutral-500'
                      }
                      color="text-white"
                      label={
                        item.confirmado === '1' ? 'Confirmado' : 'Recusado'
                      }
                    />
                  </div>
                </div>
                <div>
                  <p className="text-sm opacity-80 text-right">
                    {/* Valor total */}
                  </p>
                  <p className="font-bold text-danger">{/* aaaaa */}</p>
                </div>
              </div>
              <div className="flex justify-between items-center pt-3">
                <div>
                  <p className="text-sm opacity-80 ">Arma</p>
                  <p className="font-medium">{item.arma}</p>
                </div>
                <div>
                  <p className="text-sm opacity-80 text-right">Munição</p>
                  <p className="font-bold text-right">{item.municao}</p>
                </div>
              </div>
            </CardContent>
          </Card>
        )
      })}
    </div>
  )
}

export default List
