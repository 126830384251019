import React from 'react'
import Modal from '../Modal'
import { api } from '../../utils/api'
import { useAuth } from '../../context/AuthContext'
import toast from 'react-hot-toast'
import { useLoading } from '../../context/LoadingContext'

const ModalConfirm = ({
  isOpen,
  setIsOpen,
  onClose,
  armaSelecionadaAcervo,
  setOpenModalChangeStatus,
  getData,
}) => {
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()

  const onSubmit = async () => {
    startLoading()
    try {
      const response = await fetch(`${api}acervoPessoal/changeStatus`, {
        method: 'POST',
        body: JSON.stringify({
          id: armaSelecionadaAcervo.pessoaArmaID,
          status: armaSelecionadaAcervo.status === '1' ? '0' : '1',
          banco: user.clube.banco,
          clubeID: user.clube.clubeID,
          pessoaID: user.pessoa.pessoaID,
          usuarioID: user.pessoa.usuarioID,
          pendencia: armaSelecionadaAcervo.pendencia,
          numeroSerie: armaSelecionadaAcervo.numeroSerie,
        }),
      })
      const res = await response.json()
      toast.success(
        `Arma ${
          !armaSelecionadaAcervo.pendencia
            ? 'excluida'
            : armaSelecionadaAcervo.status === '1'
            ? 'inativada'
            : 'ativada'
        } com sucesso!`
      )
      getData()
      onClose()
    } catch (error) {
      console.log(error)
      toast.error(
        `Erro ao ${
          !armaSelecionadaAcervo.pendencia
            ? 'excluir'
            : armaSelecionadaAcervo.status === '1'
            ? 'inativar'
            : 'ativar'
        } arma!`
      )
    } finally {
      stopLoading()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleSave={onSubmit}
      onClose={() => setOpenModalChangeStatus(false)}
      onCloseIcon={() => setOpenModalChangeStatus(false)}
      colorBtn1={
        !armaSelecionadaAcervo.pendencia || armaSelecionadaAcervo.status === '1'
          ? 'danger'
          : 'secondary'
      }
      titleBnt1={`${!armaSelecionadaAcervo.pendencia ? 'Excluir' : 'Salvar'}`}
      bnt1Type="button"
      title={
        !armaSelecionadaAcervo.pendencia
          ? 'Excluir arma'
          : armaSelecionadaAcervo.status === '1'
          ? 'Inativar arma'
          : 'Ativar arma'
      }
      size="sm"
      zIndex="z-30"
    >
      <h1 className="text-lg ">
        Deseja
        {!armaSelecionadaAcervo.pendencia
          ? ' excluir'
          : armaSelecionadaAcervo.status === '1'
          ? ' inativar'
          : ' ativar'}{' '}
        esta arma?
      </h1>
    </Modal>
  )
}

export default ModalConfirm
