import { ButtonGroup, Button } from '@mui/material'
import Input from '../../components/forms/Input'
import Select from '../../components/forms/Select'

const FilterHeader = ({ calibres, data, form, handleChangeFilter }) => {
  return (
    <div className="pt-4 pb-2 space-y-4">
      {/* Navegação */}
      <ButtonGroup aria-label="Basic button group" className="w-full">
        <Button
          onClick={() => {
            handleChangeFilter(data)
            form.setValue('tab', 'Resumo')
          }}
          variant={form.getValues().tab === 'Resumo' ? 'contained' : 'outlined'}
        >
          Resumo
        </Button>
        <Button
          onClick={() => {
            handleChangeFilter(data)
            form.setValue('tab', 'All')
          }}
          variant={form.getValues().tab === 'All' ? 'contained' : 'outlined'}
        >
          Todos
        </Button>
        <Button
          onClick={() => {
            handleChangeFilter(data)
            form.setValue('tab', 'Treinamento')
          }}
          variant={
            form.getValues().tab === 'Treinamento' ? 'contained' : 'outlined'
          }
        >
          Treinamento
        </Button>
        <Button
          onClick={() => {
            handleChangeFilter(data)
            form.setValue('tab', 'Competição')
          }}
          variant={
            form.getValues().tab === 'Competição' ? 'contained' : 'outlined'
          }
        >
          Competição
        </Button>
      </ButtonGroup>

      {/* Filtros */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-2">
        <Input
          placeholder="Data inicial"
          control={form.control}
          clearErrors={form.clearErrors}
          setError={form.setError}
          onChange={() => {
            handleChangeFilter(data)
          }}
          errors={form.errors?.dataIni}
          allowPastDates={true}
          type="date"
          name="dataIni"
          title="Data inicio"
        />
        <Input
          placeholder="Data fim"
          control={form.control}
          clearErrors={form.clearErrors}
          setError={form.clearErrors}
          errors={form.errors?.dataFim}
          onChange={(e) => {
            const currentDate = new Date()
            const date = new Date(e)
            if (date > currentDate) {
              form.setValue('dataFim', currentDate.toISOString().slice(0, 10))
            }
            handleChangeFilter(data)
          }}
          allowPastDates={true}
          type="date"
          name="dataFim"
          title="Data fim"
          maxDate={new Date().toISOString().slice(0, 10)}
        />
        <Select
          title="Calibre"
          name="calibre"
          type="string"
          options={calibres ?? []}
          onChange={() => {
            handleChangeFilter(data)
          }}
          register={form.register}
          setValue={form.setValue}
          control={form.control}
          errors={form.errors?.calibre}
          clearErrors={form.clearErrors}
          setError={form.setError}
        />
      </div>
    </div>
  )
}

export default FilterHeader
