export const competicaoTypes = {
  armasPessoa: [
    {
      nome: '',
      id: '',
      pessoaArmaID: '',
      numeroSerie: '',
      imagem: '',
      numeroControle: '',
      tipo: '',
      sistemaControle: '',
    },
  ],
  armasAll: [
    {
      nome: '',
      imagem: '',
      id: '',
    },
  ],
  modalidades: [
    {
      nome: '',
      id: '',
    },
  ],
  competicao: [
    {
      id: '',
      data: '',
      data_: '',
      dataFim: '',
      dataFim_: '',
      descricao: '',
      idTipoEvento: '',
      nomeTipoEvento: '',
      dentroPrazo: '',
      local: '',
    },
  ],
}
