import { useEffect, useState } from 'react'
import Button from './forms/Button'
import { IoCloseOutline } from 'react-icons/io5'
import { twMerge } from 'tailwind-merge'
import { useLoading } from '../context/LoadingContext'
import { useTheme } from '@mui/material/styles'

const Modal = ({
  isOpen,
  size,
  title,
  subTitle,
  onClose,
  onCloseIcon,
  handleSave,
  children,
  titleBnt1,
  colorBtn1 = 'primary',
  disabledBnt1 = false,
  classNameBtn1,
  bnt1Type = 'submit',
  isValid,
  classNameBtn2,
  colorBtn2 = 'default',
  titleBnt2,
  zIndex = 'z-20',
  effectLoading = true,
  closeIcon = true,
  btn1 = true,
}) => {
  const { isLoading } = useLoading()
  const [isModalOpen, setIsModalOpen] = useState(isOpen)
  const theme = useTheme()

  useEffect(() => {
    setIsModalOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    const handleClickOutside = (e) => {
      if (e.target === e.currentTarget) {
        onClose()
      }
    }

    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyPress)
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }

    return () => {}
  }, [isModalOpen, onClose])

  const getContainerClasses = () => {
    switch (size) {
      case 'sm':
        return 'w-11/12 md:w-6/12'
      case 'md':
        return 'w-11/12 md:w-8/12'
      case 'lg':
        return 'w-11/12 md:w-10/12'
      default:
        return 'w-11/12 sm:w-8/12 md:w-3/12'
    }
  }

  return (
    <>
      {isModalOpen && (
        <div
          className={`modal-container fixed inset-0 ${zIndex} flex items-center justify-center`}
          onClick={onClose}
        >
          <div className="fixed inset-0 bg-black opacity-40"></div>
          <div
            className={`w-[96vw] ${
              theme.palette.mode === 'dark' ? 'bg-[#18181B]' : 'bg-white'
            } rounded-lg shadow-md relative ${getContainerClasses()}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-4 flex justify-between items-center">
              <div className="text-xl font-medium">{title}</div>
              <div className="text-sm font-medium opacity-80">{subTitle}</div>
              {closeIcon && (
                <IoCloseOutline
                  onClick={onCloseIcon}
                  className="cursor-pointer text-2xl font-thin"
                />
              )}
            </div>
            <div
              className="overflow-auto p-4"
              style={{ maxHeight: 'calc(90vh - 200px)' }}
            >
              {children}
            </div>
            <div className="bg-gray-200 p-4 flex justify-end gap-2">
              {btn1 && (
                <Button
                  color={colorBtn1}
                  type={bnt1Type}
                  isValid={isValid}
                  onClick={handleSave}
                  name={titleBnt1 ?? 'Salvar'}
                  disabled={disabledBnt1 || isLoading}
                  effectLoading={effectLoading}
                />
              )}
              <Button
                className={twMerge(`${classNameBtn2}`)}
                color={colorBtn2}
                onClick={onClose}
                name={titleBnt2 ?? 'Fechar'}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Modal
