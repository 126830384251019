function validationCPF(cpf) {
  // Remove caracteres indesejados do CPF
  cpf = cpf.replace(/[^\d]+/g, '')

  // Valida a quantidade de dígitos do CPF
  if (cpf.length !== 11) {
    return false
  }

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cpf)) {
    return false
  }

  // Algoritmo de validação do CPF
  let sum = 0
  let rest
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }
  rest = (sum * 10) % 11
  if ((rest === 10) || (rest === 11)) {
    rest = 0
  }
  if (rest !== parseInt(cpf.substring(9, 10))) {
    return false
  }
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }
  rest = (sum * 10) % 11
  if ((rest === 10) || (rest === 11)) {
    rest = 0
  }
  if (rest !== parseInt(cpf.substring(10, 11))) {
    return false
  }

  return true
}


function validarEmail(email) {
  // Expressão regular para validar email
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export { validationCPF, validarEmail }