import { useEffect } from 'react'
import { useFilter } from '../../context/FilterContext'
import Input from '../forms/Input'
import { useCommonData } from '../../context/CommonDataContext'
import CustomSelect from '../forms/CustomSelect'

const Filters = () => {
  const { commonData } = useCommonData()
  const {
    form,
    setNames,
    filterDate,
    SelectFilterByName,
    data,
    setFilteredData,
    filteredData,
    handleSearch,
    key,
  } = useFilter()
  let dataFiltered = data

  const onSubmit = async () => {
    dataFiltered = await handleSearch(dataFiltered)
    dataFiltered = await filterDate(dataFiltered)
    dataFiltered = await SelectFilterByName(dataFiltered, 'usuario')
    setFilteredData(dataFiltered)
  }

  //* Função para acionar o formulario de filtro do contexto (useFilter())
  useEffect(() => {
    onSubmit()
  }, [key])

  useEffect(() => {
    setNames(['dataInicio', 'dataFim', 'usuario'])
  }, [filteredData, setNames])

  return (
    <>
      <Input
        xs={12}
        md={6}
        type="date"
        title="Data inicio"
        name="dataInicio"
        control={form.control}
      />
      <Input
        xs={12}
        md={6}
        type="date"
        title="Data Fim"
        name="dataFim"
        control={form.control}
      />
      <CustomSelect
        xs={12}
        md={12}
        title="Profissional"
        name="usuario"
        form={form}
        options={commonData.professionalLog}
        value={form.getValues('usuario')?.name}
      />
    </>
  )
}
export default Filters
