import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import CardsInitialsData from '../../data/CardsInitialsData'

const CardsInitial = ({ value }) => {
  const data = CardsInitialsData()
  const localStorageData = JSON.parse(
    localStorage.getItem('@sisarma-socio-notification')
  )

  return (
    value && (
      <div className="h-full w-full">
        <div className="grid grid-cols-2 gap-2 h-full w-full ">
          {data.map((card) => {
            return (
              <Link
                key={card.id}
                to={card.route}
                className={twMerge(
                  `bg-primary opacity-90 hover:opacity-100 h-full rounded-xl flex flex-col items-center justify-center gap-2 cursor-pointer transition-colors relative overflow-hidden
                }`
                )}
              >
                <div className="text-4xl text-gray relative">
                  {card.icon}
                  {card.id === 4 &&
                    !localStorageData?.historico &&
                    localStorageData?.historico !== '1' && (
                      <div
                        className={`absolute rounded-full bg-red-600 px-[6px] py-[2px] -top-2 -right-2`}
                      >
                        <div className="flex justify-center items-center rounded-full">
                          <p className="text-white font-medium text-xs">1</p>
                        </div>
                      </div>
                    )}
                  {card.id === 5 &&
                    (!localStorageData?.mensagem ||
                      localStorageData?.mensagem !== '3') && (
                      <div
                        className={`absolute rounded-full bg-red-600 px-[6px] py-[2px] -top-2 -right-2`}
                      >
                        <div className="flex justify-center items-center rounded-full">
                          <p className="text-white font-medium text-xs">1</p>
                        </div>
                      </div>
                    )}
                  {card.id === 6 && Number(value.quantidadePendencias) > 0 && (
                    <div
                      className={`absolute rounded-full bg-red-600 ${
                        Number(value.quantidadePendencias) < 10
                          ? 'px-[6px] py-[2px] -top-2 -right-2'
                          : Number(value.quantidadePendencias) >= 100
                          ? 'px-[4px] py-[6px] -top-4 -right-4'
                          : Number(value.quantidadePendencias) >= 1000
                          ? 'px-[6px] py-[8px] -top-4 -right-4'
                          : 'px-[4px] py-[2px] -top-2 -right-2'
                      }`}
                    >
                      <div className="flex justify-center items-center rounded-full">
                        <p className="text-white font-medium text-xs">
                          {value.quantidadePendencias}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <h1 className="text-white">{card.title}</h1>
              </Link>
            )
          })}
        </div>
      </div>
    )
  )
}

export default CardsInitial
