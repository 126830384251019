import {
  FaRegUser,
  FaRegClock,
  FaRegCalendarCheck,
  FaRegComments,
  FaRegCreditCard,
} from 'react-icons/fa'
import { FaPersonRifle } from 'react-icons/fa6'

const CardsInitialsData = () => {
  const data = [
    {
      id: 1,
      title: 'Perfil',
      icon: <FaRegUser />,
      route: '/perfil',
    },
    {
      id: 2,
      title: 'Habitualidade',
      icon: <FaRegClock />,
      route: '/habitualidade',
    },
    {
      id: 3,
      title: 'Acervo pessoal',
      icon: <FaPersonRifle />,
      route: '/acervo-pessoal',
    },
    {
      id: 4,
      title: 'Histórico',
      icon: <FaRegCalendarCheck />,
      route: '/historico',
    },
    {
      id: 5,
      title: 'Mensagem',
      icon: <FaRegComments />,
      route: '/mensagem',
    },
    {
      id: 6,
      title: 'Financeiro',
      icon: <FaRegCreditCard />,
      route: '/financeiro',
    },
  ]

  return data
}

export default CardsInitialsData
