import Modal from '../../Modal'
import PoliticaPrivacidade from './conteudo/PoliticaPrivacidade'
import TermosUso from './conteudo/TermosUso'

const ModalPoliticas = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen.politicaPrivacidade || isOpen.termosUso}
      onClose={onClose}
      titleBnt2="Ok, lido"
      onCloseIcon={onClose}
      title={`${
        isOpen.politicaPrivacidade
          ? 'Políticas de Privacidade'
          : 'Termos de Uso'
      }`}
      zIndex="z-30"
      btn1={false}
    >
      {isOpen.politicaPrivacidade ? <PoliticaPrivacidade /> : <TermosUso />}
    </Modal>
  )
}

export default ModalPoliticas
