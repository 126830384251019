import { Button, ButtonGroup, Grid } from '@mui/material'
import React from 'react'
import Input from '../forms/Input'
import Select from '../forms/Select'

const DataSeller = ({
  typeSeller,
  setTypeSeller,
  control,
  errors,
  register,
  setValue,
  clearErrors,
  setError,
  data,
}) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 className="text-lg font-medium pb-4">Dados da compra</h1>
        </Grid>
        <Grid item xs={12} className="relative">
          <label className="absolute text-sm -top-1 opacity-70 left-6 z-50 px-2 bg-transparent">
            Tipo
          </label>
          <ButtonGroup
            className="z-10"
            fullWidth
            disableElevation
            color="primary"
          >
            <Button
              variant={`${typeSeller === 1 ? 'contained' : 'outlined'}`}
              onClick={() => {
                setTypeSeller(1)
                setValue('typeSeller', '')
              }}
            >
              FÍSICA
            </Button>
            <Button
              variant={`${typeSeller === 2 ? 'contained' : 'outlined'}`}
              onClick={() => {
                setTypeSeller(2)
                setValue('typeSeller', '')
              }}
            >
              JURIDICA
            </Button>
          </ButtonGroup>
        </Grid>
        <Input
          clearErrors={clearErrors}
          setError={setError}
          xs={12}
          md={6}
          placeholder="Nome"
          control={control}
          errors={errors?.nameSeller}
          name="nameSeller"
          title="Nome"
        />
        <Input
          clearErrors={clearErrors}
          setError={setError}
          xs={12}
          md={6}
          placeholder={typeSeller === 1 ? 'CPF' : 'CNPJ'}
          control={control}
          errors={errors?.numberSeller}
          name="numberSeller"
          title={typeSeller === 1 ? 'CPF' : 'CNPJ'}
          mask={typeSeller === 1 ? 'cpf' : 'cnpj'}
        />
        <Input
          clearErrors={clearErrors}
          setError={setError}
          xs={12}
          md={6}
          placeholder="Nro nota fiscal"
          control={control}
          errors={errors?.numberNF}
          name="numberNF"
          title="Nro nota fiscal"
        />
        <Input
          clearErrors={clearErrors}
          setError={setError}
          xs={12}
          md={6}
          placeholder="Data da compra"
          control={control}
          type="date"
          maxDate={new Date().toISOString().slice(0, 10)}
          errors={errors?.dataNF}
          name="dataNF"
          title="Data da compra"
        />
        <Select
          xs={12}
          title="Finalidade"
          name="finalidade"
          type="string"
          options={data.finalidades ?? []}
          register={register}
          setValue={setValue}
          control={control}
          errors={errors?.finalidade}
          clearErrors={clearErrors}
          setError={setError}
        />
      </Grid>
    </Grid>
  )
}

export default DataSeller
