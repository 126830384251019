import Modal from '../Modal'
import { GoShare } from 'react-icons/go'

const ModalInstructionIOS = ({ isOpen, onClose }) => {
    return (
        <Modal
            title="Instruções para iOS"
            isOpen={isOpen}
            onClose={onClose}
            onCloseIcon={onClose}
            btn1={false}
        >
            <div>
                <div className="pb-4 text-sm">
                    <div className="flex items-center gap-1">
                        <p className="font-bold">1.</p>
                        <p>
                            Toque no botão <strong>Compartilhar</strong>
                        </p>
                        <GoShare className="text-xl text-secondary" />
                    </div>
                    <p>
                        <span className="font-bold">2.</span> Toque em{' '}
                        <strong>Adicionar à Tela de Início</strong>
                    </p>
                </div>
                <img src="./manualIOS.gif" alt="" />
            </div>
        </Modal>
    )
}

export default ModalInstructionIOS
