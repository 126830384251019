import { FaStar } from 'react-icons/fa'

import { api } from '../../utils/api'
import { useAuth } from '../../context/AuthContext'

const CardAvaliation = () => {
  const { setUser, user } = useAuth()

  const handleClick = async () => {
    window.open('https://g.page/r/Cf93hDctkDzuEBI/review', '_blank')
    const response = await fetch(`${api}home/avaliacao`, {
      method: 'POST',
      body: JSON.stringify({
        banco: user.clube.banco,
        usuarioID: user.pessoa.usuarioID,
        clubeID: user.clube.clubeID,
        avaliacao: 1,
      }),
    })

    const res = await response.json()
    const userFormat = {
      ...user,
      pessoa: {
        ...user.pessoa,
        avaliacao: '1',
      },
    }
    setUser(userFormat)
    localStorage.setItem('@sisarma-socio', JSON.stringify(userFormat))
  }

  return (
    user.pessoa.avaliacao === '0' && (
      <div
        onClick={handleClick}
        className="border border-slate-600/20 text-slate-600 p-5 cursor-pointer rounded-xl"
      >
        <div className="flex justify-between items-center gap-2">
          <div>
            <p className="text-base font-medium">Nos avalie</p>
            <p className="text-xs opacity-80">Sua opinião é muito importante</p>
          </div>
          <div className="flex gap-0">
            <FaStar size={20} className="text-yellow-400" />
            <FaStar size={20} className="text-yellow-400" />
            <FaStar size={20} className="text-yellow-400" />
            <FaStar size={20} className="text-yellow-400" />
            <FaStar size={20} className="text-yellow-400" />
          </div>
        </div>
      </div>
    )
  )
}

export default CardAvaliation
