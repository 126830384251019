export const perfilTypes = {
  perfil: {
    data: {
      nome: '',
      email: '',
      cr: '',
      crValidade: '',
      imagem: '',
      celular: '',
      diasVencimento: '',
      anexo: '',
      extensaoArquivo: '',
      nomeArquivo: '',
      pessoaAnexoID: '',
      formatoArquivo: '',
      descricao: '',
    },
    anexos: [
      {
        imagem: '',
        extensaoArquivo: '',
        name: '',
        pessoaAnexoID: '',
        formatoArquivo: '',
        descricao: '',
      },
    ],
  },
}
