import React, { useEffect, useState } from 'react'
import CardsInitial from '../components/inicio/CardsInitial'
import ModalPoliticasAccept from '../components/inicio/politicas/ModalPoliticasAccept'
import { useAuth } from '../context/AuthContext'
import AlertCR from '../components/AlertCR'
import CardAvaliation from '../components/inicio/CardAvaliation'
import { useGetData } from '../context/getDataContext'

const Inicio = () => {
  const [openModalPoliticas, setOpenModalPoliticas] = useState(false)
  const { logout, user, setUser, validateAccess } = useAuth()
  const {
    getDataInicio,
    data: { home: data },
  } = useGetData()

  const onClose = () => {
    setOpenModalPoliticas(false)
    logout()
  }

  //   Usuário aceitou os termos
  const acceptedPolicies = () => {
    setOpenModalPoliticas(false)
    const userFormat = {
      ...user,
      pessoa: {
        ...user.pessoa,
        politicaPrivacidade: '1',
        termosUso: '1',
      },
    }
    setUser(userFormat)
    localStorage.setItem('@sisarma-socio', JSON.stringify(userFormat))
  }

  useEffect(() => {
    document.title = 'SisarmaApp | Home'
    validateAccess()
    getDataInicio()
    if (user.pessoa.admin === '1') return
    if (
      user.pessoa.termosUso === '0' ||
      user.pessoa.politicaPrivacidade === '0'
    ) {
      setOpenModalPoliticas(true)
    }
  }, [user])

  return (
    data && (
      <section className="h-[calc(100vh-110px)]  md:h-[calc(100vh-90px)]">
        <div className="flex flex-col gap-2 h-full">
          {data.alertCR && <AlertCR data={data.alertCR} />}
          {data.finance && <CardsInitial value={data.finance} />}
          <CardAvaliation />
          {/* <Notification /> */}
        </div>
        <ModalPoliticasAccept
          isOpen={openModalPoliticas}
          onClose={onClose}
          acceptedPolicies={acceptedPolicies}
        />
      </section>
    )
  )
}

export default Inicio
