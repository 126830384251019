import { createContext, useContext, useEffect, useState } from 'react'
import { api } from '../utils/api'
import { AuthContext } from './AuthContext'

// Types
import { acervoPessoalTypes } from '../types/getData/acervoPessoal'
import { homeTypes } from '../types/getData/home'
import { perfilTypes } from '../types/getData/perfil'
import { treinamentoTypes } from '../types/getData/habitualidade/treinamento'
import { competicaoTypes } from '../types/getData/habitualidade/competicao'
import { historicoTypes } from '../types/getData/historico'
import { financeiroTypes } from '../types/getData/financeiro'

const initialValues = {
  acervoPessoal: acervoPessoalTypes,
  home: homeTypes,
  perfil: perfilTypes,
  'habitualidade/treinamento': treinamentoTypes,
  'habitualidade/competicao': competicaoTypes,
  historico: historicoTypes,
  financeiro: financeiroTypes,
}
const GetDataContext = createContext(initialValues)

const GetDataProvider = ({ children }) => {
  const { user, setUser } = useContext(AuthContext)
  const [data, setData] = useState(initialValues)
  const [count, setCount] = useState(false)

  const getData = async (dataKey) => {
    try {
      const response = await fetch(`${api}${dataKey}/getData`, {
        method: 'POST',
        body: JSON.stringify({
          banco: user.clube.banco,
          clubeID: user.clube.clubeID,
          pessoaID: user.pessoa.pessoaID,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const res = await response.json()
      setData((prevData) => ({
        ...prevData,
        [dataKey]: res,
      }))

      if (dataKey === 'perfil') {
        const formatData = {
          ...user,
          pessoa: {
            ...user.pessoa,
            foto: res?.data?.imagem,
          },
        }
        setUser(formatData)
        localStorage.setItem('@sisarma-socio', JSON.stringify(formatData))
      }
      if (dataKey === 'historico') {
        return res
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (user && user.clube && user.pessoa && !count) {
      getData('home')
      getData('acervoPessoal')
      getData('perfil')
      getData('habitualidade/treinamento')
      getData('habitualidade/competicao')
      getData('historico')
      getData('financeiro')
      setCount(true)
    }
  }, [user, count])

  const values = {
    data,
    getDataAcervoPessoal: () => getData('acervoPessoal'),
    getDataInicio: () => getData('home'),
    getDataPerfil: () => getData('perfil'),
    getDataTreinamento: () => getData('habitualidade/treinamento'),
    getDataCompeticao: () => getData('habitualidade/competicao'),
    getDataHistorico: () => getData('historico'),
    getDataFinanceiro: () => getData('financeiro'),
  }

  return (
    <GetDataContext.Provider value={values}>{children}</GetDataContext.Provider>
  )
}

const useGetData = () => {
  const context = useContext(GetDataContext)
  if (context === undefined) {
    throw new Error('useGetData must be used within a GetDataProvider')
  }
  return context
}

export { GetDataContext, GetDataProvider, useGetData }
