import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'

const Pagination = ({ data, setDataPage, tabData }) => {
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const rowPerPage = 10
  const pages = Math.ceil(tabData.length / rowPerPage)

  const handlePageChange = (value) => {
    setPage(value)
    const firstRow = rowPerPage * value
    const lastRow = firstRow + rowPerPage
    setDataPage(tabData.slice(firstRow, lastRow))
  }

  useEffect(() => {
    setPage(0)
    setDataPage(tabData.slice(0, rowPerPage))
  }, [data, setDataPage, tabData])

  const borderStyle =
    theme.palette.mode !== 'dark'
      ? '1px solid rgba(0, 0, 0, 0.23)'
      : '1px solid rgba(255, 255, 255, 0.23)'

  return (
    <div className="grid grid-cols-2 items-center my-2">
      <div>
        <p className="text-sm opacity-90">
          total de registros: {tabData.length}
        </p>
        <p className="text-sm opacity-90 block lg:hidden">
          {page + 1} de {pages}
        </p>
      </div>
      <div className="flex justify-end items-center gap-4">
        <div className="hidden lg:block">
          <p className="text-sm">
            Página {page + 1} de {pages}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <IconButton
            disabled={page === 0}
            onClick={() => handlePageChange(0)}
            aria-label="delete"
            sx={{
              border: borderStyle,
              borderRadius: '30%',
            }}
          >
            <MdOutlineKeyboardDoubleArrowLeft />
          </IconButton>
          <IconButton
            sx={{
              border: borderStyle,
              borderRadius: '30%',
            }}
            disabled={page === 0}
            onClick={() => handlePageChange(page - 1)}
          >
            <MdKeyboardArrowLeft />
          </IconButton>
          <IconButton
            sx={{
              border: borderStyle,
              borderRadius: '30%',
            }}
            disabled={page === pages - 1}
            onClick={() => handlePageChange(page + 1)}
          >
            <MdKeyboardArrowRight />
          </IconButton>
          <IconButton
            sx={{
              border: borderStyle,
              borderRadius: '30%',
            }}
            disabled={page === pages - 1}
            onClick={() => handlePageChange(pages - 1)}
          >
            <MdOutlineKeyboardDoubleArrowRight />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default Pagination
