import './styles/index.css'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import BlankLayout from './layout/BlankLayout'
import DefaultLayou from './layout/DefaultLayout'
import { LoadingProvider } from './context/LoadingContext'
import { AuthProvider } from './context/AuthContext'
import { HabitualidadeProvider } from './context/HabitualidadeContext'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import ModalSemAcesso from './components/ModalSemAcesso'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { FilterProvider } from './context/FilterContext'
import { CommonDataProvider } from './context/CommonDataContext'
import CustomToaster from './components/CustomToaster'
import { MensagemProvider } from './context/MensagemContext'
import { lightTheme, darkTheme } from './styles/themes'
import { GetDataProvider } from './context/getDataContext'

function Main() {
  const location = useLocation()
  const [currentTheme, setCurrentTheme] = useState(getInitialTheme())

  function getInitialTheme() {
    const savedTheme = localStorage.getItem('theme')
    return savedTheme ? savedTheme : 'light'
  }

  function setAndSaveTheme(theme) {
    localStorage.setItem('theme', theme)
    setCurrentTheme(theme)
  }

  return (
    <React.StrictMode>
      <AuthProvider>
        <LoadingProvider>
          <GetDataProvider>
            <CommonDataProvider>
              <FilterProvider>
                <ThemeProvider
                  theme={currentTheme === 'light' ? lightTheme : darkTheme}
                >
                  <CssBaseline />
                  <MensagemProvider>
                    <HabitualidadeProvider>
                      <CustomToaster />
                      {location.pathname === '/login' ? (
                        <BlankLayout>
                          <App />
                        </BlankLayout>
                      ) : (
                        <DefaultLayou
                          setCurrentTheme={setAndSaveTheme}
                          currentTheme={currentTheme}
                        >
                          <App />
                          <ModalSemAcesso />
                        </DefaultLayou>
                      )}
                    </HabitualidadeProvider>
                  </MensagemProvider>
                </ThemeProvider>
              </FilterProvider>
            </CommonDataProvider>
          </GetDataProvider>
        </LoadingProvider>
      </AuthProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <html lang="pt-br">
      <head>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LPVSWD1GH9"
        ></script>{' '}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());                  
            gtag('config', 'G-LPVSWD1GH9');
            `,
          }}
        ></script>
      </head>
      <Main />
    </html>
  </BrowserRouter>,
  document.getElementById('root')
)

reportWebVitals()
