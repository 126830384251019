import { Avatar } from '@mui/material'

const WeaponSelect = ({
  name,
  url,
  armaSelecionada,
  onClick,
  inative,
  numeroSerie,
}) => {
  return (
    <div className="flex flex-col justify-center items-center gap-1 pb-1 ">
      <div onClick={onClick} className="relative" alt="arma selecionada">
        <Avatar
          src={url ?? './semFoto.jpeg'}
          alt="arma"
          sx={{ width: 140, height: 140 }}
          className={`border-[4px] hover:border-primary hover:border-[4px] cursor-pointer ${
            armaSelecionada?.nome === name &&
            armaSelecionada?.numeroSerie === numeroSerie
              ? 'border-primary border-[4px]'
              : 'border-primary-dark/30 border-[4px]'
          }  ${inative ? 'opacity-20 ' : ''} `}
        />
        {inative && (
          <div className="absolute inset-0 flex justify-center items-center opacity-100 cursor-pointer">
            <h1 className="text-white bg-danger/60 rounded-md text-sm w-full text-center transform rotate-[-30deg]">
              Inativa
            </h1>
          </div>
        )}
      </div>
      <div
        className={`text-[10px] text-center ${
          armaSelecionada?.nome === name &&
          armaSelecionada?.numeroSerie === numeroSerie &&
          ' text-primary font-bold'
        }`}
      >
        <p>{name}</p>
        {numeroSerie && <p>(SN: {numeroSerie})</p>}
      </div>
    </div>
  )
}

export default WeaponSelect
