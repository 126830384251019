import React from 'react'
import Modal from '../../Modal'
import { Alert, Avatar } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { formatDate } from '../../../utils/functions'
import { useHabitualidadeContext } from '../../../context/HabitualidadeContext'

const ModalFinalRegistration = ({
  open,
  OnClose,
  reset,
  onSubmit,
  setKey,
  setArmaSelecionada,
  competionCreate,
  setCompetionCreate,
  setFiles,
  previews,
  setPreviews,
  armaPropria,
  armasPessoa,
}) => {
  const { competicao } = useHabitualidadeContext()
  const history = useHistory()

  // Cria uma nova competição copiando alguns dados já existentes
  const handleNewCompetition = () => {
    setCompetionCreate(false)
    if (!armaPropria) setArmaSelecionada(null)
    reset()
    OnClose()
    setFiles([])
    setPreviews([])
    reset({
      descricao: competicao.descricao,
      data: competicao.data,
      local: competicao.local,
      tipoEvento: competicao.tipoEvento,
      dataCadastro: competicao.dataCadastro,
      numeroControle: !armaPropria ? '' : armasPessoa[0].numeroControle,
    })
    setKey((prev) => !prev)
  }

  return (
    <Modal
      isOpen={open}
      bnt1Type="submit"
      isValid={false}
      onClose={() => {
        !competionCreate ? OnClose() : history.push('/historico')
      }}
      onCloseIcon={() => {
        !competionCreate ? OnClose() : history.push('/historico')
      }}
      handleSave={
        !competionCreate
          ? () => onSubmit(competicao)
          : () => handleNewCompetition()
      }
      title="Competição"
      size="sm"
      titleBnt1={!competionCreate ? 'Salvar' : 'Novo cadastro'}
      titleBnt2="Fechar"
    >
      {competionCreate ? (
        <Alert className="text-xl" color="success">
          Competição cadastrada com sucesso!
        </Alert>
      ) : (
        <Alert className="text-xl" color="warning">
          Verifique os dados para salvar. Os mesmos não poderão ser alterados!
        </Alert>
      )}
      {competicao && (
        <div className="mt-4 text-sm space-y-1">
          <div className="flex gap-1">
            <p className="opacity-80">Data da competição: </p>
            <p className="opacity-100 font-medium">
              {competicao.data_ === competicao.dataFim_
                ? competicao.data_
                : competicao.data_ + ' a ' + competicao.dataFim_}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Descrição: </p>
            <p className="opacity-100 font-medium">
              {competicao.descricao ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Tipo de evento: </p>
            <p className="opacity-100 font-medium">
              {competicao.tipoEvento.nome ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Local: </p>
            <p className="opacity-100 font-medium">
              {competicao.local ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Data: </p>
            <p className="opacity-100 font-medium">
              {formatDate(competicao.dataCadastro) ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Arma Própria: </p>
            <p className="opacity-100 font-medium">
              {competicao.armaPropria ? 'Sim' : 'Não' ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Arma selecionada: </p>
            <p className="opacity-100 font-medium">
              {competicao.armaSelecionada?.nome ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Nro de série: </p>
            <p className="opacity-100 font-medium">
              {competicao.armaSelecionada?.numeroSerie ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">
              {`Nro do ${
                !competicao.armaPropria
                  ? 'SIGMA'
                  : competicao.armaSelecionada?.tipo
              }: `}
            </p>
            <p className="opacity-100 font-medium">
              {!competicao.armaPropria
                ? competicao?.numeroControle?.length
                  ? competicao.numeroControle
                  : '--'
                : competicao.armaSelecionada?.numeroControle ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Modalidade: </p>
            <p className="opacity-100 font-medium">
              {competicao.modalidade.nome ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Divisão: </p>
            <p className="opacity-100 font-medium">
              {competicao.divisao ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Quantidade de munição: </p>
            <p className="opacity-100 font-medium">
              {competicao.QtdMunicao ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Livro: </p>
            <p className="opacity-100 font-medium">
              {competicao.livro ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Folha: </p>
            <p className="opacity-100 font-medium">
              {competicao.folha ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Colocação: </p>
            <p className="opacity-100 font-medium">
              {competicao.colocacao ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Pontos/Resultado: </p>
            <p className="opacity-100 font-medium">
              {competicao.pontos ?? '--'}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="opacity-80">Observação: </p>
            <p className="opacity-100 font-medium">
              {competicao.observacao ?? '--'}
            </p>
          </div>
          <div className="pt-4">
            <h1 className="text-xl font-medium pb-1">Fotos</h1>
            {previews.length === 0 && (
              <Alert color="warning">Não foram incluídas fotos</Alert>
            )}
            <div className="grid grid-cols-2 md:grid-cols-4 md:gap-2 gap-3">
              {previews.map((photo, index) => (
                <Avatar
                  variant="rounded"
                  alt={photo[index]?.name}
                  src={photo}
                  sx={{
                    width: 'auto',
                    height: '120px',
                    border: '1px solid #b1b1b1',
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ModalFinalRegistration
