import React from 'react'
import Modal from './Modal'
import { useHistory } from 'react-router-dom'
import { Alert } from '@mui/material'
import { useAuth } from '../context/AuthContext'

const ModalSemAcesso = () => {
  const history = useHistory()
  const { hasAccess, user } = useAuth()

  return (
    user.pessoa.usuarioID !== '1' && (
      <Modal
        isOpen={!hasAccess}
        onClose={() => history.push('/login')}
        onCloseIcon={() => history.push('/login')}
        title="Sem acesso"
        titleBnt2="Ok, entendi"
        btn1={false}
      >
        <Alert severity="warning">
          Oops... Parece que você não tem acesso a esta página. Por favor, entre
          em contato com o clube para solicitar o seu acesso.
        </Alert>
      </Modal>
    )
  )
}

export default ModalSemAcesso
