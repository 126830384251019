import React from 'react'
import { useAuth } from '../context/AuthContext'
import Select from './forms/Select'

const SelectClube = ({
  handleSubmit,
  control,
  setValue,
  clearErrors,
  setError,
}) => {
  const { dataLogin } = useAuth()

  return (
    <form onSubmit={handleSubmit}>
      <Select
        control={control}
        name="clube"
        title="Clube"
        required
        setValue={setValue}
        options={dataLogin.clube}
        clearErrors={clearErrors}
        setError={setError}
      />
    </form>
  )
}

export default SelectClube
