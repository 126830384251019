import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import { useForm } from 'react-hook-form'
import Select from '../forms/Select'
import Input from '../forms/Input'
import { Grid } from '@mui/material'
import { armType } from '../../types/habitualidade'
import WeaponSelect from '../WeaponSelect'
import { useAuth } from '../../context/AuthContext'
import { api } from '../../utils/api'
import toast from 'react-hot-toast'
import ModalConfirm from './ModalConfirm'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import { useLoading } from '../../context/LoadingContext'
import Anexos from './Anexos'
import DataSeller from './DataSeller'
import { useCommonData } from '../../context/CommonDataContext'

const ModalArm = ({
  open,
  setOpenModalNew,
  OnClose,
  data,
  armaSelecionadaAcervo,
  getData,
}) => {
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const { commonData } = useCommonData()
  const [armaSelecionadaModal, setArmaSelecionadaModal] = useState(armType)
  const [openModalChangeStatus, setOpenModalChangeStatus] = useState(false)
  // Escolher entre o sistema SIGMA ou SISARMA
  const [sistemaControle, setSistemaControle] = useState(1)
  //Tipo pessoa Juridica ou Fisica
  const [typeSeller, setTypeSeller] = useState(1)
  const [effectLoading, setEffectLoading] = useState(true)
  const [files, setFiles] = useState([])
  const [previews, setPreviews] = useState([])
  const [arrDelete, setArrDelete] = useState([])

  const {
    handleSubmit,
    control,
    register,
    setValue,
    clearErrors,
    trigger,
    setError,
    reset,
    formState: { errors },
  } = useForm()

  const onSelectArm = (value) => {
    if (!value) return
    setArmaSelecionadaModal(value)
  }

  // Salva anexos e fotos da arma
  const saveAnexosCreate = async (id, logAppID) => {
    let formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes('image') || files[i].type.includes('pdf')) {
        formData.append('files[]', files[i])
      }
    }
    formData.append('id', id)
    formData.append('logAppID', logAppID)
    formData.append('banco', user.clube.banco)
    formData.append('clubeID', user.clube.clubeID)
    formData.append('usuarioID', user.pessoa.usuarioID)

    try {
      const response = await fetch(`${api}acervoPessoal/saveAnexos`, {
        method: 'POST',
        body: formData,
      })
      const res = await response.json()
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  // Adicina uma nova arma
  const create = async (data) => {
    const formatData = {
      ...data,
      sistemaControle,
      typeSeller,
    }
    startLoading()
    try {
      const response = await fetch(`${api}acervoPessoal/create`, {
        method: 'POST',
        body: JSON.stringify({
          ...formatData,
          banco: user.clube.banco,
          pessoaID: user.pessoa.pessoaID,
          usuarioID: user.pessoa.usuarioID,
          clubeID: user.clube.clubeID,
        }),
      })
      const res = await response.json()
      if (res.id === 'duplicate') {
        return toast.error(
          'Arma com número de serie já cadastrada, altere o número de série.'
        )
      }
      let finished = false
      if (res.id && res.id > 0 && files.length > 0) {
        finished = await saveAnexosCreate(res.id, res.logAppID)
      } else {
        finished = true
      }
      if (finished) {
        OnClose()
        getData()
        toast.success('Arma adicionada com sucesso!')
      }
    } catch (error) {
      console.log(error)
      toast.error('Erro ao adicionar arma!', error)
    } finally {
      stopLoading()
    }
  }

  // Salva anexos e fotos da arma no update
  const saveAnexosUpdate = async (logAppID) => {
    let formData = new FormData()
    const filteredArray = files.filter(
      (obj) => !obj.hasOwnProperty('pessoaArmaAnexoID')
    )
    if (filteredArray.length === 0) return
    for (let i = 0; i < filteredArray.length; i++) {
      if (
        filteredArray[i].type.includes('image') ||
        filteredArray[i].type.includes('pdf')
      ) {
        formData.append('files[]', filteredArray[i])
      }
    }

    formData.append('id', armaSelecionadaAcervo.pessoaArmaID)
    formData.append('logAppID', 2)
    formData.append('banco', user.clube.banco)
    formData.append('clubeID', user.clube.clubeID)
    formData.append('usuarioID', user.pessoa.usuarioID)

    try {
      const response = await fetch(`${api}acervoPessoal/saveAnexos`, {
        method: 'POST',
        body: formData,
      })
      await response.json()
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  // atualiza a arma
  const update = async (data) => {
    const formatData = {
      ...data,
      sistemaControle,
      typeSeller,
      arrDelete,
    }
    startLoading()
    try {
      const response = await fetch(`${api}acervoPessoal/update`, {
        method: 'POST',
        body: JSON.stringify({
          ...formatData,
          id: armaSelecionadaAcervo.pessoaArmaID,
          banco: user.clube.banco,
          clubeID: user.clube.clubeID,
          usuarioID: user.pessoa.usuarioID,
          pessoaID: user.pessoa.pessoaID,
        }),
      })
      const res = await response.json()
      await saveAnexosUpdate(res)
      if (res === 'duplicate') {
        return toast.error(
          'Arma com número de serie já cadastrada, altere o número de série.'
        )
      }
      toast.success('Arma Editada com sucesso!')
      getData()
      OnClose()
      setArmaSelecionadaModal(null)
    } catch (error) {
      console.log(error)
      toast.error('Erro ao Editar arma!')
    } finally {
      stopLoading()
    }
  }

  const handleSave = (data) => {
    if (armaSelecionadaAcervo?.nome?.length) {
      update(data)
    } else {
      create(data)
    }
  }

  const OnCloseClick = () => {
    if (armaSelecionadaAcervo?.nome?.length) {
      setOpenModalChangeStatus(true)
      setEffectLoading(false)
    } else {
      OnClose()
      setArmaSelecionadaModal(null)
      reset()
    }
  }

  const onCloseIcon = () => {
    setArmaSelecionadaModal(null)
    OnClose()
  }

  useEffect(() => {
    setArmaSelecionadaModal(armaSelecionadaAcervo)
    if (armaSelecionadaAcervo?.nome?.length) {
      reset({
        arma: {
          nome: armaSelecionadaAcervo?.nome,
          id: armaSelecionadaAcervo?.id,
        },
        numeroSerie: armaSelecionadaAcervo.numeroSerie,
        dataVencimento: armaSelecionadaAcervo.dataVencimento,
        dataEmissao: armaSelecionadaAcervo?.dataEmissao,
        numeroControle: armaSelecionadaAcervo?.numeroControle,
        ufOrgaoID: {
          id: Number(armaSelecionadaAcervo.ufID),
          nome: armaSelecionadaAcervo.ufNome,
        },
        finalidade: armaSelecionadaAcervo.finalidadeID
          ? {
              id: Number(armaSelecionadaAcervo.finalidadeID),
              nome: armaSelecionadaAcervo.finalidadeNome,
            }
          : null,
        numberSeller: armaSelecionadaAcervo?.numeroVendedor,
        numberNF: armaSelecionadaAcervo?.numeroNotaFiscal,
        dataNF: armaSelecionadaAcervo?.dataCompra,
        nameSeller: armaSelecionadaAcervo?.nomeVendedor,
      })
      setSistemaControle(Number(armaSelecionadaAcervo.sistemaControle))
      setTypeSeller(Number(armaSelecionadaAcervo.tipoVendedor))
      setPreviews(armaSelecionadaAcervo?.anexos)
      setFiles(armaSelecionadaAcervo?.anexos)
    }
    trigger()
  }, [armaSelecionadaAcervo, reset])

  return (
    <>
      {/* Modal de confirmação de inativação */}
      <ModalConfirm
        isOpen={openModalChangeStatus}
        setIsOpen={setOpenModalChangeStatus}
        armaSelecionadaAcervo={armaSelecionadaAcervo}
        setOpenModalChangeStatus={setOpenModalChangeStatus}
        getData={getData}
        onClose={() => {
          OnClose()
          setArmaSelecionadaModal(null)
          setOpenModalChangeStatus(false)
          setOpenModalNew(false)
          reset()
        }}
      />
      <Modal
        isOpen={open}
        setIsOpen={setOpenModalNew}
        onClose={OnCloseClick}
        onCloseIcon={onCloseIcon}
        handleSave={handleSubmit(handleSave)}
        effectLoading={effectLoading}
        title={
          armaSelecionadaAcervo?.nome?.length ? 'Editar arma' : 'Adicionar arma'
        }
        zIndex="z-20"
        size="md"
        titleBnt1="Salvar"
        disabledBnt1={!armaSelecionadaModal?.nome?.length}
        titleBnt2={
          armaSelecionadaAcervo?.nome?.length
            ? armaSelecionadaAcervo.status == 1
              ? armaSelecionadaAcervo.pendencia
                ? 'Inativar' // Se armaSelecionadaAcervo.status == 1 e armaSelecionadaAcervo.pendencia é verdadeiro
                : 'Excluir' // Se armaSelecionadaAcervo.status == 1 e armaSelecionadaAcervo.pendencia é falso
              : 'Ativar' // Se armaSelecionadaAcervo.status != 1
            : 'Fechar' // Se armaSelecionadaAcervo.nome.length não for verdadeiro
        }
        colorBtn2={
          armaSelecionadaAcervo?.nome?.length
            ? armaSelecionadaAcervo.status == 1
              ? 'danger'
              : 'secondary'
            : 'default'
        }
      >
        <form onSubmit={handleSubmit(create)} className="pt-2">
          <Grid container spacing={2}>
            {armaSelecionadaModal && armaSelecionadaModal.imagem && (
              <div className="w-full flex justify-center">
                <WeaponSelect
                  armaSelecionada={armaSelecionadaModal}
                  name={armaSelecionadaModal.nome}
                  url={armaSelecionadaModal.imagem}
                />
              </div>
            )}
            <Select
              xs={12}
              md={6}
              title="Arma"
              name="arma"
              type="string"
              options={commonData.armsAll ?? []}
              disabled={armaSelecionadaAcervo?.nome?.length ? true : false}
              register={register}
              setValue={setValue}
              onChange={onSelectArm}
              required
              control={control}
              errors={errors?.arma}
              clearErrors={clearErrors}
              setError={setError}
            />
            <Input
              clearErrors={clearErrors}
              setError={setError}
              xs={12}
              md={6}
              placeholder="Número de Série"
              control={control}
              errors={errors?.numeroSerie}
              require
              name="numeroSerie"
              title="Número de Série"
            />
            <Select
              xs={12}
              md={6}
              title="UF Orgão"
              name="ufOrgaoID"
              type="string"
              options={data.ufs ?? []}
              register={register}
              required
              setValue={setValue}
              control={control}
              errors={errors?.ufOrgaoID}
              clearErrors={clearErrors}
              setError={setError}
            />
            <Input
              clearErrors={clearErrors}
              setError={setError}
              xs={12}
              md={6}
              placeholder="Validade do Registro"
              control={control}
              errors={errors?.dataVencimento}
              required
              type="date"
              name="dataVencimento"
              title="Validade do Registro"
              className="pb-3"
            />
            <Grid item xs={12} className="relative">
              <label className="absolute text-sm -top-1 opacity-70 left-6 z-50 px-2 bg-transparent">
                Sistema
              </label>
              <ButtonGroup
                className="z-10"
                fullWidth
                disableElevation
                color="primary"
              >
                <Button
                  variant={`${
                    sistemaControle === 1 ? 'contained' : 'outlined'
                  }`}
                  onClick={() => setSistemaControle(1)}
                >
                  SIGMA
                </Button>
                <Button
                  variant={`${
                    sistemaControle === 2 ? 'contained' : 'outlined'
                  }`}
                  onClick={() => setSistemaControle(2)}
                >
                  SINARM
                </Button>
              </ButtonGroup>
            </Grid>
            <Input
              clearErrors={clearErrors}
              setError={setError}
              xs={12}
              md={6}
              placeholder="Número do Registro"
              control={control}
              errors={errors?.numeroControle}
              required
              type="number"
              name="numeroControle"
              title="Número do Registro"
            />
            <Input
              clearErrors={clearErrors}
              setError={setError}
              xs={12}
              md={6}
              placeholder="Data de Emissão"
              control={control}
              errors={errors?.dataEmissao}
              required
              type="date"
              name="dataEmissao"
              title="Data de Emissão"
            />
            {/* Dados vendedor */}
            <DataSeller
              typeSeller={typeSeller}
              setTypeSeller={setTypeSeller}
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              data={data}
              clearErrors={clearErrors}
              setError={setError}
            />
            {/* Anexos */}
            <Anexos
              files={files}
              setFiles={setFiles}
              previews={previews}
              setPreviews={setPreviews}
              setArrDelete={setArrDelete}
              arrDelete={arrDelete}
            />
          </Grid>
        </form>
      </Modal>
    </>
  )
}

export default ModalArm
