import { twMerge } from 'tailwind-merge'

const Etiqueta = ({ name, className }) => {
    return (
        <div
            className={twMerge(
                `absolute top-2 -right-4 pb-1 rotate-45 text-xs opacity-90 text-white ${className} `
            )}
        >
            <h1>{name}</h1>
        </div>
    )
}

export default Etiqueta
