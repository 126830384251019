import { Card, CardContent } from '@mui/material'
import { FaRankingStar } from 'react-icons/fa6'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { BiTargetLock } from 'react-icons/bi'
import { FaRegClock } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import Competion from '../components/habitualidade/Competion'
import Treinament from '../components/habitualidade/Treinament'
import IconBack from '../components/IconBack'
import { useAuth } from '../context/AuthContext'

const Habitualidade = () => {
  const [type, setType] = useState('')
  const history = useHistory()
  const { validateAccess } = useAuth()

  const handleType = (value) => {
    setType(value)
  }

  useEffect(() => {
    validateAccess()
  }, [])

  useEffect(() => {
    const title = 'SisarmaApp | Habitualidade '
    if (type) {
      const subTitle = type === 'treinamento' ? '| Treinamento' : '| Competição'
      document.title = `${title} ${subTitle}`
    } else {
      document.title = title
    }
  }, [type])

  return (
    <section>
      <Card className="relative">
        <div
          className="absolute top-6 right-5 text-xl cursor-pointer"
          onClick={() => history.push('/')}
        >
          <IconBack />
        </div>

        <div className="p-5">
          <div className="flex items-center gap-1">
            <FaRegClock />
            <h1 className="text-lg font-semibold">Habitualidade</h1>
          </div>
        </div>
        <CardContent>
          <div className="flex justify-center items-center gap-4">
            <div
              onClick={() => handleType('treinamento')}
              className={twMerge(
                ` bg-primary opacity-90 hover:opacity-100 transition-colors p-4 text-white flex flex-col items-center justify-center gap-1 w-full cursor-pointer rounded-lg ${
                  type === 'treinamento' ? 'bg-primary-focus' : ''
                }`
              )}
            >
              <div className="text-6xl">
                <BiTargetLock />
              </div>
              <p className="text-white">Treinamento</p>
            </div>
            <div
              onClick={() => handleType('competicao')}
              className={twMerge(
                `bg-primary opacity-90 hover:opacity-100  transition-colors p-4 text-white flex flex-col items-center justify-center gap-1 w-full cursor-pointer rounded-lg ${
                  type === 'competicao' ? 'bg-primary-focus' : ''
                }`
              )}
            >
              <div className="text-6xl">
                <FaRankingStar />
              </div>
              <p className="text-white">Competição</p>
            </div>
          </div>
        </CardContent>
      </Card>
      {type && type === 'treinamento' ? (
        <Treinament />
      ) : type === 'competicao' ? (
        <Competion setType={setType} />
      ) : null}
    </section>
  )
}

export default Habitualidade
