import React from 'react'
import { Toaster } from 'react-hot-toast'
import { useTheme } from '@mui/material/styles'

const CustomToaster = () => {
  const theme = useTheme()
  return (
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      toastOptions={{
        style:
          theme.palette.mode === 'dark'
            ? { backgroundColor: '#18181B', color: 'white' }
            : { backgroundColor: 'white', color: 'black' },
      }}
    />
  )
}

export default CustomToaster
