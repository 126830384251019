import { Alert, Card, CardContent, CardHeader, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { api } from '../../../utils/api'
import Select from '../../forms/Select'
import SwitchLabel from '../../forms/SwitchLabel'
import Button from '../../forms/Button'
import Input from '../../forms/Input'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import WeaponSelect from '../../WeaponSelect'
import { selectedType } from '../../../types/habitualidade'
import ModalFinalRegistration from './ModalFinalRegistration'
import UploadPhotos from './UploadPhotos'
import { useLoading } from '../../../context/LoadingContext'
import { useHabitualidadeContext } from '../../../context/HabitualidadeContext'
import { useGetData } from '../../../context/getDataContext'
import { convertHeicToJpg } from '../../../utils/functions'

export const initialState = {
  armasAll: [selectedType],
  armasPessoa: [selectedType],
}

const AlertVerifyCurrent = ({ data }) => {
  const history = useHistory()
  const handleClick = () => {
    history.push(`/historico?date=${data}`)
  }
  return (
    <Grid item xs={12}>
      <Alert severity="info" sx={{ mb: 2 }}>
        <div className="flex flex-col md:flex-row items-center gap-2">
          <p>
            Você já possui treinamento(s) para essa data e arma.{'  '}
            <span
              className="cursor-pointer underline text-blue-400 opacity-90 hover:opacity-100 transition-all"
              onClick={handleClick}
            >
              Ver treinamentos
            </span>
          </p>
        </div>
      </Alert>
    </Grid>
  )
}

const Treinament = () => {
  const { user } = useAuth()
  const {
    getDataTreinamento,
    data: { 'habitualidade/treinamento': data },
  } = useGetData()
  const { setTreinamento } = useHabitualidadeContext()
  const history = useHistory()
  const { startLoading, stopLoading } = useLoading()
  const [armaPropria, setArmaPropria] = useState(true)
  const [armaSelecionada, setArmaSelecionada] = useState(selectedType)
  const [modalFinalRegistration, setModalFinalRegistration] = useState(false)
  const [treinamentCreate, setTreinamentCreate] = useState(false)
  const [files, setFiles] = useState([])
  const [previews, setPreviews] = useState([])
  const [verifyLauchData, setVerifyLauchData] = useState(0)
  const [key, setKey] = useState(false)

  const {
    handleSubmit,
    control,
    register,
    reset,
    getValues,
    trigger,
    watch,
    setValue,
    clearErrors,
    setError,
    formState: { errors, isValid },
  } = useForm()

  const handleSwitchChange = (newValue) => {
    watch('arma')
    setArmaPropria(newValue)
    setValue('seletedArm', null)
    if (!newValue) {
      setValue('numeroControle', '')
      setError('arma', {
        type: 'required',
        message: 'custom message',
      })
      setArmaSelecionada(null)
    } else {
      setValue('numeroControle', data.armasPessoa[0].numeroControle)
      setArmaSelecionada(data.armasPessoa[0])
      clearErrors('arma')
    }
  }

  const onSelectArm = (value) => {
    const arm = data.armasAll.find((a) => a.nome === value.nome)
    if (arm) {
      setArmaSelecionada(arm)
    } else {
      setArmaSelecionada(null)
      setValue('seletedArm', null)
    }
  }

  useEffect(() => {
    if (!getValues('seletedArm')) {
      setArmaSelecionada(null)
      setError('arma', {
        type: 'required',
        message: 'custom message',
      })
    } else {
      clearErrors('arma')
    }
  }, [getValues('seletedArm')])

  const handleClickConfirm = (value) => {
    delete value.seletedArm
    const formatValues = {
      ...value,
      armaSelecionada,
      clubeID: user.clube.clubeID,
      banco: user.clube.banco,
    }
    setTreinamento(formatValues)
    setModalFinalRegistration(true)
    setTreinamentCreate(false)
  }

  const savePhotos = async (id, logAppID) => {
    let formData = new FormData()

    for (let i = 0; i < files.length; i++) {
      if (
        files[i].type.includes('image') ||
        /\.(HEIC|HEIF)$/i.test(files[i].name)
      ) {
        if (/\.(HEIC|HEIF)$/i.test(files[i].name)) {
          try {
            const jpgBlob = await convertHeicToJpg(files[i])
            formData.append('files[]', jpgBlob, `${files[i].name}.jpg`)
          } catch (error) {
            console.error('Erro ao converter HEIC para JPG:', error)
            return false
          }
        } else {
          formData.append('files[]', files[i])
        }
      }
    }

    formData.append('id', id)
    formData.append('logAppID', logAppID)
    formData.append('banco', user.clube.banco)
    formData.append('clubeID', user.clube.clubeID)
    formData.append('usuarioID', user.pessoa.usuarioID)

    try {
      const response = await fetch(
        `${api}habitualidade/treinamento/savePhotos`,
        {
          method: 'POST',
          body: formData,
        }
      )
      const responseText = await response.text()
      console.log(responseText)

      try {
        const res = JSON.parse(responseText)
        return true
      } catch (error) {
        console.error('Erro ao analisar JSON:', error)
        return false
      }
    } catch (error) {
      console.error('Erro na solicitação de rede:', error)
      return false
    }
  }

  const create = async (data) => {
    startLoading()
    try {
      const response = await fetch(`${api}habitualidade/treinamento/create`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          banco: user.clube.banco,
          usuarioID: user.pessoa.usuarioID,
          pessoaID: user.pessoa.pessoaID,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        const errorText = await response.text()
        console.error('Erro na resposta da API:', errorText)
        toast.error('Erro ao criar treinamento. Por favor, tente novamente.')
        return
      }

      const responseText = await response.text()
      console.log(responseText)

      try {
        const res = JSON.parse(responseText)
        if (!res.status && res.message === 'duplicate') {
          toast.error('Treinamento com data, hora e arma já cadastrado!')
          return
        }
        const id = res.id
        const logAppID = res.logAppID
        let finished = false
        if (id && id > 0 && files.length > 0) {
          finished = await savePhotos(id, logAppID)
        } else {
          finished = true
        }
        if (finished) {
          setTreinamentCreate(true)
          toast.success('Treinamento criado com sucesso!')
        }
      } catch (error) {
        console.error('Erro ao analisar JSON:', error)
      }
    } catch (error) {
      console.error('Erro ao criar treinamento:', error)
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    trigger()
  }, [key])

  const getDataverifyLaunchData = async () => {
    setKey(!key)
    if (!armaSelecionada) {
      setVerifyLauchData(0)
    } else {
      const response = await fetch(
        `${api}habitualidade/treinamento/verifyLaunchData`,
        {
          method: 'POST',
          body: JSON.stringify({
            banco: user.clube.banco,
            clubeID: user.clube.clubeID,
            pessoaID: user.pessoa.pessoaID,
            data: getValues('data'),
            armaID: armaSelecionada?.id,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (!response.ok) {
        const errorText = await response.text()
        console.error('Erro na resposta da API:', errorText)
        return
      }

      const responseText = await response.text()
      console.log(responseText)

      try {
        const res = JSON.parse(responseText)
        setVerifyLauchData(res)
      } catch (error) {
        console.error('Erro ao analisar JSON:', error)
      }
    }
  }

  useEffect(() => {
    getDataverifyLaunchData()
  }, [armaSelecionada])

  useEffect(() => {
    getDataTreinamento()
    getDataverifyLaunchData(new Date().toISOString().slice(0, 10))
    reset({
      data: new Date().toISOString().slice(0, 10),
    })
  }, [reset])

  useEffect(() => {
    if (data && data.armasPessoa?.length > 0) {
      setArmaSelecionada(data.armasPessoa[0])
    }
  }, [data, data.armasPessoa])

  useEffect(() => {
    if (!armaPropria) {
      setValue('numeroControle', '')
    } else {
      setValue('numeroControle', armaSelecionada?.numeroControle)
    }
  }, [armaPropria, armaSelecionada, setValue])

  console.log('hetvbalues', getValues())

  return (
    <>
      <ModalFinalRegistration
        setPreviews={setPreviews}
        previews={previews}
        setFiles={setFiles}
        getDataverifyLaunchData={getDataverifyLaunchData}
        getValues={getValues}
        treinamentCreate={treinamentCreate}
        setTreinamentCreate={setTreinamentCreate}
        reset={reset}
        onSubmit={create}
        setArmaSelecionada={setArmaSelecionada}
        open={modalFinalRegistration}
        OnClose={() => {
          setModalFinalRegistration(false)
        }}
        armaPropria={armaPropria}
        armasPessoa={data.armasPessoa}
      />
      <form onSubmit={handleSubmit(handleClickConfirm)} className="pt-2">
        <Card>
          <CardHeader title="Treinamento" />
          <CardContent>
            <Grid container spacing={2}>
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={12}
                md={6}
                placeholder="Data"
                control={control}
                errors={errors?.data}
                allowPastDates={true}
                key={key}
                onChange={(e) => {
                  getDataverifyLaunchData()
                  setValue('data', e)
                }}
                required
                type="date"
                name="data"
                title="Data"
                maxDate={new Date().toISOString().slice(0, 10)}
              />
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={6}
                md={3}
                placeholder="Hora Inicio"
                control={control}
                errors={errors?.horaInicio}
                required
                type="time"
                name="horaInicio"
                title="Hora Inicio"
              />
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={6}
                md={3}
                placeholder="Hora Fim"
                control={control}
                errors={errors?.horaFim}
                type="time"
                name="horaFim"
                title="Hora Fim"
              />
              <SwitchLabel
                xs={12}
                name="armaPropria"
                title="Arma própria"
                value={armaPropria}
                register={register}
                onChange={handleSwitchChange}
              />
              <Grid item xs={12}>
                {data && armaPropria ? (
                  <div
                    className={`flex gap-4 ${
                      data.armasPessoa.length < 3 || !armaPropria
                        ? ' justify-center'
                        : ''
                    } overflow-x-auto ${
                      data.armasPessoa.length > 0 ? ' cursor-pointer' : ''
                    }`}
                    style={
                      data.armasPessoa.length > 2
                        ? {
                            overflowX: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            whiteSpace: 'nowrap',
                            width: '100%',
                          }
                        : {}
                    }
                  >
                    {data.armasPessoa.length === 0 && (
                      <Alert className="w-full" severity="error">
                        Nenhuma arma cadastrada em seu acervo.{' '}
                        <Link
                          to="/acervo-pessoal"
                          className="cursor-pointer font-bold underline opacity-80 hover:opacity-100 transition-colors"
                        >
                          Cadastrar arma
                        </Link>
                      </Alert>
                    )}
                    {data.armasPessoa.map((arma) => {
                      return (
                        <div
                          key={arma.nome}
                          onClick={() => setArmaSelecionada(arma)}
                        >
                          <WeaponSelect
                            armaSelecionada={armaSelecionada}
                            name={arma.nome}
                            url={arma.imagem}
                            numeroSerie={arma.numeroSerie}
                          />
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div>
                    <Select
                      md={12}
                      title="Arma"
                      name="seletedArm"
                      required
                      type="string"
                      options={data.armasAll ?? []}
                      register={register}
                      setValue={setValue}
                      onChange={onSelectArm}
                      control={control}
                      errors={errors?.arma}
                      clearErrors={clearErrors}
                      setError={setError}
                    />
                    {/* <Select
                    xs={12}
                    md={6}
                    title="Modalidade"
                    name="modalidade"
                    type="string"
                    options={data?.modalidades ?? []}
                    register={register}
                    setValue={setValue}
                    control={control}
                    required
                    errors={errors?.modalidade}
                    clearErrors={clearErrors}
                    setError={setError}
                  /> */}
                    {armaSelecionada && (
                      <WeaponSelect
                        armaSelecionada={armaSelecionada}
                        name={armaSelecionada.nome}
                        url={armaSelecionada.imagem}
                      />
                    )}
                  </div>
                )}
              </Grid>
              {verifyLauchData > 0 && armaSelecionada && (
                <AlertVerifyCurrent data={getValues('data')} />
              )}
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={12}
                md={4}
                placeholder={
                  armaPropria && armaSelecionada && armaSelecionada.id
                    ? armaSelecionada?.sistemaControle === '1'
                      ? 'Número SIGMA'
                      : 'Número SINARM'
                    : 'Número de SIGMA'
                }
                control={control}
                disabled={armaPropria}
                errors={errors?.numeroControle}
                type="number"
                name="numeroControle"
                title={
                  armaPropria && armaSelecionada && armaSelecionada.id
                    ? armaSelecionada?.sistemaControle === '1'
                      ? 'Número SIGMA'
                      : 'Número SINARM'
                    : 'Número de SIGMA'
                }
              />
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={12}
                md={4}
                placeholder="Qtd de disparos"
                control={control}
                errors={errors?.QtdMunicao}
                required
                type="number"
                name="QtdMunicao"
                title="Qtd de disparos"
              />
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={12}
                md={4}
                placeholder="Livro"
                required
                control={control}
                errors={errors?.livro}
                name="livro"
                title="Livro"
              />
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={12}
                md={4}
                placeholder="Folha"
                required
                control={control}
                errors={errors?.folha}
                name="folha"
                title="Folha"
              />
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={12}
                md={8}
                placeholder="Pontos"
                control={control}
                errors={errors?.pontos}
                type="number"
                name="pontos"
                title="Pontos"
              />
              <Input
                clearErrors={clearErrors}
                setError={setError}
                xs={12}
                placeholder="Observação"
                control={control}
                errors={errors?.observacao}
                name="observacao"
                title="Observação"
              />
            </Grid>
            <Grid item xs={12} className="pt-4">
              <UploadPhotos
                files={files}
                setFiles={setFiles}
                previews={previews}
                setPreviews={setPreviews}
              />
            </Grid>
            <Grid item xs={12} className="pt-4">
              <div className="flex justify-between gap-4">
                <Button
                  color="primary"
                  name="Salvar"
                  disabled={!isValid}
                  type="submit"
                />
                <Button
                  color="default"
                  name="Voltar"
                  onClick={() => history.push('/')}
                />
              </div>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  )
}

export default Treinament
