export const resumoType = {
    total: '',
    totalPago: '',
}

export const pagamentoType = {
    data: '',
    formaPagamento: '',
    operacao: '',
    valor: '',
}

export const detalhadoType = [
    {
        data: '',
        financeiroID: '',
        origem: '',
        pagamentos: [],
        situacao: '',
        valor: '',
    },
]
