import { useAuth } from '../context/AuthContext'
import { cpfMask } from '../utils/mask'
import { MdOutlineLogout } from 'react-icons/md'
import { LuFileClock } from 'react-icons/lu'
import { Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { FaRegStar, FaRegUser } from 'react-icons/fa'
import { IoMoonOutline } from 'react-icons/io5'
import { LuSunDim } from 'react-icons/lu'
import { useState } from 'react'

const NavBar = ({ setCurrentTheme, currentTheme }) => {
  const { logout, user } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
  }

  const toggleTheme = () => {
    setCurrentTheme(currentTheme === 'light' ? 'dark' : 'light')
  }

  return (
    <nav className="bg-primary-dark flex items-center h-12">
      <div className="flex justify-between items-center p-1 w-full md:w-[60%] mx-auto px-2">
        <div></div>
        <div className="flex items-center gap-4">
          <div className="text-white text-[10px] cursor-pointer">
            <a href="/perfil">
              <p>{`${user.pessoa.nome} -  ${cpfMask(user.pessoa.cpf)}`}</p>
            </a>
          </div>
          <div>
            <Avatar
              className="cursor-pointer"
              src={user?.pessoa?.foto ?? './semFoto.jpeg'}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <a href="/perfil">
                <MenuItem>
                  <ListItemIcon>
                    <FaRegUser />
                  </ListItemIcon>
                  Perfil
                </MenuItem>
              </a>
              <MenuItem
                onClick={() => {
                  toggleTheme()
                  handleClose()
                }}
              >
                <ListItemIcon>
                  {currentTheme === 'light' ? (
                    <IoMoonOutline />
                  ) : (
                    <LuSunDim size={19} />
                  )}
                </ListItemIcon>
                <button>
                  {currentTheme === 'light' ? 'Tema Escuro' : 'Tema Claro'}
                </button>
              </MenuItem>
              {/* {(user.pessoa.avaliacao === '1' || user.pessoa.admin === '1') && ( */}
              <a
                href="https://g.page/r/Cf93hDctkDzuEBI/review"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  handleClose()
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <FaRegStar />
                  </ListItemIcon>
                  Nos Avalie
                </MenuItem>
              </a>
              {/* )} */}
              {user && user.pessoa.admin === '1' && (
                <a href="/log">
                  <MenuItem>
                    <ListItemIcon>
                      <LuFileClock />
                    </ListItemIcon>
                    Log
                  </MenuItem>
                </a>
              )}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <MdOutlineLogout />
                </ListItemIcon>
                Sair
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
