import React from 'react'
import Modal from '../../Modal'
import { Alert, Avatar } from '@mui/material'
import { format, isValid, addDays } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { useHistory } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useHabitualidadeContext } from '../../../context/HabitualidadeContext'

const ModalFinalRegistration = ({
  open,
  OnClose,
  reset,
  onSubmit,
  setArmaSelecionada,
  treinamentCreate,
  setTreinamentCreate,
  getDataverifyLaunchData,
  setFiles,
  previews,
  setPreviews,
  armaPropria,
  armasPessoa,
}) => {
  const { treinamento } = useHabitualidadeContext()
  const history = useHistory()

  const formattedDate = isValid(new Date(treinamento.data))
    ? format(addDays(new Date(treinamento.data), 1), 'dd/MM/yyyy', {
        locale: ptBR,
      })
    : 'Data inválida'

  const handleNewCompetition = () => {
    getDataverifyLaunchData()
    reset()
    if (!armaPropria) setArmaSelecionada(null)
    OnClose()
    setFiles([])
    setPreviews([])
    reset({
      data: treinamento.data,
      horaInicio: treinamento.horaInicio,
      horaFim: treinamento.horaFim,
      numeroControle: !armaPropria ? '' : armasPessoa[0].numeroControle,
    })
    setTreinamentCreate(false)
  }

  return (
    <Modal
      bnt1Type="submit"
      isValid={false}
      isOpen={open}
      onClose={() => {
        !treinamentCreate ? OnClose() : history.push('/historico')
      }}
      onCloseIcon={() => {
        !treinamentCreate ? OnClose() : history.push('/historico')
      }}
      handleSave={
        !treinamentCreate
          ? () => onSubmit(treinamento)
          : () => handleNewCompetition()
      }
      title="Treinamento"
      size="sm"
      titleBnt1={!treinamentCreate ? 'Salvar' : 'Novo cadastro'}
      titleBnt2="Fechar"
    >
      {treinamentCreate ? (
        <Alert className="text-xl" color="success">
          Treinamento cadastrado com sucesso!
        </Alert>
      ) : (
        <Alert className="text-xl" color="warning">
          Verifique os dados para salvar. Os mesmos não poderão ser alterados!
        </Alert>
      )}
      {treinamento && (
        <Card>
          <CardContent>
            <div className="mt-4 text-sm space-y-1">
              <div className="flex gap-1">
                <p className="opacity-80">Data: </p>
                <p className="opacity-100 font-medium">
                  {' '}
                  {formattedDate ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Hora Inicio: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.horaInicio ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Hora Fim: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.horaFim ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Arma Própria: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.armaPropria ? 'Sim' : 'Não' ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Arma selecionada: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.armaSelecionada?.nome ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Nro de série: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.armaSelecionada?.numeroSerie ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">
                  {`Nro do ${
                    !treinamento.armaPropria
                      ? 'SIGMA'
                      : treinamento.armaSelecionada?.tipo
                  }: `}
                </p>
                <p className="opacity-100 font-medium">
                  {!treinamento.armaPropria
                    ? treinamento?.numeroControle?.length
                      ? treinamento.numeroControle
                      : '--'
                    : treinamento.armaSelecionada?.numeroControle ?? '--'}
                </p>
              </div>

              <div className="flex gap-1">
                <p className="opacity-80">Quantidade de munição: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.QtdMunicao ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Livro: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.livro ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Folha: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.folha ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Pontos/Resultado: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.pontos ?? '--'}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="opacity-80">Observação: </p>
                <p className="opacity-100 font-medium">
                  {treinamento.observacao ?? '--'}
                </p>
              </div>
              <div className="pt-4">
                <h1 className="text-xl font-medium pb-1">Fotos</h1>
                {previews.length === 0 && (
                  <Alert color="warning">Não foram incluídas fotos</Alert>
                )}
                <div className="grid grid-cols-2 md:grid-cols-4 md:gap-2 gap-3">
                  {previews.map((photo, index) => (
                    <Avatar
                      variant="rounded"
                      alt={photo[index]?.name}
                      src={photo}
                      sx={{
                        width: 'auto',
                        height: '120px',
                        border: '1px solid #b1b1b1',
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </Modal>
  )
}

export default ModalFinalRegistration
