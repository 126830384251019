import { Link } from '@mui/material'
import React from 'react'
import { useAuth } from '../../../../context/AuthContext'

const TermosUso = () => {
  const { user } = useAuth()

  return (
    <main>
      <h1 className="title">Termos e condições gerais de uso do SISARMA.</h1>
      <section>
        <div className="content">
          <p className="text">
            Os serviços do SISARMA são fornecidos pela pessoa jurídica com a
            seguinte Razão Social/nome: PRECISA GESTÃO EM TECNOLOGIA E SERVICOS
            LTDA, com nome fantasia PRECISA GESTÃO EM TECNOLOGIA E SERVICOS
            LTDA, inscrito no CNPJ/CPF sob o nº 10.954.970/0001-80, titular da
            propriedade intelectual sobre software, website, aplicativos,
            conteúdos e demais ativos relacionados à sistema SISARMA.
          </p>
        </div>
        <div className="content">
          <h2 className="sub-title">1. Do objeto</h2>
          <p className="text">
            O sistema visa licenciar o uso de seu software, website, aplicativos
            e demais ativos de propriedade intelectual, fornecendo ferramentas
            para auxiliar e dinamizar o dia a dia dos seus usuários. A sistema
            caracteriza-se pela prestação do seguinte serviço: REGISTRO DE
            HABITUALIDADE; GESTÃO DO ACERVO; EMISSÃO DE DOCUMENTOS PERTINENTES;
          </p>

          <h2 className="sub-title">2. Da aceitação</h2>
          <p className="text">
            O presente Termo estabelece obrigações contratadas de livre e
            espontânea vontade, por tempo determinado, entre o sistema e as
            pessoas físicas ou jurídicas, usuárias do SISTEMA. O prazo
            determinado para o uso do sistema se limita a sua situação junto ao
            clube filiado. Ao utilizar o sistema o usuário aceita integralmente
            as presentes normas e compromete-se a observá-las, sob o risco de
            aplicação das penalidades cabíveis. A aceitação do presente
            instrumento é imprescindível para o acesso e para a utilização de
            quaisquer serviços fornecidos pela empresa. Caso não concorde com as
            disposições deste instrumento, o usuário não deve utilizá-los.
          </p>

          <h2 className="sub-title">3. Do acesso dos usuários</h2>
          <p className="text">
            Serão utilizadas todas as soluções técnicas à disposição do
            responsável pelo sistema para permitir o acesso ao serviço 24 (vinte
            e quatro) horas por dia, 7 (sete) dias por semana. No entanto, a
            navegação no sistema ou em alguma de suas páginas poderá ser
            interrompida, limitada ou suspensa para atualizações, modificações
            ou qualquer ação necessária ao seu bom funcionamento.
          </p>
        </div>
      </section>

      <section>
        <div className="content">
          <h2 className="sub-title">4. Do cadastro</h2>
          <p className="text">
            O acesso às funcionalidades do sistema exigirá a realização de um
            cadastro prévio que será realizado pelo clube. Ao obter acesso, o
            usuário deverá informar dados completos, recentes e válidos, sendo
            de sua exclusiva responsabilidade manter referidos dados
            atualizados, bem como o usuário se compromete com a veracidade dos
            dados fornecidos. O usuário se compromete a não informar seus dados
            cadastrais e/ou de acesso ao sistema a terceiros,
            responsabilizando-se integralmente pelo uso que deles seja feito.
            Menores de 18 anos e aqueles que não possuírem plena capacidade
            civil deverão obter previamente o consentimento expresso de seus
            responsáveis legais para utilização da sistema e dos serviços ou
            produtos, sendo de responsabilidade exclusiva dos mesmos o eventual
            acesso por menores de idade e por aqueles que não possuem plena
            capacidade civil sem a prévia autorização. Mediante a realização do
            cadastro o usuário declara e garante expressamente ser plenamente
            capaz, podendo exercer e usufruir livremente dos serviços e
            produtos. O usuário deverá fornecer um endereço de e-mail válido,
            através do qual o site realizará todas as comunicações necessárias.
            Após a confirmação do cadastro, o usuário possuirá um login e uma
            senha pessoal, a qual assegura ao usuário o acesso individual à
            mesma. Desta forma, compete ao usuário exclusivamente a manutenção
            de referida senha de maneira confidencial e segura, evitando o
            acesso indevido às informações pessoais. Toda e qualquer atividade
            realizada com o uso da senha será de responsabilidade do usuário,
            que deverá informar prontamente ao clube filiado em caso de uso
            indevido da respectiva senha. Não será permitido ceder, vender,
            alugar ou transferir, de qualquer forma, a conta, que é pessoal e
            intransferível. Caberá ao usuário assegurar que o seu equipamento
            seja compatível com as características técnicas que viabilize a
            utilização do sistema e dos serviços ou produtos. O usuário poderá,
            a qualquer tempo, requerer o cancelamento de seu cadastro junto ao
            clube. O seu descadastramento será realizado o mais rapidamente
            possível, desde que não sejam verificadas pendências. O usuário, ao
            aceitar os Termos e Política de Privacidade, autoriza expressamente
            a sistema a coletar, usar, armazenar, tratar ou utilizar as
            informações derivadas do uso do sistema, incluindo todas as
            informações preenchidas pelo usuário no momento em que realizar ou
            atualizar seu cadastro, além de outras expressamente descritas na
            Política de Privacidade que deverá ser autorizada pelo usuário.
          </p>
        </div>

        <div className="content">
          <h2 className="sub-title">5. Do suporte</h2>
          <p className="text">
            Em caso de qualquer dúvida, sugestão ou problema com a utilização do
            sistema, o usuário poderá entrar em contato com o clube, através do
            email {user.clube.email} OU telefone {user.clube.telefone}. Estes
            serviços de atendimento ao usuário estarão disponíveis de acordo com
            horários de funcionamento do clube.
          </p>
        </div>

        <div className="content">
          <h2 className="sub-title">6. Das responsabilidades</h2>
          <p className="text">
            É de responsabilidade do usuário: a) defeitos ou vícios técnicos
            originados no próprio sistema do usuário; b) a correta utilização do
            sistema oferecido, prezando pela boa convivência, pelo respeito e
            cordialidade entre os usuários; c) pelo cumprimento e respeito ao
            conjunto de regras disposto nesse Termo de Condições Geral de Uso,
            na respectiva Política de Privacidade e na legislação nacional e
            internacional; d) pela proteção aos dados de acesso à sua
            conta/perfil (login e senha). e) pela veracidade das informações
            prestadas e documentos anexados. d) os conteúdos ou atividades
            ilícitas praticadas no sistema. É de responsabilidade do sistema
            SISARMA: a) indicar as características do serviço ou produto; b) a
            resolução dos defeitos e vícios encontrados no sistema oferecido
            desde que lhe tenha dado causa; c) as informações que foram por ele
            divulgadas, sendo que os comentários ou informações divulgadas por
            usuários são de inteira responsabilidade dos próprios usuários; O
            sistema não se responsabiliza por links externos contidos em seu
            sistema que possam redirecionar o usuário ao ambiente externo a sua
            rede. Não poderão ser incluídos links externos ou páginas que sirvam
            para fins comerciais ou publicitários ou quaisquer informações
            ilícitas, violentas, polêmicas, pornográficas, xenofóbicas,
            discriminatórias ou ofensivas.
          </p>
        </div>
      </section>

      <section>
        <div className="content">
          <h2 className="sub-title">7. Dos direitos autorais</h2>
          <p className="text">
            O presente Termo de Uso concede aos usuários uma licença não
            exclusiva, não transferível e não sublicenciável, para acessar e
            fazer uso do sistema por ela disponibilizado. A estrutura do
            sistema/aplicativo, as marcas, logotipos, nomes comerciais, layouts,
            gráficos e design de interface, imagens, ilustrações, fotografias,
            apresentações, vídeos, conteúdos escritos e de som e áudio,
            programas de computador, banco de dados, arquivos de transmissão e
            quaisquer outras informações e direitos de propriedade intelectual
            da razão social PRECISA GESTÃO EM TECNOLOGIA E SERVICOS LTDA,
            observados os termos da{' '}
            <Link href="https://www.jusbrasil.com.br/legislacao/91774/código-de-propriedade-industrial-lei-9279-96">
              Lei da Propriedade Industrial (Lei nº 9.279/96)
            </Link>
            ,{' '}
            <Link href="https://www.jusbrasil.com.br/legislacao/92175/lei-de-direitos-autorais-lei-9610-98">
              Lei de Direitos Autorais (Lei nº 9.610/98)
            </Link>{' '}
            e{' '}
            <Link href="https://www.jusbrasil.com.br/legislacao/109879/lei-do-software-lei-9609-98">
              Lei do Software (Lei nº 9.609/98)
            </Link>
            , estão devidamente reservados. Este Termo de Uso não cede ou
            transfere ao usuário qualquer direito, de modo que o acesso não gera
            qualquer direito de propriedade intelectual ao usuário, exceto pela
            licença limitada ora concedida. O uso do sistema pelo usuário é
            pessoal, individual e intransferível, sendo vedado qualquer uso não
            autorizado, comercial ou não-comercial. Tais usos consistirão em
            violação dos direitos de propriedade intelectual da razão social
            PRECISA GESTÃO EM TECNOLOGIA E SERVICOS LTDA, puníveis nos termos da
            legislação aplicável.
          </p>
        </div>

        <div className="content">
          <h2 className="sub-title">8. Das sanções</h2>
          <p className="text">
            Sem prejuízo das demais medidas legais cabíveis, a razão social
            PRECISA GESTÃO EM TECNOLOGIA E SERVICOS LTDA poderá, a qualquer
            momento, advertir, suspender ou cancelar a conta do usuário: a) que
            violar qualquer dispositivo do presente Termo; b) que descumprir os
            seus deveres de usuário; c) que tenha qualquer comportamento
            fraudulento, doloso ou que ofenda a terceiros.
          </p>
        </div>

        <div className="content">
          <h2 className="sub-title">9. Das alterações</h2>
          <p className="text">
            Os itens descritos no presente instrumento poderão sofrer
            alterações, unilateralmente e a qualquer tempo, por parte de PRECISA
            GESTÃO EM TECNOLOGIA E SERVICOS LTDA, para adequar ou modificar os
            serviços, bem como para atender novas exigências legais. As
            alterações serão veiculadas pelo sistema/aplicativo e o usuário
            poderá optar por aceitar o novo conteúdo ou por cancelar o uso do
            sistema, caso julgue necessário. O sistema oferecido pode, a
            qualquer tempo e unilateralmente, e sem qualquer aviso prévio, ser
            deixado de fornecer, alterados em suas características, bem como
            restringido para o uso ou acesso.
          </p>
        </div>
      </section>

      <section>
        <div className="content">
          <h2 className="sub-title">10. Da política de privacidade</h2>
          <p className="text">
            Além do presente Termo, o usuário deverá consentir com as
            disposições contidas na respectiva Política de Privacidade a ser
            apresentada a todos os interessados dentro da interface do sistema.
          </p>
        </div>

        <div className="content">
          <h2 className="sub-title">11. Do foro</h2>
          <p className="text">
            Para a solução de controvérsias decorrentes do presente instrumento
            será aplicado integralmente o Direito brasileiro. Os eventuais
            litígios deverão ser apresentados no foro da comarca em que se
            encontra a sede da empresa.
          </p>
        </div>
      </section>
    </main>
  )
}

export default TermosUso
