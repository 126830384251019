import { useForm } from 'react-hook-form'
import { useAuth } from '../../context/AuthContext'
import Modal from '../Modal'
import { api } from '../../utils/api'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { Icon } from '@iconify/react'

const ModalNewPassord = ({ isOpen, onClose }) => {
  const { user } = useAuth()

  const {
    handleSubmit,
    watch,
    register,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${api}perfil/newPassword`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          banco: user.clube.banco,
          pessoaID: user.pessoa.pessoaID,
          clubeID: user.clube.clubeID,
          usuarioID: user.pessoa.usuarioID,
        }),
      })
      const res = await response.json()
      toast.success('Senha alterada com sucesso!')
      onClose()
      reset()
    } catch (error) {
      console.log(error)
      toast.error('Erro ao alterar dados!')
    }
  }

  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  })

  // Funções para validação e comparação de senha
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    })
  }

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      handleSave={handleSubmit(onSubmit)}
      onCloseIcon={onClose}
      title="Redefinir senha"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <TextField
          fullWidth
          label="Nova senha"
          id="input-password"
          variant="outlined"
          size="small"
          type={values.showPassword ? 'text' : 'password'}
          name="senha"
          error={!!errors.senha}
          helperText={errors.senha && errors.senha.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  <Icon
                    icon={
                      values.showPassword
                        ? 'mdi:eye-outline'
                        : 'mdi:eye-off-outline'
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('senha', {
            minLength: {
              value: 4,
              message: 'Senha deve ter pelo menos 4 caracteres',
            },
          })}
        />

        <TextField
          fullWidth
          label="Confirmar nova senha"
          id="input-confirm-password"
          variant="outlined"
          size="small"
          type={values.showConfirmPassword ? 'text' : 'password'}
          name="confirmaSenha"
          error={!!errors.confirmaSenha}
          helperText={errors.confirmaSenha && errors.confirmaSenha.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                >
                  <Icon
                    icon={
                      values.showConfirmPassword
                        ? 'mdi:eye-outline'
                        : 'mdi:eye-off-outline'
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('confirmaSenha', {
            validate: (value) =>
              value === watch('senha') || 'As senhas não coincidem',
          })}
        />
      </form>
    </Modal>
  )
}

export default ModalNewPassord
