import Button from '../components/forms/Button'
import { useForm } from 'react-hook-form'
import { useAuth } from '../context/AuthContext'
import Modal from '../components/Modal'
import SelectClube from '../components/SelectClube'
import Input from '../components/forms/Input'
import { useEffect, useState } from 'react'
import ModalConfirmPassordRedefinition from '../components/login/ModalConfirmPassordRedefinition'
import ButtonsDowload from '../components/ButtonsDowload'
import { useTheme } from '@mui/material/styles'

const Login = () => {
  const [openModalPasswordRedefinition, setOpenModalPasswordRedefinition] =
    useState(false)
  const [cpf, setCpf] = useState(null)
  const theme = useTheme()

  const {
    validatelogin,
    dataLogin,
    login,
    modalSelectedClube,
    setModalSelectedClube,
  } = useAuth()
  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    console.log('🚀 ~ data:', data)
    validatelogin(data)
  }

  const handleLogin = (data) => {
    const dataFormat = {
      ...dataLogin,
      ...data,
    }
    login(dataFormat)
  }

  const onCloseModalPasswordRedefinition = () => {
    setOpenModalPasswordRedefinition(false)
    setCpf(null)
  }
  useEffect(() => {
    document.title = 'SisarmaApp | Login'
  }, [])

  return (
    <>
      {/* Modal confirmar envio de email com nova senha */}
      <ModalConfirmPassordRedefinition
        isOpen={openModalPasswordRedefinition}
        onClose={onCloseModalPasswordRedefinition}
        cpf={cpf}
        setCpf={setCpf}
      />
      <div className="w-screen">
        <Modal
          isOpen={modalSelectedClube}
          onClose={() => setModalSelectedClube(false)}
          onCloseIcon={() => setModalSelectedClube(false)}
          handleSave={handleSubmit(handleLogin)}
          title="Selecionar Clube"
          isValid={false}
          size="sm"
          titleBnt1="Entrar"
          titleBnt2="Fechar"
        >
          <SelectClube
            handleSubmit={() => handleSubmit(onSubmit)}
            register={register}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setError={setError}
          />
        </Modal>
        <div className="w-full px-4 md:px-0 md:w-[60%] mx-auto h-screen flex justify-center items-start p-12">
          <form
            className="flex flex-col items-center gap-6 w-[600px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="space-y-4">
              <div>
                <img
                  className="w-full pb-8"
                  src={
                    theme.palette.mode === 'dark' ? 'logoDark.png' : 'logo.png'
                  }
                  alt=""
                />
              </div>
              <Input
                clearErrors={clearErrors}
                xs={12}
                placeholder="CPF"
                control={control}
                errors={errors?.cpf}
                name="cpf"
                mask="cpf"
                title="CPF"
              />
              <Input
                setError={setError}
                xs={12}
                placeholder="Senha"
                control={control}
                errors={errors?.senha}
                type="password"
                name="senha"
                title="Senha"
              />
              <Button
                name="Entrar"
                type="submit"
                color="primary"
                isValid={false}
              />
              <div
                className="text-center"
                onClick={() => {
                  setOpenModalPasswordRedefinition(true)
                  setCpf(getValues('cpf'))
                }}
              >
                <span className="opacity-60 underline hover:opacity-100 cursor-pointer">
                  Esqueci a senha
                </span>
              </div>
              <ButtonsDowload />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login
