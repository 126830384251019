import React, { createContext, useState, useContext } from 'react'

const initialState = {
  isLoading: false,
  startLoading: () => {},
  stopLoading: () => {},
}

const LoadingContext = createContext(initialState)

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const values = {
    isLoading,
    startLoading,
    stopLoading,
  }

  return (
    <LoadingContext.Provider value={values}>{children}</LoadingContext.Provider>
  )
}

const useLoading = () => {
  return useContext(LoadingContext)
}

export { LoadingContext, LoadingProvider, useLoading }
