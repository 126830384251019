import { Controller } from 'react-hook-form'
import { FormControl, Grid, TextField } from '@mui/material'
import { cpfMask } from '../../utils/mask'
import { formatCellPhone } from '../../utils/functions'

const Input = ({
  xs,
  md,
  title,
  placeholder,
  name,
  rows,
  value,
  type,
  mask,
  getAddressByCep,
  multiline,
  disabled,
  required,
  clearErrors,
  setError,
  control,
  errors,
  onChange,
  className,
  help,
  helpText,
  helpTextPosition,
  minDate,
  maxDate,
  ...props
}) => {
  return (
    <Grid item xs={xs} md={md} sx={{ my: 1 }} className={className}>
      <div className="relative">
        <FormControl fullWidth sx={{ position: 'relative' }}>
          <Controller
            name={name}
            control={control}
            defaultValue={value}
            rules={{ required: required }}
            render={({ field }) => (
              <>
                <TextField
                  {...props}
                  multiline={multiline}
                  value={field?.value}
                  label={title}
                  placeholder={placeholder}
                  rows={rows}
                  type={type ?? 'text'}
                  size="small"
                  disabled={disabled}
                  aria-describedby="validation-schema-nome"
                  error={errors}
                  onChange={(e) => {
                    let value = e.target.value
                    if (clearErrors && setError) {
                      if (value) {
                        clearErrors(name)
                      } else {
                        setError(name, {
                          type: 'required',
                          message: 'custom message',
                        })
                      }
                    }

                    if (mask === 'cpf') {
                      value = cpfMask(value)
                    } else if (type === 'number' && mask !== 'cpf') {
                      value = value.replace(/\D/g, '')
                    } else if (mask === 'cellPhone') {
                      value = formatCellPhone(value)
                    }

                    field.onChange(value)
                    if (onChange) {
                      onChange(value)
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={
                    mask === 'cellPhone'
                      ? {
                          inputMode: 'numeric',
                          type: 'tel',
                          maxLength: 15,
                        }
                      : mask === 'cpf'
                      ? {
                          maxLength: 14,
                          type: 'tel',
                          inputMode: 'numeric',
                        }
                      : type === 'number'
                      ? {
                          inputMode: 'numeric',
                          type: 'tel',
                        }
                      : {
                          min: minDate,
                          max: maxDate,
                        }
                  }
                />
              </>
            )}
          />
        </FormControl>
      </div>
    </Grid>
  )
}

export default Input
