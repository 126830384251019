import { useEffect, useState } from 'react'
import Modal from '../Modal'
import { api } from '../../utils/api'
import toast from 'react-hot-toast'
import { Alert, TextField } from '@mui/material'
import { validationCPF } from '../../utils/validation'
import { cpfMask } from '../../utils/mask'
import { useLoading } from '../../context/LoadingContext'

const ModalConfirmPassordRedefinition = ({ isOpen, onClose, cpf, setCpf }) => {
  const [data, setData] = useState(null)
  const [emailInvalido, setEmailInvalido] = useState(false)
  const { startLoading, stopLoading, isLoading } = useLoading()

  function substituirPorAsteriscos(email) {
    if (email) {
      var partes = email.split('@')
      if (partes.length === 2) {
        var usuario = partes[0]
        var dominio = partes[1]
        if (usuario.length >= 4) {
          var novoUsuario = usuario.substring(0, usuario.length - 4) + '****'
          var novoEmail = novoUsuario + '@' + dominio
          return novoEmail
        } else {
          console.log(
            "O nome de usuário deve ter pelo menos 4 caracteres antes do '@'."
          )
        }
      } else {
        return false
      }
    }
  }

  function gerarSenha() {
    var caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var senha = ''
    for (var i = 0; i < 4; i++) {
      var aleatorio = Math.floor(Math.random() * caracteres.length)
      senha += caracteres.charAt(aleatorio)
    }
    return senha
  }

  const SendEmail = async () => {
    try {
      startLoading()
      const response = await fetch(`${api}login/atualizaSenha`, {
        method: 'POST',
        body: JSON.stringify({
          email: data.email,
          nome: data.nome,
          senha: gerarSenha(),
          cpf: cpf.replace(/\D/g, ''),
        }),
      })
      onClose()
      toast.success('Email enviado com sucesso!')
      const res = await response.json()
    } catch (error) {
      console.log(error)
    } finally {
      stopLoading()
    }
  }

  const getData = async () => {
    setEmailInvalido(false)
    if (cpf?.length < 11) {
      return
    } else {
      if (cpf) {
        const cpfValidation = validationCPF(cpf)
        if (cpfValidation) {
          const cpfFormat = cpf.replace(/\D/g, '')
          try {
            const response = await fetch(`${api}login/esqueciSenha`, {
              method: 'POST',
              body: JSON.stringify({
                cpf: cpfFormat,
              }),
            })
            const data = await response.json()
            if (data) {
              const verifyEmail = substituirPorAsteriscos(data.email)
              if (!verifyEmail) {
                setEmailInvalido(true)
              }
              setData(data)
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  }

  const handleChangeCpf = (value) => {
    const valueFormat = cpfMask(value)
    setCpf(valueFormat)
    if (value && value.length === 14 && validationCPF(value)) {
      getData()
    } else {
      setData(null)
    }
  }

  useEffect(() => {
    getData()
  }, [cpf, isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseIcon={onClose}
      title="Esqueci a senha"
      isValid={false}
      handleSave={SendEmail}
      disabledBnt1={cpf?.length < 14 || !data || !data.email}
      titleBnt1={`${isLoading ? 'Enviando...' : 'Enviar'}`}
      effectLoading={true}
    >
      <TextField
        value={cpf}
        onChange={(e) => handleChangeCpf(e.target.value)}
        label="CPF"
        placeholder="CPF"
        size="small"
        inputProps={{ maxLength: 14 }}
        fullWidth
      />
      <div className="pt-4">
        {/* Email incompleto */}
        {!cpf && (
          <Alert severity="error" className="pt-8">
            Digite o CPF para continuar
          </Alert>
        )}
        {/* Email incompleto */}
        {!cpf ||
          (cpf.length < 14 && (
            <Alert severity="error" className="pt-8">
              Digite o CPF para continuar
            </Alert>
          ))}
        {/* CPF não encontrado na base */}
        {cpf && cpf?.length >= 14 && !data && (
          <div>
            <Alert severity="error" className="pt-8">
              CPF não encontrado
            </Alert>
          </div>
        )}
        {/* CPF encontrado porén o email é inválido */}
        {cpf && cpf?.length >= 14 && data && emailInvalido && (
          <div>
            <Alert severity="error">
              <h1>Email inválido!</h1>
            </Alert>
            <p className="text-sm pt-2">
              Contate o clube para solicitar uma nova senha!
            </p>
          </div>
        )}
        {/* CPF encontrado e o email é válido */}
        {cpf && cpf?.length >= 14 && data && !emailInvalido && (
          <h1>
            Será enviado uma nova senha para o email{' '}
            {substituirPorAsteriscos(data.email)}
          </h1>
        )}
      </div>
    </Modal>
  )
}

export default ModalConfirmPassordRedefinition
