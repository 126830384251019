import { Grid, FormControlLabel, Switch } from '@mui/material'

const SwitchLabel = ({
  xs,
  md,
  title,
  index,
  name,
  typePage,
  value,
  disabled,
  labelPlacement = 'end',
  register,
  trigger,
  onChange,
  ...props
}) => {
  return (
    <Grid item xs={xs} md={md}>
      <FormControlLabel
        label={title}
        labelPlacement={labelPlacement}
        control={
          <Switch
            {...props}
            name={name}
            {...register(name)}
            defaultChecked={value}
            disabled={disabled}
            onChange={(e) => {
              let value = e.target.checked
              onChange(value)
            }}
          />
        }
        size="small"
        sx={{
          '&:hover': {
            '& .MuiFormControlLabel-label': {
              color: 'primary.main',
            },
          },
        }}
      />
    </Grid>
  )
}

export default SwitchLabel
