import { Link } from '@mui/material'
import React from 'react'
import { useAuth } from '../../../../context/AuthContext'

const PoliticaPrivacidade = () => {
  const { user } = useAuth()

  return (
    <main>
      <h1 className="title">Política de Privacidade do uso do SISARMA.</h1>
      <section>
        <div className="content">
          <h2 className="sub-title">SEÇÃO 1 - INFORMAÇÕES GERAIS</h2>
          <p className="text">
            A presente Política de Privacidade contém informações sobre coleta,
            uso, armazenamento, tratamento e proteção dos dados pessoais dos
            usuários do sistema SISARMA, com a finalidade de demonstrar absoluta
            transparência quanto ao assunto e esclarecer a todos interessados
            sobre os tipos de dados que são coletados, os motivos da coleta e a
            forma como os usuários podem gerenciar ou excluir as suas
            informações pessoais. Esta Política de Privacidade aplica-se a todos
            os usuários do sistema e integra os Termos e Condições Gerais de Uso
            do sistema. O presente documento foi elaborado em conformidade com a{' '}
            <Link href="">
              Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18)
            </Link>
            ,<Link href="">o Marco Civil da Internet (Lei 12.965/14)</Link>
            (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser
            atualizado em decorrência de eventual atualização normativa, razão
            pela qual se convida o usuário a consultar periodicamente esta
            seção.
          </p>
        </div>

        <div className="content">
          <h2 className="sub-title">
            SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO.
          </h2>
          <p className="text">
            Os dados pessoais do usuário são recolhidos pelo sistema da seguinte
            forma:
            <ul className="list">
              <li>
                Quando o usuário é habilitado junto ao sistema, esses dados são
                de identificação básica, como: e-mail, nome completo, cidade de
                residência e profissão, entre outros. A partir deles, podemos
                identificar o usuário, além de garantir uma maior segurança e
                bem-estar às suas necessidades. Ficam cientes os usuários de que
                seu perfil no sistema estará acessível a ele, ao clube de
                filiação e ao desenvolvedor do sistema.
              </li>
              <li>
                Quando um usuário acessa o sistema SISARMA: as informações sobre
                interação e acesso são coletadas pela empresa para garantir uma
                melhor experiência ao usuário. Estes dados podem tratar sobre as
                palavras-chaves utilizadas em uma busca, o compartilhamento de
                um documento específico, o IP de acesso, dentre outras que
                poderão ser armazenadas e retidas.
              </li>
            </ul>
          </p>
        </div>
        <div className="content">
          <h2 className="sub-title">
            SEÇÃO 3 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO
            USUÁRIO ?
          </h2>
          <div classNameName="text">
            <p className="text">
              Os dados pessoais do usuário coletados e armazenados pelo sistema
              SISARMA tem por finalidade:
              <ul className="list">
                <li>
                  Bem-estar do usuário: aprimorar o produto e/ou serviço
                  oferecido, facilitar, agilizar e cumprir os compromissos
                  estabelecidos entre o usuário e a empresa, melhorar a
                  experiência dos usuários e fornecer funcionalidades
                  específicas a depender das características básicas do usuário.
                </li>
                <li>
                  Melhorias do sistema: compreender como o usuário utiliza os
                  serviços do sistema, para ajudar no desenvolvimento de
                  negócios e técnicas.
                </li>
                <li>
                  Comercial: os dados são usados para personalizar o conteúdo
                  oferecido e gerar subsídio ao sistema para a melhora da
                  qualidade no funcionamento dos serviços.
                </li>
                <li>
                  Previsão do perfil do usuário: tratamento automatizado de
                  dados pessoais para avaliar o uso do sistema.
                </li>
                <li>
                  Dados de cadastro: para permitir o acesso do usuário a
                  determinados conteúdos da sistema, exclusivo para usuários
                  cadastrados.
                </li>
                <li>
                  Dados de contrato: conferir às partes segurança jurídica e
                  facilitar a conclusão do negócio.
                </li>
              </ul>
            </p>
            <p>
              O tratamento de dados pessoais para finalidades não previstas
              nesta Política de Privacidade somente ocorrerá mediante
              comunicação prévia ao usuário, de modo que os direitos e
              obrigações aqui previstos permanecem aplicáveis.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="content">
          <h2 className="sub-title">
            SEÇÃO 4 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
          </h2>
          <p className="text">
            Os dados pessoais do usuário são armazenados pelo sistema durante o
            período necessário para a prestação do serviço ou o cumprimento das
            finalidades previstas no presente documento, conforme o disposto no
            inciso
            <Link href="https://www.jusbrasil.com.br/topicos/200399061/inciso-i-do-artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018">
              I
            </Link>
            do artigo{' '}
            <Link href="https://www.jusbrasil.com.br/topicos/200399064/artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018">
              15
            </Link>{' '}
            da{' '}
            <Link href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18">
              Lei 13.709/18
            </Link>
            . Os dados podem ser removidos ou anonimizados a pedido do usuário,
            excetuando os casos em que a lei oferecer outro tratamento. Ainda,
            os dados pessoais dos usuários apenas podem ser conservados após o
            término de seu tratamento nas seguintes hipóteses previstas no
            artigo 16 da referida lei: I - cumprimento de obrigação legal ou
            regulatória pelo controlador; II - estudo por órgão de pesquisa,
            garantida, sempre que possível, a anonimização dos dados pessoais;
            III - transferência a terceiro, desde que respeitados os requisitos
            de tratamento de dados dispostos nesta Lei; IV - uso exclusivo do
            controlador, vedado seu acesso por terceiro, e desde que
            anonimizados os dados.
          </p>
        </div>
        <div className="content">
          <h2 className="sub-title">
            SEÇÃO 5 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS
          </h2>
          <p className="text">
            O sistema se compromete a aplicar as medidas técnicas e
            organizativas aptas a proteger os dados pessoais de acessos não
            autorizados e de situações de destruição, perda, alteração,
            comunicação ou difusão de tais dados. O sistema se exime de
            responsabilidade por culpa exclusiva de terceiro, como em caso de
            ataque de hackers ou crackers, ou culpa exclusiva do usuário, como
            no caso em que ele mesmo transfere seus dados a terceiros. O sistema
            compromete-se a comunicar o usuário em caso de alguma violação de
            segurança dos seus dados pessoais. Os dados pessoais armazenados são
            tratados com confidencialidade, dentro dos limites legais. No
            entanto, podemos divulgar suas informações pessoais caso sejamos
            obrigados pela lei para fazê-lo ou se você violar nossos Termos de
            Serviço.
          </p>
        </div>
        <div className="content">
          <h2 className="sub-title">SEÇÃO 6 - COMPARTILHAMENTO DOS DADOS</h2>
          <p className="text">
            O compartilhamento de dados do usuário ocorre apenas com os dados
            referentes a publicações realizadas pelo próprio usuário, tais ações
            são compartilhadas publicamente com os outros usuários.
          </p>
        </div>
        <div className="content">
          <h2 className="sub-title">
            SEÇÃO 6.1 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A
            TERCEIROS?
          </h2>
          <p className="text">
            Os dados pessoais não podem ser compartilhados com terceiros, exceto
            por força de lei. Ao ser redirecionado para um aplicativo ou site de
            terceiros, você não será mais regido por essa Política de
            Privacidade ou pelos Termos de Serviço do nosso sistema. Não somos
            responsáveis pelas práticas de privacidade de outros sites e lhe
            incentivamos a ler as declarações de privacidade deles.
          </p>
        </div>
      </section>

      <section>
        <div className="content">
          <h2 className="sub-title">SEÇÃO 7 – COOKIES OU DADOS DE NAVEGAÇÃO</h2>
          <p className="text">
            Os cookies referem-se a arquivos de texto enviados pelo sistema ao
            computador do usuário e que nele ficam armazenados, com informações
            relacionadas à navegação no sistema. Tais informações são
            relacionadas aos dados de acesso como local e horário de acesso e
            são armazenadas pelo navegador do usuário para que o servidor do
            sistema possa lê-las posteriormente a fim de personalizar os
            serviços do sistema. O usuário do sistema SISARMA manifesta conhecer
            e aceitar que pode ser utilizado um sistema de coleta de dados de
            navegação mediante a utilização de cookies. O cookie persistente
            permanece no disco rígido do usuário depois que o navegador é
            fechado e será usado pelo navegador em visitas subsequentes ao
            sistema. Os cookies persistentes podem ser removidos seguindo as
            instruções do seu navegador. Já o cookie de sessão é temporário e
            desaparece depois que o navegador é fechado. É possível redefinir
            seu navegador da web para recusar todos os cookies, porém alguns
            recursos do sistema podem não funcionar corretamente se a capacidade
            de aceitar cookies estiver desabilitada.
          </p>
        </div>
        <div className="content">
          <h2 className="sub-title">SEÇÃO 8 - CONSENTIMENTO</h2>
          <p className="text">
            Ao utilizar os serviços e fornecer as informações pessoais no
            sistema, o usuário está consentindo com a presente Política de
            Privacidade. O usuário, ao cadastrar-se, manifesta conhecer e pode
            exercitar seus direitos de cancelar seu cadastro, acessar e
            atualizar seus dados pessoais e garante a veracidade das informações
            por ele disponibilizadas. O usuário tem direito de retirar o seu
            consentimento a qualquer tempo, para tanto deve entrar em contato
            através do e-mail {user.clube.email} ou por correio enviado ao
            seguinte endereço: {user.clube.endereco}
          </p>
        </div>
        <div className="content">
          <h2 className="sub-title">
            SEÇÃO 9 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
          </h2>
          <p className="text">
            Reservamos o direito de modificar essa Política de Privacidade a
            qualquer momento, então, é recomendável que o usuário revise-a com
            frequência. As alterações e esclarecimentos vão surtir efeito
            imediatamente após sua publicação no sistema. Quando realizadas
            alterações os usuários serão notificados. Ao utilizar o serviço ou
            fornecer informações pessoais após eventuais modificações, o usuário
            demonstra sua concordância com as novas normas. Diante da fusão ou
            venda do sistema à outra empresa os dados dos usuários podem ser
            transferidos para os novos proprietários para que a permanência dos
            serviços oferecidos.
          </p>
        </div>
        <div className="content">
          <h2 className="sub-title">
            SEÇÃO 10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS
          </h2>
          <p className="text">
            Para a solução de controvérsias decorrentes do presente instrumento
            será aplicado integralmente o Direito brasileiro. Os eventuais
            litígios deverão ser apresentados no foro da comarca em que se
            encontra a sede da empresa.
          </p>
        </div>
      </section>
    </main>
  )
}

export default PoliticaPrivacidade
