import React, { useEffect, useState } from 'react'
import { resumoType } from '../../types/financas'
import { Card, CardContent } from '@mui/material'
import { TbPigMoney } from 'react-icons/tb'
import { formatarMoedaBRL } from '../../utils/functions'

const initialState = resumoType

const Resumo = ({ values }) => {
  const [data, setData] = useState(initialState)

  useEffect(() => {
    setData(values)
  }, [values])

  const saldo = data.totalPago - data.total

  return (
    <Card>
      <CardContent>
        <h1 className="pb-4 font-semibold text-lg">Resumo</h1>
        <div className="grid grid-cols-2">
          <div>
            <div
              className={`rounded-xl w-full flex justify-center items-center h-full text-white  ${
                saldo < 0 ? 'bg-danger' : 'bg-primary'
              }`}
            >
              <div className="flex flex-col justify-center gap-1 items-center">
                <TbPigMoney className="text-5xl" />
                <h1>{saldo < 0 ? 'Pendência financeira' : 'Sem pendências'}</h1>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end gap-4 w-full">
            <div>
              <p className="text-sm opacity-80 text-right">Total gasto</p>
              <p className="font-medium text-right">
                {formatarMoedaBRL(Number(data.total))}
              </p>
            </div>
            <div>
              <p className="text-sm opacity-80 text-right">Total Pago</p>
              <p className="font-medium text-right">
                {formatarMoedaBRL(Number(data.totalPago))}
              </p>
            </div>
            <div>
              <p className="text-sm opacity-80 text-right">Saldo</p>
              <p
                className={`${
                  saldo >= 0 ? 'text-primary' : 'text-danger'
                } font-medium text-right`}
              >
                {formatarMoedaBRL(saldo)}
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default Resumo
