export const historicoTypes = [
  {
    origem: '',
    data: '',
    arma: '',
    municao: '',
    tipo: '',
    id: '',
    calibre: '',
    calibreID: '',
    descricao: '',
  },
]
