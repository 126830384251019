export const armType = {
    acabamento: '',
    calibre: '',
    capacidade: '',
    id: '',
    imagem: '',
    marca: '',
    modelo: '',
    nome: '',
    numeroSerie: '',
    sistemaControle: '',
    dataVencimento: '',
    dataEmissao: '',
    permitidoRestrito: '',
    motivo: '',
}

export const selectedType = {
    id: '',
    nome: '',
}

export const competionCreate = {
    QtdMunicao: '',
    armaPropria: '',
    banco: '',
    clubeID: '',
    colocacao: '',
    data: '',
    descricao: '',
    divisao: '',
    local: '',
    pontos: '',
    armaSelecionada: armType,
    modalidade: selectedType,
    tipoEvento: selectedType,
}

export const treinamentCreate = {
    QtdMunicao: '',
    armaPropria: '',
    banco: '',
    clubeID: '',
    data: '',
    horaFim: '',
    horaInicio: '',
    observacao: '',
    pontos: '',
    armaSelecionada: armType,
}
