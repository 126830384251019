import { createContext, useContext, useState } from 'react'
import { competionCreate, treinamentCreate } from '../types/habitualidade'

const initialState = {
  competicao: competionCreate,
  setCompeticao: () => {},
  treinamento: treinamentCreate,
  setTreinamento: () => {},
}
const MensagemContext = createContext(initialState)

const MensagemProvider = ({ children }) => {
  const [competicao, setCompeticao] = useState(competionCreate)
  const [treinamento, setTreinamento] = useState(treinamentCreate)

  const values = {
    competicao,
    setCompeticao,
    treinamento,
    setTreinamento,
  }

  return (
    <MensagemContext.Provider value={values}>
      {children}
    </MensagemContext.Provider>
  )
}

const useMensagemContext = () => {
  return useContext(MensagemContext)
}

export { MensagemContext, MensagemProvider, useMensagemContext }
