import * as React from 'react'
import { Dialog } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'

const Modal2 = ({ handleClose, open, children, title, subtitle }) => {
  const theme = useTheme()

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          className={`${
            theme.palette.mode === 'dark' ? 'bg-[#18181B]' : 'bg-white'
          }`}
          sx={{
            textAlign: 'center',
            backgroundColor: theme.palette.card,
          }}
        >
          <div className="text-xl font-medium">{title}</div>
          <div className="text-xs opacity-70">{subtitle}</div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          className={`${
            theme.palette.mode === 'dark' ? 'bg-[#18181B]' : 'bg-white'
          }`}
        >
          {children}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Modal2
