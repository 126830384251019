import { Alert } from '@mui/material'

const AlertCR = ({ data }) => {
  return (
    <Alert
      className="!text-xs !md:text-base"
      color={
        !data.cr || !data.diasVencimento
          ? 'error'
          : data.diasVencimento <= 30
          ? 'error'
          : data.diasVencimento <= 60
          ? 'warning'
          : 'info'
      }
    >
      {!data.cr
        ? 'Você ainda não tem um CR informado no sistema'
        : !data.diasVencimento
        ? 'O seu CR está sem data de validade'
        : Number(data.diasVencimento) < -1
        ? 'O seu CR venceu a ' + Number(data.diasVencimento * -1) + ' dias'
        : data.diasVencimento === '-1'
        ? 'O seu CR venceu ontem'
        : data.diasVencimento === '0'
        ? 'O seu CR vence hoje'
        : data.diasVencimento === '1'
        ? 'O seu CR vence amanhã'
        : `O seu CR vence em ${data.diasVencimento} dias`}
    </Alert>
  )
}

export default AlertCR
