import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// Pages import Here
import Inicio from './pages/Inicio'
import Habitualidade from './pages/Habitualidade'
import AcervoPessoal from './pages/AcervoPessoal'
import Financeiro from './pages/Financeiro'
import Historico from './pages/Historico'
import Mensagem from './pages/Mensagem'
import Perfil from './pages/Perfil'
import Login from './pages/Login'
import HistoricoDetalhes from './pages/HistoricoDetalhes'
import Log from './pages/Log'

import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

// Configuração do Google Analytics
ReactGA.initialize('G-LPVSWD1GH9')

const App = () => {
  const history = useHistory()

  // if ('serviceWorker' in navigator) {
  //   window.addEventListener('load', function () {
  //     navigator.serviceWorker.register('/sw.js').then(
  //       function (registration) {
  //         console.log(
  //           'ServiceWorker registration successful with scope: ',
  //           registration.scope
  //         )
  //       },
  //       function (err) {
  //         console.log('ServiceWorker registration failed: ', err)
  //       }
  //     )
  //   })
  // }

  // Rastrear a página atual ao carregar
  useEffect(() => {
    const localStorageData =
      JSON.parse(localStorage.getItem('@sisarma-socio-notification')) || {}

    const { reload } = localStorageData

    if (!reload) {
      localStorage.setItem(
        '@sisarma-socio-notification',
        JSON.stringify({ reload: '1' })
      )
      history.push('/login')
    }

    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [history])

  return (
    <Router>
      <head>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LPVSWD1GH9"
        ></script>{' '}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());                  
            gtag('config', 'G-939XB5GT7W');
            `,
          }}
        ></script>
      </head>
      <Switch>
        <Route
          path={`${process.env.PUBLIC_URL}/login`}
          exact
          component={Login}
        />
        <Route path={`${process.env.PUBLIC_URL}/`} exact component={Inicio} />
        <Route
          path={`${process.env.PUBLIC_URL}/habitualidade`}
          exact
          component={Habitualidade}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/acervo-pessoal`}
          exact
          component={AcervoPessoal}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/financeiro`}
          exact
          component={Financeiro}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/historico`}
          exact
          component={Historico}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/historico/:tipo/:id`}
          exact
          component={HistoricoDetalhes}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mensagem`}
          exact
          component={Mensagem}
        />
        <Route path={`${process.env.PUBLIC_URL}/log`} exact component={Log} />
        <Route
          path={`${process.env.PUBLIC_URL}/perfil`}
          exact
          component={Perfil}
        />
      </Switch>
    </Router>
  )
}

export default App
