import { Avatar, Button, Grid, IconButton } from '@mui/material'
import { useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5'
import { MdOutlineCloudUpload } from 'react-icons/md'
import { styled } from '@mui/material/styles'
import ModalViewFile from '../ModalViewFile'
import { mostrarTextoLimitado } from '../../utils/functions'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const Anexos = ({
  files,
  setFiles,
  previews,
  setPreviews,
  setArrDelete,
  arrDelete,
}) => {
  const [openModalFile, setOpenModalFile] = useState(false)
  const [file, setFile] = useState(null)

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files)
    setFiles([...files, ...newFiles])

    const newPreviews = newFiles.map((file) => {
      return {
        preview: URL.createObjectURL(file),
        name: e.target.files[0].name,
      }
    })
    setPreviews([...previews, ...newPreviews])
  }

  const handleRemoveFile = (index, preview) => {
    if (preview.pessoaArmaID) {
      setArrDelete([...arrDelete, preview.pessoaArmaAnexoID])
    }
    URL.revokeObjectURL(previews[index])

    const updatedFiles = files.filter((_, i) => i !== index)
    const updatedPreviews = previews.filter((_, i) => i !== index)

    setFiles(updatedFiles)
    setPreviews(updatedPreviews)
  }

  const onCloseModalViewFile = () => {
    setOpenModalFile(false)
    setFile(null)
  }

  const handleClickFile = (file, preview) => {
    if (!file) return
    setOpenModalFile(true)
    if (file?.type && file.type.startsWith('image/')) {
      setFile({
        preview,
        type: 'image/preview',
      })
    }
    if (file?.formatoArquivo?.startsWith('image/')) {
      setFile({
        preview,
        type: 'image/banco',
      })
    }

    if (file.type === 'application/pdf') {
      setFile({
        preview,
        type: 'pdf/preview',
      })
    }
    if (file.formatoArquivo === 'application/pdf') {
      setFile({
        preview,
        type: 'pdf/banco',
      })
    }
  }

  return (
    <>
      <ModalViewFile
        isOpen={openModalFile}
        onClose={onCloseModalViewFile}
        file={file}
      />
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1 className="text-lg font-medium">Anexos</h1>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<MdOutlineCloudUpload className="text-xl" />}
              className="w-full"
            >
              <div className="flex items-center gap-1">
                <p className="capitalize ">Adicionar</p>
                <p className="lowercase">anexo</p>
              </div>
              <VisuallyHiddenInput
                type="file"
                accept="image/*,.pdf"
                name="files"
                multiple
                onChange={handleFileChange}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div className=" flex flex-col gap-4">
              {previews &&
                previews.map((preview, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex items-center justify-between border-2 border-black/30 ${
                        !preview.pessoaArmaAnexoID && 'border-dashed'
                      } rounded-lg  p-2`}
                    >
                      <div className="flex items-center gap-2">
                        <Avatar
                          onClick={() => handleClickFile(files[index], preview)}
                          className="cursor-pointer"
                          variant="rounded"
                          alt={files[index]?.name ?? preview.name}
                          src={
                            preview.extensaoArquivo === 'pdf' ||
                            files[index].type === 'application/pdf'
                              ? '/pdf.png'
                              : preview.imagem ?? preview.preview
                          }
                          sx={{
                            width: 56,
                            height: 56,
                            border: '1px solid #b1b1b1',
                          }}
                        />
                        <p className="text-sm opacity-90 truncate sm:hidden">
                          {mostrarTextoLimitado(
                            preview.descrição ?? preview.name,
                            20
                          )}
                        </p>
                        <p className="text-sm opacity-90 hidden sm:block">
                          {preview.descrição ?? preview.name}
                        </p>
                      </div>
                      <IconButton
                        onClick={() => handleRemoveFile(index, preview)}
                      >
                        <IoCloseOutline />
                      </IconButton>
                    </div>
                  )
                })}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Anexos
