import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FaRegComments } from 'react-icons/fa'
import IconBack from '../components/IconBack'
import { useAuth } from '../context/AuthContext'
import TableFilter from '../components/Filter/TableFilter'
import { Card, CardContent } from '@mui/material'
import ModalLog from '../components/Log/ModalLog'
import { api } from '../utils/api'
import { useFilter } from '../context/FilterContext'
import Filters from '../components/Log/Filters'
import { cleanJSONString } from '../utils/functions'

const Log = () => {
  const history = useHistory()
  const { validateAccess, user } = useAuth()
  const { data, setData, setFilteredData, startFilter } = useFilter()
  const [modalLog, setModalLog] = useState(false)
  const [row, setRow] = useState({})

  const getData = async () => {
    try {
      const response = await fetch(`${api}log/getData`, {
        method: 'POST',
        body: JSON.stringify({
          banco: user.clube.banco,
          clubeID: user.clube.clubeID,
          pessoaID: user.pessoa.pessoaID,
        }),
      })
      const res = await response.json()

      const groupedData = Object.values(
        res.reduce((acc, curr) => {
          const { total, id, dataHora, data, usuario, ip, nome, alteracao } =
            curr
          const cleanedAlteracao = alteracao ? cleanJSONString(alteracao) : null
          const parsedAlteracao = cleanedAlteracao
            ? JSON.parse(cleanedAlteracao)
            : null

          let alteracaoString = ''
          if (parsedAlteracao) {
            alteracaoString = `before: ${JSON.stringify(
              parsedAlteracao.before
            )} -> after: ${JSON.stringify(parsedAlteracao.after)}`
          }

          const alteredData = {
            total,
            id,
            dataHora,
            data,
            usuario,
            ip,
            nome,
            alteracao: alteracaoString,
          }

          if (!acc[id]) {
            acc[id] = alteredData
          } else {
            acc[id].alteracao += `\n${alteredData.alteracao}`
          }
          return acc
        }, {})
      )

      // Remover as barras invertidas da propriedade "alteracao" de cada objeto
      groupedData.forEach((obj) => {
        obj.alteracao = obj.alteracao.replace(/\\n/g, '\n')
      })

      const reversedData = [...groupedData].reverse()
      setData(reversedData)
      setFilteredData(reversedData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    startFilter(<Filters />)
    getData()
    validateAccess()
    document.title = 'SisarmaApp | Log'
  }, [validateAccess])

  const columns = [
    { id: 'id', label: 'ID', width: '5%' },
    { id: 'nome', label: 'Nome', width: '20%' },
    { id: 'usuario', label: 'Usuário', width: '30%' },
    { id: 'dataHora', label: 'Data hora', width: '15%' },
    { id: 'alteracao', label: 'Scripts', width: '10%', truncate: true },
  ]

  const onRowClick = (row) => {
    if (!row.alteracao) return
    setModalLog(true)
    setRow(row)
  }

  if (user.pessoa.admin === '1') {
    return (
      <section>
        {modalLog && (
          <ModalLog
            row={row}
            open={modalLog}
            handleClose={() => setModalLog(false)}
          />
        )}
        <Card className="relative">
          <div
            className="absolute top-6 right-5 text-xl cursor-pointer"
            onClick={() => history.push('/')}
          >
            <IconBack />
          </div>
          <div className="p-5">
            <div className="flex items-center gap-1">
              <FaRegComments />
              <h1 className="text-lg font-semibold">Log</h1>
            </div>
          </div>
          <CardContent>
            <TableFilter columns={columns} onRowClick={onRowClick} />
          </CardContent>
        </Card>
      </section>
    )
  } else {
    history.push('/')
    return null
  }
}

export default Log
