import React, { useEffect, useState } from 'react'
import Button from '../forms/Button'
import { TbDeviceMobileDown } from 'react-icons/tb'
import ModalInstructionIOS from './ModalInstructionIOS'

const ButtonsDowload = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null)
  const [showInstallButton, setShowInstallButton] = useState(false)
  const [isIOS, setIsIOS] = useState(false)
  const [isAndroidOrWindows, setIsAndroidOrWindows] = useState(false)
  const [openModalInstructionIOS, setOpenModalInstructionIOS] = useState(false)
  const [isStandalone, setIsStandalone] = useState(false) // Novo estado para verificar o modo de exibição

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    setIsIOS(/iphone|ipad|ipod/.test(userAgent))
    setIsAndroidOrWindows(/android|windows/.test(userAgent))

    // Verifica se o PWA está sendo acessado no modo standalone
    setIsStandalone(window.matchMedia('(display-mode: standalone)').matches)

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      )
    }
  }, [])

  const handleBeforeInstallPrompt = (e) => {
    e.preventDefault()
    setDeferredPrompt(e)
    setShowInstallButton(true)
  }

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    setIsIOS(/iphone|ipad|ipod/.test(userAgent))
    setIsAndroidOrWindows(/android|windows/.test(userAgent))

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      )
    }
  }, [])

  const handleAndroidDownload = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt()
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Usuário aceitou a instalação')
        } else {
          console.log('Usuário cancelou a instalação')
        }
        setDeferredPrompt(null)
        setShowInstallButton(false)
      })
    } else {
      console.log('O evento beforeinstallprompt não foi disparado.')
    }
  }
  function openApp() {
    window.open('https://app.sisarma.com.br', '_blank')
  }

  return (
    <>
      <ModalInstructionIOS
        isOpen={openModalInstructionIOS}
        onClose={() => setOpenModalInstructionIOS(false)}
      />
      <div>
        {isAndroidOrWindows && showInstallButton ? (
          <Button onClick={handleAndroidDownload} name="Baixar App">
            <TbDeviceMobileDown className="text-2xl" />
          </Button>
        ) : (
          !isStandalone && (
            <Button onClick={openApp} name="Aplicativo já baixado">
              <TbDeviceMobileDown className="text-2xl" />
            </Button>
          )
        )}
        {isIOS && (
          <Button
            onClick={() => setOpenModalInstructionIOS(true)}
            name="Baixar App"
          >
            <TbDeviceMobileDown className="text-2xl" />
          </Button>
        )}
      </div>
    </>
  )
}

export default ButtonsDowload
