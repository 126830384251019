import { useAuth } from '../context/AuthContext'
import { versions } from '../data/versions'

const Footer = () => {
  const { user } = useAuth()

  return (
    <footer>
      <div className="text-sm opacity-70 flex items-center h-12 md:h-8 justify-between w-full px-2 md:px-0 md:w-[60%] mx-auto">
        <p>versão {versions[versions.length - 1].version}</p>
        <p>{user.clube.nome}</p>
      </div>
    </footer>
  )
}

export default Footer
