import { useEffect, useState } from 'react'
import Modal2 from '../Modal2'
import { api } from '../../utils/api'
import { useAuth } from '../../context/AuthContext'
import { TbClock } from 'react-icons/tb'
import { FiUser } from 'react-icons/fi'
import { CiGlobe } from 'react-icons/ci'
import { Typography } from '@mui/material'
import { CiLogin } from 'react-icons/ci'
import { LiaExchangeAltSolid } from 'react-icons/lia'
import { LuTrash } from 'react-icons/lu'
import { GoPlusCircle } from 'react-icons/go'
import { cleanJSONString } from '../../utils/functions'
import { useTheme } from '@mui/material/styles'

const ModalLog = ({ handleClose, open, row }) => {
  const [data, setData] = useState([])
  const { user } = useAuth()
  const theme = useTheme()

  function formatarAlteracao(alteracao, operacao) {
    try {
      const objetoAlteracao = JSON.parse(cleanJSONString(alteracao))
      let jsonString = JSON.stringify(objetoAlteracao, null, 2)
      let linhas = jsonString.split('\n')

      if (operacao === 'update') {
        // Converte o objeto em uma string com quebras de linha
        // Verifica as diferenças entre as linhas
        linhas = linhas.map((linha, index) => {
          // Verifica se os valores dos campos são diferentes
          Object.keys(objetoAlteracao.before).forEach((key) => {
            let beforeValue = objetoAlteracao.before[key]
            let afterValue = objetoAlteracao.after[key]
            if (beforeValue !== afterValue) {
              // Substitui os valores antes e depois pela linha com destaque
              let beforeString = JSON.stringify(beforeValue)
              let afterString = JSON.stringify(afterValue)
              if (
                beforeValue !== undefined &&
                beforeValue !== null &&
                beforeValue !== '' &&
                linha.includes(beforeString) // Verifica se a linha contém o valor 'before'
              ) {
                linha = linha.replace(
                  new RegExp(beforeString, 'g'),
                  `<span style="background-color: yellow; color: black;">${beforeString}</span>`
                )
              }
              if (
                afterString !== undefined &&
                afterString !== null &&
                afterString !== '' &&
                linha.includes(afterString) // Verifica se a linha contém o valor 'after'
              ) {
                linha = linha.replace(
                  new RegExp(afterString, 'g'),
                  `<span style="background-color: yellow; color: black;">${afterString}</span>`
                )
              }
            }
          })
          return linha
        })
      }

      // Une as linhas formatadas em uma única string
      let resultado = linhas.join('\n')

      return <pre dangerouslySetInnerHTML={{ __html: resultado }} />
    } catch (error) {
      return alteracao
    }
  }

  const getData = async () => {
    try {
      const response = await fetch(`${api}log/detalhes/getData`, {
        method: 'POST',
        body: JSON.stringify({
          banco: user.clube.banco,
          logAppID: row.id,
          clubeID: user.clube.clubeID,
          pessoaID: user.pessoa.pessoaID,
        }),
      })
      const res = await response.json()
      setData(res)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [row.id])

  return (
    data && (
      <Modal2
        handleClose={handleClose}
        open={open}
        title={row.nome}
        subtitle={
          <span
            dangerouslySetInnerHTML={{
              __html: `Este log <strong>#${row.id}</strong> gerou um total de ${
                data.length
              } ${data.length === 1 ? 'script' : 'scripts'}`,
            }}
          />
        }
      >
        <div className="grid grid-cols-3 gap-8 text-xs">
          <div className="flex flex-col items-center gap-2">
            <TbClock className="text-gray-900" size={32} />
            <span className="text-center">{row.dataHora}</span>
          </div>
          <div className="flex flex-col items-center gap-2">
            <FiUser className="text-gray-900" size={32} />
            <span className="text-center">{row.usuario}</span>
          </div>
          <div className="flex flex-col items-center gap-2">
            <CiGlobe className="text-gray-900" size={32} />
            <span className="text-center">{row.ip}</span>
          </div>
        </div>

        {/* Scripts */}
        <div className="flex flex-col gap-4 mt-8">
          {data.map((log) => {
            const color =
              log.operacao === 'insert'
                ? 'bg-green-500/10 text-green-500'
                : log.operacao === 'update'
                ? 'bg-yellow-500/10 text-yellow-500'
                : log.operacao === 'delete'
                ? 'bg-red-500/10 text-red-500'
                : ''

            return (
              <div
                className={`border ${
                  theme.palette.mode === 'dark'
                    ? 'border-white/10'
                    : 'border-black/10'
                } rounded-xl p-4 space-y-4 h-auto`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <div className={`p-3 rounded-md ${color}`}>
                      {log.operacao === 'insert' ? (
                        <GoPlusCircle className={color} size={22} />
                      ) : log.operacao === 'update' ? (
                        <LiaExchangeAltSolid className={color} size={22} />
                      ) : log.operacao === 'delete' ? (
                        <LuTrash className={color} size={22} />
                      ) : (
                        <CiLogin className={color} size={22} />
                      )}
                    </div>

                    <div className="text-sm opacity-70">
                      {log.operacao === 'insert'
                        ? 'Inclusão'
                        : log.operacao === 'update'
                        ? 'Alteração'
                        : log.operacao === 'delete'
                        ? 'Exclusão'
                        : 'Operação desconhecida'}
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col justify-end ">
                      <Typography
                        variant="caption"
                        sx={{ color: 'text.disabled', textAlign: 'right' }}
                      >
                        Tabela
                      </Typography>
                      <Typography
                        sx={{ color: 'text.secondary', marginTop: '-4px' }}
                      >
                        {log.tabela}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className={`p-2 rounded-lg`}>
                  <pre>{formatarAlteracao(log.alteracao, log.operacao)}</pre>
                </div>
              </div>
            )
          })}
        </div>
        <div></div>
      </Modal2>
    )
  )
}

export default ModalLog
