import { Avatar, Card, CardContent, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Input from '../components/forms/Input'
import { useForm } from 'react-hook-form'
import Button from '../components/forms/Button'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { api } from '../utils/api'
import toast from 'react-hot-toast'
import { FaRegUser } from 'react-icons/fa'
import IconBack from '../components/IconBack'
import ModalNewPassord from '../components/perfil/ModalNewPassord'
import { useLoading } from '../context/LoadingContext'
import Anexos from '../components/perfil/Anexos'
import AlertCR from '../components/AlertCR'
import { useGetData } from '../context/getDataContext'

const Perfil = () => {
  const { user, validateAccess } = useAuth()
  const {
    data: { perfil: data },
    getDataPerfil,
  } = useGetData()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const history = useHistory()
  const [opeModalNewPassord, setOpeModalNewPassord] = useState(false)
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)
  const [files, setFiles] = useState([])
  const [previews, setPreviews] = useState([])
  const [arrDelete, setArrDelete] = useState([])

  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    setError,
    formState: { errors, isValid },
  } = useForm()

  // Salva anexos e fotos da arma no update
  const saveAnexosUpdate = async (logAppID) => {
    let formData = new FormData()
    const filteredArray = files.filter(
      (obj) => !obj.hasOwnProperty('pessoaAnexoID')
    )
    if (filteredArray.length === 0) return
    for (let i = 0; i < filteredArray.length; i++) {
      if (
        filteredArray[i].type.includes('image') ||
        filteredArray[i].type.includes('pdf')
      ) {
        formData.append('files[]', filteredArray[i])
      }
    }
    formData.append('id', user.pessoa.pessoaID)
    formData.append('banco', user.clube.banco)
    formData.append('clubeID', user.clube.clubeID)
    formData.append('logAppID', logAppID)

    try {
      const response = await fetch(`${api}perfil/saveAnexos`, {
        method: 'POST',
        body: formData,
      })
      await response.json()
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const onSubmit = async (data) => {
    const formatData = {
      ...data,
      arrDelete,
    }
    startLoading()
    if (!formatData) return
    try {
      const response = await fetch(`${api}perfil/updateData`, {
        method: 'POST',
        body: JSON.stringify({
          ...formatData,
          banco: user.clube.banco,
          pessoaID: user.pessoa.pessoaID,
          clubeID: user.clube.clubeID,
          usuarioID: user.pessoa.usuarioID,
        }),
      })
      const logAppID = await response.json()
      await saveAnexosUpdate(logAppID)
      toast.success('Dados alterados com sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Erro ao alterar dados!')
    } finally {
      stopLoading()
      getDataPerfil()
      setArrDelete([])
    }
  }

  const handleChangePhoto = async (files) => {
    setIsLoadingPhoto(true)
    const formData = new FormData()

    formData.append('files[]', files)
    formData.append('id', user.pessoa.pessoaID)
    formData.append('banco', user.clube.banco)
    formData.append('clubeID', user.clube.clubeID)
    formData.append('usuarioID', user.pessoa.usuarioID)

    try {
      const response = await fetch(`${api}perfil/savePhoto`, {
        method: 'POST',
        body: formData,
      })
      const res = await response.json()
    } catch (error) {
      console.log(error)
    } finally {
      getDataPerfil()
      toast.success('Foto alterada com sucesso!')
      setIsLoadingPhoto(false)
    }
  }

  useEffect(() => {
    getDataPerfil()
    validateAccess()

    document.title = 'SisarmaApp | Perfil'
  }, [])

  useEffect(() => {
    reset(data.data)
    setFiles(data.anexos)
    setPreviews(data.anexos)
  }, [data, reset])

  return (
    data && (
      <section>
        <ModalNewPassord
          isOpen={opeModalNewPassord}
          onClose={() => setOpeModalNewPassord(false)}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="relative">
            <div
              className="absolute top-6 right-5 text-xl cursor-pointer"
              onClick={() => history.push('/')}
            >
              <IconBack />
            </div>
            <div className="p-5">
              <div className="flex items-center gap-1">
                <FaRegUser />
                <h1 className="text-lg font-semibold">Perfil</h1>
              </div>
            </div>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AlertCR
                    data={{
                      cr: data?.data?.cr,
                      diasVencimento: data?.data?.diasVencimento,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <input
                    clearErrors={clearErrors}
                    setError={setError}
                    type="file"
                    id="avatarInput"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={(e) => handleChangePhoto(e.target.files[0])}
                  />
                  <Avatar
                    alt="photo user"
                    src={user?.pessoa?.foto ?? './semFoto.jpeg'}
                    variant="rounded"
                    className="border border-black/10 !w-[160px] !h-[160px] mx-auto md:!w-full md:!h-full cursor-pointer"
                    onClick={() => {
                      const input = document.getElementById('avatarInput')
                      if (input) {
                        input.click()
                      }
                    }}
                  ></Avatar>
                  {isLoadingPhoto && (
                    <p className="text-sm text-center">Carregando foto...</p>
                  )}
                </Grid>
                <Grid item xs={12} md={10}>
                  <Grid container spacing={1}>
                    <Input
                      clearErrors={clearErrors}
                      setError={setError}
                      xs={12}
                      md={6}
                      placeholder="Nome"
                      control={control}
                      errors={errors?.nome}
                      name="nome"
                      title="Nome"
                      disabled
                    />
                    <Input
                      clearErrors={clearErrors}
                      setError={setError}
                      xs={12}
                      md={6}
                      placeholder="Email"
                      control={control}
                      errors={errors?.email}
                      name="email"
                      title="Email"
                    />
                    <Input
                      clearErrors={clearErrors}
                      setError={setError}
                      xs={12}
                      md={6}
                      placeholder="Celular"
                      control={control}
                      errors={errors?.celular}
                      mask="cellPhone"
                      name="celular"
                      title="Celular"
                    />
                    <Input
                      clearErrors={clearErrors}
                      setError={setError}
                      xs={6}
                      placeholder="CR"
                      control={control}
                      errors={errors?.cr}
                      name="cr"
                      title="CR"
                      disabled
                    />
                    <Input
                      clearErrors={clearErrors}
                      setError={setError}
                      xs={6}
                      md={12}
                      placeholder="CR Validade"
                      control={control}
                      errors={errors?.crValidade}
                      name="crValidade"
                      title="CR Validade"
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Button
                        onClick={() => setOpeModalNewPassord(true)}
                        className="hover:bg-secondary hover:text-white"
                        name="Redefinir senha"
                        color="default"
                      />
                    </Grid>
                  </Grid>
                  {/* Anexos */}
                  <Anexos
                    files={files}
                    setFiles={setFiles}
                    previews={previews}
                    setPreviews={setPreviews}
                    setArrDelete={setArrDelete}
                    arrDelete={arrDelete}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="flex justify-between gap-4">
                    <Button
                      isValid={false}
                      color="primary"
                      name="Salvar"
                      type="submit"
                      disabled={isLoading}
                      effectLoading={true}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </section>
    )
  )
}

export default Perfil
