import { createContext, useState, useContext, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { api } from '../utils/api'
import { useLoading } from './LoadingContext'

const initialState = {
  user: {
    clube: {
      clubeID: '',
      banco: '',
      nome: '',
    },
    pessoa: {
      pessoaID: '',
      usuarioID: '',
      nome: '',
      cpf: '',
      email: '',
      admin: '',
      politicaPrivacidade: '',
      termosUso: '',
      avaliacao: '',
    },
  },
  setUser: () => {},
  validatelogin: () => {},
  login: () => {},
  logout: () => {},
  modalSelectedClube: () => {},
  setModalSelectedClube: () => {},
  dataLogin: {
    clube: [],
    pessoa: {},
    status: '',
  },
  validateAccess: () => {},
  hasAccess: () => {},
}

const AuthContext = createContext(initialState)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [dataLogin, setDataLogin] = useState(null)
  const [modalSelectedClube, setModalSelectedClube] = useState(false)
  const history = useHistory()
  const { startLoading, stopLoading } = useLoading()
  const [hasAccess, setHasAccess] = useState(true)

  // Valida acesso App
  const validateAccess = async () => {
    const localStorage = JSON.parse(
      window.localStorage.getItem('@sisarma-socio')
    )
    try {
      const response = await fetch(`${api}login/validateAccess`, {
        method: 'POST',
        body: JSON.stringify({
          usuarioID: user.pessoa.usuarioID,
          clubeID: user.clube.clubeID,
        }),
      })
      const res = await response.json()
      if (res.status === 'sem acesso' || localStorage === null) {
        setHasAccess(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Pega dados do clube
  const getClube = async (clube) => {
    try {
      const response = await fetch(`${api}login/getDataClube`, {
        method: 'POST',
        body: JSON.stringify({
          clubeID: clube.clubeID,
          banco: clube.banco,
          pessoaID: clube.pessoaID,
        }),
      })
      const res = await response.json()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  // Loga o usuário
  const login = async (data) => {
    setHasAccess(true)
    const clube = await getClube(data.clube.length ? data.clube[0] : data.clube)
    const imagemPerfil = clube?.imagem
    setModalSelectedClube(false)
    const formatData = {
      pessoa: {
        ...data.pessoa,
        foto: imagemPerfil,
      },
      clube: {
        ...clube,
        ...(modalSelectedClube ? data.clube : data.clube[0]),
      },
    }
    // //? LOGA
    setUser(formatData)
    localStorage.setItem('@sisarma-socio', JSON.stringify(formatData))
    history.push('/')
    stopLoading()
  }

  // Valida o login
  const validatelogin = async (data) => {
    const formatData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
    }
    try {
      startLoading()
      const response = await fetch(`${api}login`, {
        method: 'POST',
        body: JSON.stringify(formatData),
      })
      const res = await response.json()
      // return
      if (res.status === 'sem acesso') {
        toast.error('Usuário sem acesso!')
        return
      } else if (res.status === 'dados incorretos') {
        toast.error('Email ou senha inválidos!')
        return
      }

      setDataLogin(res)
      if (res.clube.length > 1) {
        // Habilita o modal para selecionar o clube
        setModalSelectedClube(true)
      } else {
        login(res)
      }
    } catch (error) {
      console.log(error)
    } finally {
      stopLoading()
    }
  }

  // Desloga o usuário
  const logout = () => {
    setUser(null)
    setHasAccess(true)
    localStorage.removeItem('@sisarma-socio')
    history.push('/login')
  }

  // Verifica se tem usuário logado no localStorage
  useEffect(() => {
    const data = localStorage.getItem('@sisarma-socio')
    if (data) {
      setUser(JSON.parse(data))
    } else {
      history.push('/login')
    }
  }, [history])

  const values = {
    user,
    setUser,
    validatelogin,
    login,
    logout,
    modalSelectedClube,
    setModalSelectedClube,
    dataLogin,
    validateAccess,
    hasAccess,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  return useContext(AuthContext)
}

export { AuthContext, AuthProvider, useAuth }
