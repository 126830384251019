import { CircularProgress } from '@mui/material'
import { twMerge } from 'tailwind-merge'
import { useLoading } from '../../context/LoadingContext'
import { useTheme } from '@mui/material/styles'

const Button = ({
  name,
  type = 'button' /* 'button' | 'submit' | 'reset' */,
  color = 'default',
  effectLoading,
  className,
  disabled,
  isValid = true,
  children,
  ...props
}) => {
  const { isLoading } = useLoading()
  const theme = useTheme()

  const colors = {
    primary: 'bg-primary opacity-90 hover:opacity-100',
    secondary: 'bg-secondary opacity-90 hover:opacity-100',
    danger: 'bg-danger opacity-90 hover:opacity-100',
    default: `border ${
      theme.palette.mode === 'dark'
        ? 'border-white/10 text-white hover:bg-black/10'
        : 'border-black/10 text-black hover:bg-white/10'
    } transition-colors hover:opacity-100 hover:bg-black/10`,
  }

  const buttonClasses = colors[color] || ''

  return (
    <div className="w-full relative">
      {type === 'submit' && isValid && (
        <p className="opacity-80 text-red-400 text-xs absolute -top-6 left-0 w-[350px]">
          Os campos vermelhos são obrigatórios
        </p>
      )}
      <button
        disabled={disabled}
        type={type}
        className={twMerge(
          `px-4 py-3 rounded-xl text-white transition-colors w-full ${buttonClasses} ${className}`,
          (isLoading && effectLoading) || disabled
            ? 'cursor-not-allowed opacity-40 hover:opacity-50'
            : ''
        )}
        {...props}
      >
        <div className="flex items-center justify-center gap-2">
          {isLoading && effectLoading && (
            <p>
              <CircularProgress color="inherit" size={14} />
            </p>
          )}
          {children}
          <p>{name}</p>
        </div>
      </button>
    </div>
  )
}

export default Button
